import React from "react";
import { useAppContext } from "../../../../contexts/App";

interface IRadioButton {
  checked: boolean,
  onClick?: (e: any) => void,
}

export default function CustomRadioButton({checked, onClick}: IRadioButton) {
  const {company} = useAppContext()
  return (
    <div className={'w-[20px] h-[20px]'}>
      <svg onClick={onClick} className="cursor-pointer" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="8.75" stroke={company.template.options.mainColor} strokeWidth="1.5"/>
        {checked && <circle cx="10" cy="10" r="6.5" fill={company.template.options.mainColor}/>}
      </svg>
    </div>
  )
}
