import { useEffect, useState } from 'react'
import { useAppContext } from '../../../../../contexts/App'
import { useCartContext } from '../../../../../contexts/CartContext'
import { useProductDetailCardContext } from '../../../../../contexts/ProductDetailCardContext'
import productImgStub from '../../../../../images/productImgStub.jpg'
import { resizeImage } from '../../../../../libs/helpers/resizeImage'
import { Preloader } from '../../../../common/preloader'
import { formatCurrency } from '../../../../../libs/helpers/formatCurrency'


export default function AdditionalProduct({ product, goodsCanBeAddedToCart }: any) {
  const { allProducts, branch } = useAppContext()
  const { cart, addProduct, addProductServer, getProductCart, updateProduct, updateProductServer } = useCartContext()
  const { openDetail } = useProductDetailCardContext()
  const [productCart, setProductCart] = useState<any>(null)
  const [load, setLoad] = useState(false)
  const [canBeAdded, setCanBeAdded] = useState(goodsCanBeAddedToCart && product?.stockBalance != 0 && product?.isActive)

  useEffect(() => {
    setCanBeAdded(goodsCanBeAddedToCart && product?.stockBalance != 0 && product?.isActive)
  }, [product, goodsCanBeAddedToCart])

  useEffect(() => {
    setProductCart(getProductCart(product.id))
  }, [product, cart])

  return (
    <div onClick={() => canBeAdded && openDetail(product.id)} className={`${canBeAdded && 'cursor-pointer'} w-[280px] h-full lg:w-[331px] flex gap-4 p-3 lg:p-[15px]  bg-white dark:bg-gray-40 rounded-[29px] my-4 md:mt-[30px] md:mb-[20px] shadow-lg`}>
      {
        product.image ?
        <div className='rounded-[17px] overflow-hidden h-[125px] min-w-[125px]'>
          <picture className={'h-[125px]'}>
            <source className={'h-[125px]'} type="image/webp" srcSet={`${resizeImage(product.image, '125', 'webp')} 1x, ${resizeImage(product.image, '300', 'webp')} 2x, ${resizeImage(product.image, '400', 'webp')}3x`}/>    
            <source className={'h-[125px]'} type="image/jpeg" srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`}/>
            <img 
              className={'h-[125px]'}
              src={resizeImage(product.image, '125', 'jpg')} 
              srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`}
              alt=""
            />
          </picture>
        </div> :  
        <img
          className='h-[125px] w-[125px] rounded-[17px]'
          src={productImgStub} 
          alt=""
        />
      }

      <div className='grow flex flex-col justify-between'>
        <div className='lg:text-lg leading-[127.02%] -tracking-[.01em] font-semibold text-dark dark:text-light'>{ product.title }</div>
        {
          canBeAdded && 
          <>
            <div className='text-main font-bold text-lg flex items-center gap-1'>{!!(allProducts[product.id].skuGroupsValues.length || allProducts[product.id].optionsExists) && 'От'} {formatCurrency(branch.extended.currency.code, product.price)}</div>
            
            {
              !!(allProducts[product.id].skuGroupsValues.length || allProducts[product.id].optionsExists) ?
                <div
                  className='flex items-center justify-center w-[110px] h-[35px] bg-orderbtn rounded-[18px] cursor-pointer -tracking-[.01em] font-bold text-main hover:bg-main hover:text-white duration-500'
                >
                  Выбрать
                </div> : 
                <>
                  {
                    !!productCart ?
                    <div 
                      onClick={event => {
                        event.stopPropagation()
                        if (window.location.pathname.split('/').at(-1) === 'cart') {
                          setLoad(true)
                          updateProductServer(productCart.uid, {count: 0}).then(() => setLoad(false))
                        } else {
                          updateProduct(productCart.uid, {count: 0})
                        }
                      }} 
                      className='w-full h-[35px] flex items-center justify-center border-[2px] rounded-[18px] border-main hover:bg-main hover:text-white text-main font-bold cursor-pointer'
                    >
                      {load ? <Preloader countOfDot={3} color={'white'} size={'8px'} className={'min-w-[122px] gap-2'}/> : 'Добавлено'}
                    </div>:
                    <div 
                      onClick={event => {
                        event.stopPropagation()
                        if (window.location.pathname.split('/').at(-1) === 'cart') {
                          setLoad(true)
                          addProductServer(product.id, [], product.minCount).then(() => setLoad(false))
                        } else {
                          addProduct(product.id, [], product.minCount)
                        }
                      }} 
                      className='w-full h-[35px] flex items-center justify-center bg-orderbtn rounded-[18px] text-main hover:bg-main hover:text-white font-bold cursor-pointer'
                    >
                      {load ? <Preloader countOfDot={3} color={'white'} size={'8px'} className={'min-w-[122px] gap-2'}/> : 'Добавить'}
                    </div>
                  }
                </>
            }
          </>
        }
      </div>

    </div>
  )
}
