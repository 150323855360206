import React from 'react'
import Slider from 'react-slick';
import { useAppContext } from '../../../contexts/App';
import { resizeImage } from '../../../libs/helpers/resizeImage';


export default function MainPageSlider() {
  const { branch } = useAppContext()

  const settingsSlider = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 520,
        settings: {
          dots: false
        }
      }
    ]
  };

  return (
    <>
      <div className='main-page-slider hidden xs:block'>
        <Slider {...settingsSlider} >
          {
            branch.base?.slidesDesktop?.map((slide: any) => (
              <div className='w-full outline-none rounded-[29px] overflow-hidden' key={slide.id}>
                {/* <img className='w-full rounded-[29px]' src={slide.link} alt="" /> */}
                <picture>
                  {/* // load webp in different sizes if browser supports it */}
                  <source media="(min-width: 769px)" 
                    srcSet={resizeImage(slide.link, 0, 'webp')}
                    type="image/webp"/>
                  <source media="(max-width: 768px)" 
                    srcSet={resizeImage(slide.link, 768, 'webp')}
                    type="image/webp"/>

                  {/* // load jpg in different sizes if browser doesn't support webp */}
                  <source media="(min-width: 766px)" 
                    srcSet={resizeImage(slide.link, 0, 'jpg')}
                    type="image/jpeg"/>
                  <source media="(max-width: 768px)" 
                    srcSet={resizeImage(slide.link, 768, 'jpg')}
                    type="image/jpeg"/>

                  {/* // fallback in different sizes, as well as regular src. */}
                  <img 
                    srcSet={`${resizeImage(slide.link, 768, 'jpg')} 768w`}
                    sizes="(max-width: 768px) 768px"
                    src={resizeImage(slide.link, 0, 'jpg')} 
                    alt="image description"
                    className=""
                  />
                </picture>

              </div>
            ))
          }
        </Slider>
      </div>

      <div className={`main-page-slider xs:hidden block ${branch.base?.slidesMobile?.length > 0 ? 'mt-5' : ''}`}>
        <Slider {...settingsSlider} >
          {
            branch.base?.slidesMobile?.map((slide: any) => (
              <div className='w-full outline-none' key={slide.id}>
                <img className='w-full rounded-[29px]' src={slide.link} alt="" />
              </div>
            ))
          }
        </Slider>
      </div>
    </>
  )
}
