import React, { useState, useRef, useEffect } from 'react'
import ArrowIcon from './../../../../icons/ArrowIcon';

export default function Sku({ skuGroups, products, product, setProduct }: any) {


  return (
    <div>
      {
        skuGroups.map((skuGroup: any) => {
          // Заранее прошу прощение, если кому-то придётся в этом разбираться

          // Так как максимум может быть только 2 sku группы, то мы можем быть уверенны что если мы из выбранного товара достанем группу с несовпадающим id с skuGroup.id, то это вторая связанная группа
          let valueOtherSkuGroup = product.skuGroupsValues.filter((skuGroupsValue: any) => skuGroupsValue.skuGroupId !== skuGroup.id).at(0)

          let options = skuGroup.values.map((option: string) => {
            
            let productOption
            if (skuGroups.length > 1) {
              productOption = products
                // Сначала оставляем только продукты с "другой" группой как у выбранного товара
                .filter((childGood: any) => childGood.skuGroupsValues.reduce((accumulator: any, value: any) => ({...accumulator, [value.skuGroupId]: value.title}), {})[valueOtherSkuGroup.skuGroupId] === valueOtherSkuGroup.title)
                // Потом фильтруем по "этой" группе
                .filter((childGood: any) => childGood.skuGroupsValues.reduce((accumulator: any, value: any) => ({...accumulator, [value.skuGroupId]: value.title}), {})[skuGroup.id] === option)
                .at(0)
            } else {
              // Берём все товары childGoods, из каждого берём skuGroupsValues, на всякий фильтруем по skuGroupId, и проверяем на совпадение title 
              productOption = products.filter((childGood: any) => childGood.skuGroupsValues.filter((value: any) => value.skuGroupId === skuGroup.id).at(0)?.title === option).at(0)
            }

            return {
              title: option, 
              isActive: product.skuGroupsValues.filter((item: any) => item.skuGroupId === skuGroup.id).at(0)?.title === option,
              product: productOption
            }
          })

          return (
            <div className='mt-[15px]' key={skuGroup.id}>
              <div className='mb-[5px] text-dark dark:text-gray-10 font-bold text-lg md:text-base'>{ skuGroup.title }</div>
              {
                skuGroup.outputType === "select" ? 
                <Select setProduct={setProduct} options={options} /> :
                <RadioButtonGroup setProduct={setProduct} options={options} />
              }
            </div>
          )
        })
      }
    </div>
  )
}

// skuGroup.outputType === "radio-button-group"
function RadioButtonGroup({ options, setProduct }: any) {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    options.forEach((option: any, index: number) => {
      if (option.isActive) {
        setActiveIndex(index)
      }
    });
  }, [options])

  return (
    <div className='p-[3px] h-[35px] bg-gray-20 dark:bg-gray-30 rounded-[10px]'>
      <div className='relative flex h-full'>
        <div style={{width: `${100 / options.length}%`, transform: `translateX(${100 * activeIndex}%)`}} className={`absolute h-full bg-gray-30 dark:bg-gray-50 rounded-[10px] transition-all duration-300`}></div>
        {
          options.map((option: any) => (
            <div 
              key={option.title}
              onClick={() => setProduct(option.product)} className={`flex z-10 items-center justify-center text-sm rounded-[10px] cursor-pointer ${(!option.product || option?.product?.stockBalance === 0 || !option?.product?.isActive) && 'opacity-50'}`} 
              style={{width: `${100 / options.length}%`}}
            >
              {option.title}
            </div>
          ))
        }
      </div>
    </div>
  )
}


// skuGroup.outputType === "select"
function Select({ options, setProduct }: any) {
  const [show, setShow] = useState(false)
  const wrapper: any = useRef(null);

  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);


  return (
    <div className='w-full h-[35px] relative z-[10]'>
      <div
        ref={wrapper} 
        className={`absolute w-full overflow-hidden rounded-[10px] cursor-pointer duration-300 ${show ? 'bg-white dark:bg-gray-30 shadow-md' : 'bg-gray-20 dark:bg-gray-40'}`}
        style={{height: (show ? `min(${35 * options.length}px, 200px)` : '35px')}}
      >
        <div onClick={() => setShow(!show)} className='h-[35px] flex items-center justify-between'>
          <div className='ml-[22px] text-sm'>{ options.filter((option: any) => option.isActive).at(0)?.title }</div>
          <div className='rotate-[90deg] mr-5'><ArrowIcon className='w-[10px] h-[10px]'/></div>
        </div>

        <div className=''>
          {
            options.filter((option: any) => !option.isActive).map((item: any) => {
              return (
                <div 
                  key={item.title}
                  onClick={() => {setProduct(item.product); setShow(false)}}
                  className={`h-[35px] flex items-center ml-[22px] text-sm  hover:text-main ${(!item.product || item?.product?.stockBalance === 0 || !item?.product?.isActive) && 'opacity-50'}`}
                >
                  { item.title }
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

