import { useEffect, useState } from "react"
import { useAppContext } from "../../contexts/App"
import { NotificationBlock, sendEvent, useVisitorContext } from "../../contexts/VisitorContext"
import { apiClient } from "../../libs/api/apiClient"
import Nav from "../../components/common/Nav"
import MainPageSlider from "../../components/pages/mainPage/Slider"
import Stories from "../../components/pages/mainPage/Stories"
import Catalog from "../../components/pages/mainPage/Catalog"


export default function Main() {
  const { catalog, setCatalog, branch, allProducts } = useAppContext()

  useEffect(() => {
    if (!catalog.length) {
      apiClient.catalog.get(branch.id).then(({data, message}: any) => {
        setCatalog(data)
      })
    }
  }, [allProducts])

  useEffect(() => {
    if (sessionStorage.getItem('visitorId')) {
      sendEvent('section-visited', {target: 'Главная'})
    }
  }, [])

  return (
    <>
      <div className={'container'}>
        <Nav/>
        <MainPageSlider/>
        <Stories/>
      </div>

      <div className={'mt-5'}>
        <NotificationBlock place='main-top'/>
      </div>

      <div className={'container'}>
        <Catalog/>
      </div>
    </>
  )
}