import React, { useEffect, useState } from 'react'
import { animateScroll } from 'react-scroll';
import { useAdditionalFieldsFunnelsContext } from '../../../contexts/AdditionalFieldsFunnelsContext';
import { useAppContext } from '../../../contexts/App';
import { useCartContext } from '../../../contexts/CartContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { localStorageWrap } from '../../../libs/helpers/localStorageWrap';
import { Preloader } from '../../../components/common/preloader';
import ArrowIcon from '../../../components/icons/ArrowIcon';
import Popup from '../../../components/common/popup';
import { CartProductType, OptionProductType } from '../../../types/cart';
import productImgStub from '../../../images/productImgStub.jpg'
import CartStep from '../../../components/pages/cartPage/cartStep';
import OrderStep from '../../../components/pages/cartPage/orderStep';


export default function Cart() {
  const { company, city, branch, address, allProducts} = useAppContext()
  const { cart, updateCart, synchronize } = useCartContext()
  const { user, isLoadingUser } = useAuthContext()
  const { vlidateCartFields } = useAdditionalFieldsFunnelsContext()
  const navigate = useNavigate();

  const [step, setStep] = useState('cart')
  const [loaded, setLoaded] = useState(true)
  const [cartErrors, setCartErrors] = useState<any>(null)
  const [backToCartMessage, setBackToCartMessage] = useState<string>('')


  function nextOrderPage() {
    if (vlidateCartFields()) {
      let errors = cart.products.filter(p => p?.errors?.length)
  
      if (errors.length) {
        setCartErrors(errors)
      } else {
        setStep('order')
        animateScroll.scrollToTop()
      }
    }
  }
  
  function removeInvalidProducts() {
    updateCart({deleting: cart.products.filter(p => p?.errors?.length).map(p => p.uid)})
    setCartErrors(null)
  }

  useEffect(() => {
    if (isLoadingUser) return;
    if (!(user || (branch?.extended?.allowGuestOrderInMobile && localStorageWrap.getItem('clientPhone')))) {
      navigate(`/${city.slug}`)
    }
  }, [isLoadingUser])

  if (isLoadingUser)
    return <div className={"h-[80vh] flex items-center justify-center"}>
      <Preloader countOfDot={4} /></div>

  return (
    <>
      <div className='w-full h-[50px] border-b-[1px] border-gray-20 dark:border-gray-50 relative flex items-center'>
        <div className='relative max-w-[1290px] w-full mx-auto'>
          <div className={`max-w-[1000px] mx-auto px-[15px] md:px-[75px] flex items-center justify-between h-full`}>
            {/* <Link href={`/${city.slug}`} className='xl:absolute left-0'>
              <ArrowSecondaryIcon className='w-[12px] md:w-[15px] h-[8px] md:h-[10px]'/>
            </Link> */}
            <div className={`text-xs md:text-lg md:font-medium ${step === 'cart' ? 'text-main' : 'text-gray-40 dark:text-gray-10 opacity-50'}`}>Корзина</div>
            <ArrowIcon className={`w-[4px] md:w-[6px] h-[7px] md:h-[9px] ${step === 'cart' ? 'opacity-100' : 'opacity-50'}`} colorClassName={`${step === 'cart' ? 'fill-main' : 'fill-gray-40 dark:fill-gray-10'}`}/>

            <div className={`text-xs md:text-lg md:font-medium ${step === 'order' ? 'text-main' : 'text-gray-40 dark:text-gray-10 opacity-50'}`}>Оформление заказа</div>
            <ArrowIcon className={`w-[4px] md:w-[6px] h-[7px] md:h-[9px] ${step === 'order' ? 'opacity-100' : 'opacity-50'}`} colorClassName={`${step === 'order' ? 'fill-main' : 'fill-gray-40 dark:fill-gray-10'}`} />

            {/* <div className='text-xs md:text-lg md:font-medium text-gray-40 dark:text-gray-10 opacity-50'>Оплата</div>
            <ArrowIcon className='w-[4px] md:w-[6px] h-[7px] md:h-[9px] opacity-50' colorClassName='fill-gray-40 dark:fill-gray-10' /> */}

            <div  className={`text-xs md:text-lg md:font-medium text-gray-40 dark:text-gray-10 opacity-50`}>{company.statuses.filter((s: any) => s.code === 'accepted').at(0).title}</div>

          </div>
        </div>
      </div>
      
      {
        step === 'cart' &&
        <CartStep
          nextOrderPage={nextOrderPage} 
          loaded={loaded} 
          backToCartMessage={backToCartMessage}
        />
      }

      {
        step === 'order' &&
        <OrderStep back={(message: string = '') => {setStep('cart'); animateScroll.scrollToTop(); setBackToCartMessage(message)}} setCartErrors={setCartErrors} />
      }
      
      <Popup
        isActive={cartErrors}
        close={() => setCartErrors(null)}
        closeIcon={true}
        width={'630px'}
        classNamePopup={'m-0 self-center'}
      >
        {
          !!cartErrors &&
          <div className='flex flex-col gap-5'>
            {
              cartErrors.map((product: CartProductType) => {
                let productData: any = {}
            
                if (allProducts[product.productId]) {
                  productData = allProducts[product.productId]
                }

                productData = {...productData, ...product}

                return (
                  <div key={product.uid} className='opacity-60 py-9 border-b-[1px] border-gray-20 md:border-none md:p-[15px] md:bg-white dark:md:bg-gray-50 md:rounded-[29px] md:shadow-[4px_4px_20px_rgba(0,0,0,0.07)]'>
                    <div className="flex gap-5 md:gap-[30px]">
                      {
                        productData?.image ? 
                        <img className={`h-[120px] !w-auto xs:w-full rounded-[17px]`} src={productData?.image} alt="" /> :
                        <img
                          className='h-[120px] !w-auto xs:w-full rounded-[17px]'
                          src={productImgStub} 
                          alt=""
                        />
                      }
          
                      <div className='flex flex-col grow'>
                        <div className='flex md:items-center justify-between'>
                          <div className='flex items-center flex-wrap gap-x-4 md:text-[20px] font-bold -tracking-[.01em] pr-[10px] md:pr-0'>
                            <span>{ productData?.title }</span> 
          
                            { 
                              productData?.skuGroupsValues.map((sky: any) => (
                                <span className='text-main' key={sky.valueId}>{ sky.title }</span>
                              ))
                            }
          
                          </div>
                          
                        </div>
          
                        {
                          !!productData?.options.length &&
                          <div 
                            className='text-xs text-gray-40 font-normal mb-1' 
                            dangerouslySetInnerHTML={{__html: `+ ${ productData?.options.map((option: OptionProductType) => `${option.title}${option.count > 1 ? '&nbsp;x&nbsp;' + option.count : ''}`).join(', ') }`}}
                          />
                        }
          
                        {
                          !!(productData?.weight || productData?.size) &&
                          <div className={`flex gap-2 -tracking-[.01em] font-bold text-gray-30`}>
                            { !!productData?.weight && <div>{ productData?.weight } г.</div> }
                            { !!productData?.size && <div>{ productData?.size } { productData?.measure_unit }</div> }
                          </div> 
                        }
          
                        <div className={`text-sm grow mt-[11px] -tracking-[.01em]  leading-[17px] text-gray-50 dark:text-gray-10`}>{ productData?.shortDescription }</div>

                        <div className=''>
                          {
                            productData.errors.map((err: string) => (
                              <div key={err} className='text-sm text-main font-bold'>{err}</div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </div>
        }
        <div onClick={removeInvalidProducts} className="flex items-center justify-center h-[38px] mt-10 rounded-full bg-orderbtn text-main text-sm font-bold cursor-pointer">Удалить товары из корзины</div>
      </Popup>
    </>
  )
}