import React, { useRef, useState, useEffect } from 'react'
import Slider from "react-slick";
import productImgStub from "../../../../images/productImgStub.jpg";
import LinesEllipsis from 'react-lines-ellipsis';
import { useAppContext } from '../../../../contexts/App';
import { useProductDetailCardContext } from '../../../../contexts/ProductDetailCardContext';
import { useDetectorBrowser } from '../../../../hooks/useDetectorBrowser';
import ArrowIcon from '../../../icons/ArrowIcon';
import { resizeImage } from '../../../../libs/helpers/resizeImage';
import { formatCurrency } from '../../../../libs/helpers/formatCurrency';
import CrossedOldPriceIcon from '../../../icons/CrossedOldPriceIcon';

export default function Popular() {
  const { initialCatalog, allProducts, branch } = useAppContext()
  const { openDetail } = useProductDetailCardContext()
  const [isShowLeftArrow, setIsShowLeftArrow] = useState(false)
  const [isShowRightArrow, setIsShowRightArrow] = useState(false)
  const [products, setProducts] = useState([])

  useEffect(() => {
    let temp = {}
    if (initialCatalog) {
      initialCatalog.forEach((category: any) => {
        category.goods.forEach((goodId: any) => {
          if (allProducts[goodId]?.isTop) {
            temp = {...temp, [goodId]: allProducts[goodId]}
          }
        });
      });
    }

    setProducts(Object.values(temp))

    setIsShowRightArrow(Object.values(temp).length <= 3 ? false : true)
  }, [initialCatalog])

  const settings = {
		className: "slider variable-width",
		slidesToShow: 1,
    slidesToScroll: 1,
		variableWidth: true,
		infinite: false,
		arrows: false,
	};

  const slider = useRef<any>(null)

  const isSafari = useDetectorBrowser().includes('safari')

  return (
    <div className='mt-[30px]'>
      {!!products.length && <div className={`text-dark dark:text-light text-4xl font-bold text-[32px]`}>Популярное</div>}
      
      <div className={`relative mt-1`}>
        <button className={`${isShowLeftArrow? 'lg:block' : 'lg:hidden'} hidden absolute h-full top-0 left-[-70px]`} onClick={() => slider.current?.slickPrev()}>
          <div className='rotate-180'>
            <ArrowIcon className='w-[13px] h-[21px]'/>
          </div>
        </button>

        <Slider {...settings} ref={slider} afterChange={(currentSlide) => {
            if(currentSlide === 0){
              setIsShowLeftArrow(false)
            } else{
              setIsShowLeftArrow(true)
            }
            if (currentSlide === Math.floor(products.length / 2 + 1)){
              setIsShowRightArrow(false)
            } else if(products.length <= 3 ){
              setIsShowRightArrow(false)
            } else {
              setIsShowRightArrow(true)
            }
          }}
          >
          {
            products.map((product: any) => (
              <div className='xs:mr-[30px] mr-[10px]' key={product.id} onClick={() => openDetail(product.id)}>
                <div className='w-[280px] xs:w-[331px] mt-7 mb-[40px] md:mb-[50px] flex gap-4 cursor-pointer bg-white dark:bg-gray-50 rounded-[29px] shadow-lg'>
                  {
                    product.image ?
                    // <img src={product.image} className='h-[106px] lg:h-[125px] rounded-[17px]' alt="" /> 
                    <div className='rounded-[17px] overflow-hidden'>
                      <picture className={'xs:h-[125px] h-[106px]'}>
                        <source className={'xs:h-[125px] h-[106px]'} type="image/webp" srcSet={`${resizeImage(product.image, '125', 'webp')} 1x, ${resizeImage(product.image, '300', 'webp')} 2x, ${resizeImage(product.image, '400', 'webp')}3x`}/>
                        <source className={'xs:h-[125px] h-[106px]'} type="image/jpeg" srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`}/>
                        <img
                          className={'h-[106px] w-[106px] xs:h-[125px] xs:w-[125px]'}
                          src={resizeImage(product.image, '125', 'jpg')}
                          srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`}
                          alt=""
                        />
                      </picture>
                    </div>:
                    <img
                      className='w-[106px] h-[106px] xs:w-[125px] xs:h-[125px] rounded-[17px]'
                      src={productImgStub} 
                      alt=""
                    />
                  }

                  <div className='w-[calc(280px-106px-30px)] xs:w-[calc(331px-125px-25px)] py-2 flex flex-col py-3 pr-3 lg:py-[15px] lg:pr-[15px] justify-between'>
                    <LinesEllipsis
                      text={product.title}
                      basedOn="letters"
                      maxLine={2}
                      className='text-[15px] sm:text-lg leading-[127.02%] -tracking-[.01em] font-semibold text-dark dark:text-light'
                    />
                      {/* <div className='lg:text-lg leading-[127.02%] -tracking-[.01em] font-semibold text-dark dark:text-light'>{ product.title }</div> */}
                      <div className='flex flex-row items-center'>
                      <div className='text-main font-bold text-[16px] xs:text-lg flex items-center gap-1 mr-2'>{!!(allProducts[product.id]?.skuGroupsValues.length || allProducts[product.id]?.optionsExists) && 'От'} {formatCurrency(branch.extended.currency.code, product.price)}</div>
                      {!!product.oldPrice &&
                        <div className={`relative ${String(product.oldPrice).length >= 4? 'xs:w-[31%]' : ' xs:max-w-[21%]'} w-[30%] ml-[2px] text-sm leading-[17px] text-gray-50 dark:text-gray-30`}>
                        <div className='flex items-center opacity-60'>{formatCurrency(branch.extended.currency.code, product.oldPrice)}</div>
                        <div className='absolute w-full h-full left-0 top-0'>
                          <CrossedOldPriceIcon width={'100%'} height={'auto'}/>
                        </div>
                      </div>
                      }
                    </div>

                  </div>
                </div>
              </div>
            ))
          }
        </Slider>

        <button className={`${isShowRightArrow ? 'lg:block' : 'lg:hidden'} hidden absolute h-full top-0 ${isSafari? 'right-[-70px]' : 'right-[-50px]'}`} onClick={() => slider.current?.slickNext()}>
          <ArrowIcon className='w-[13px] h-[21px]'/>
        </button>
      </div>
    </div>
  )
}
