import {useEffect, useState} from 'react'

export const Input = ({id, answers, errors}: any) => {
  const [inputState, setInputState] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    if(errors){
      setError(answers.map((answer: any, index: number) => {
          if(errors[`answers.${index}.answer`] && answer.npsQuestionId === id){
            return errors[`answers.${index}.answer`]
          }
        }).filter((answer: any) => answer))
    }
  }, [errors])

  const updateCurrentAnswer = (value: any) => {
    const currentAnswer = answers.find((answer: any) => answer.npsQuestionId === id)
    if(currentAnswer){
      currentAnswer.answer = value
    } else {
      answers.push({npsQuestionId: id, answer: value})
    }
  }
  
  return (
    <div>
      <div className={`flex flex-row items-center justify-between text-sm px-[22px] py-2 rounded-[10px] bg-gray-20 dark:bg-gray-40`}>
        <input 
          type="text" 
          placeholder='Комментарий'
          value={inputState}
          onChange={(e) => {
            setInputState(e.target.value)
            updateCurrentAnswer(e.target.value)
          }}
          className={`w-full h-5 bg-transparent placeholder-gray-40 dark:placeholder-gray-20 outline-none disabled:text-gray-40 dark:disabled:text-gray-30 `}
        />
      </div>
      {error && 
        <p className={'mt-[10px] text-yellow text-center text-xs font-medium'}>{error}</p>
      }
    </div>
    
    
  );
};
