import React, { useRef } from 'react'
import AdditionalProduct from './AdditionalProduct'
import Slider from "react-slick";
import ArrowIcon from '../../../../icons/ArrowIcon'
import { useAppContext } from '../../../../../contexts/App';

export default function AdditionalProducts({ product, className='' }: any) {
  const { allProducts } = useAppContext()
  const slider = useRef<any>(null)

  const sortActiveProduct = product.goods?.filter((c: any) => !!c.isActive)
  return (
    <>
      {
        !!sortActiveProduct.length ?
        <div className={`pt-1 md:pt-[15px] mx-[-20px] rounded-b-[29px] overflow-hidden ${className}`}>
          <div className='bg-gradient-to-r from-gray-20/20 via-gray-20 to-gray-20/20 h-[2px] mt-2'></div>

          { product.titleOfSetsBlock && <div className='text-xl -tracking-[.01em] font-bold mt-1 md:mt-[15px] ml-5'>{ product.titleOfSetsBlock }</div> }

          <div className='relative'>
            <Slider 
              variableWidth={true}
              arrows={false}
              infinite={false}
              ref={slider}
            >
              {
                sortActiveProduct.filter((product: any) => allProducts[product.id]).map((item: any) => (
                  <div className='mx-[15px] h-full ' key={item.id}>
                    <AdditionalProduct product={item} goodsCanBeAddedToCart={product.goodsCanBeAddedToCart}/>
                  </div>
                ))
              }
            </Slider>

            <button className={`${sortActiveProduct.length >= 3 ? 'md:flex' : 'md:hidden'} duration-500 bg-gradient-to-l from-[#EEEEEE] dark:from-[#BDBDBD] hidden absolute items-center justify-end h-full top-0 right-[0px] w-[200px] cursor-pointer`} onClick={() => slider.current?.slickNext()}>
              <ArrowIcon className='w-[13px] h-[21px] mr-[22px] duration-500'/>
            </button>
          </div>

        </div> : <div className={'mb-5'}/>
      }
    </>
  )
}
