import React, {useEffect, useState} from "react";
import { IQuestionnaire } from "../history";
import { formatDate, getDayAndFullMonth } from "../../../../libs/helpers/formatDate";
import { BaseButton } from "../../../../components/common/button/BaseButton";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../../contexts/App";
import { useAuthContext } from "../../../../contexts/AuthContext";
import { usePagination } from "../../../../hooks/usePagination";
import { apiClient } from "../../../../libs/api/apiClient";
import { Preloader } from "../../../../components/common/preloader";
import { ProfileLayout } from "../../../../components/layouts/profile";


interface INotification {
  id: string,
  data: any,
  readAt: string | null,
  type: string,
  createdAt: string
}

interface INotificationDate {
  createdAt: string,
}

interface IDistributionCard extends INotificationDate{
  distribution: IDistribution,
}

interface IOrderStatusChangeCard extends INotificationDate{
  order: IOrder,
  city: string,
}

interface IOrder {
  orderId: number,
  text: string,
  orderStatus: {
    code: string,
    color: string,
    title: string
  },
  questionnaireId: IQuestionnaire | null
}

interface IDistribution {
  text: string,
  image: string | null,
  link: string | null,
  title: string,
  subtitle: string | null
}

function DistributionCard({distribution, createdAt}: IDistributionCard) {
  const {resTime} = formatDate(new Date(createdAt))

  return (
    <div className="flex flex-col bg-white p-[15px] pb-[20px] rounded-[20px] shadow-md dark:bg-gray-40 ">
      <p className={'font-bold text-lg mb-[20px]'}>{distribution.subtitle}</p>
      {
        distribution.image && <img src={distribution.image} className={'object-cover w-full md:w-[70%] xl:w-[50%] rounded-[15px] mb-[17px]'} alt=""/>
      }
      <p className="text-gray-50 xs:text-base text-sm dark:text-white" dangerouslySetInnerHTML={{ __html: distribution.text }}>
      </p>
      <span className="text-gray-30 text-end mt-[5px]">{resTime}</span>
    </div>
  )
}
function CommonCard({common, createdAt}: any) {
  const {resTime} = formatDate(new Date(createdAt))

  return (
    <div className="flex flex-col bg-white p-[15px] pb-[20px] rounded-[20px] shadow-md dark:bg-gray-40 ">
      <p className={'font-bold text-lg mb-[10px]'}>{common.title}</p>
      {
        common.image && <img src={common.image} className={'object-cover w-full md:w-[70%] xl:w-[50%] rounded-[15px] mb-[17px]'} alt=""/>
      }
      <p className="text-gray-50 xs:text-base text-sm dark:text-white" dangerouslySetInnerHTML={{ __html: common.text }}></p>
      {common.link && <div className={'flex flex-row xs:gap-x-4 xs:text-xl text-lg gap-x-2'}>
        <Link to={common.link} target={"_blank"}>
          <BaseButton
            className={"mt-[17px] w-full md+:text-sm text-xs bg-orderbtn text-main hover:bg-main hover:text-white font-medium break-words"}>
            Перейти по ссылке
          </BaseButton>
        </Link>
      </div>}
      <span className="text-gray-30 text-end mt-[5px]">{resTime}</span>
    </div>
  )
} 
function OrderStatusChangeCard({order, city, createdAt}: IOrderStatusChangeCard) {
  const {resTime} = formatDate(new Date(createdAt))


  return (
    <div className="flex flex-col bg-white p-[15px] pb-[20px] rounded-[20px] shadow-md dark:bg-gray-40 ">
      <div className={'flex flex-row mb-[20px] xs:gap-x-4 text-lg gap-x-2'}>
        <p className="font-bold">Заказ № {order.orderId}</p>
        <div className={'flex flex-row gap-x-2'}>
          <div>Статус:</div>
          <div style={{color: order.orderStatus.color}}>{order.orderStatus.title}</div>
        </div>

      </div>
      <div className={'flex flex-row xs:gap-x-4 xs:text-xl text-lg gap-x-2'}>
        <Link to={`/${city}/account/history/${order.orderId}`}>
          <BaseButton className={"w-full md+:text-sm text-xs bg-orderbtn text-main hover:bg-main hover:text-white font-medium break-words"}>
            Смотреть заказ
          </BaseButton>
        </Link>
        {order.questionnaireId &&
            <Link to={`/${city}/feedback/${order.questionnaireId}`}>
              <BaseButton className={"w-full md+:text-sm text-xs bg-orderbtn text-main hover:bg-main hover:text-white font-medium break-words"}>
                  Оставить отзыв
              </BaseButton>
            </Link>
        }
      </div>
      <span className="text-gray-30 text-end mt-[5px]">{resTime}</span>

    </div>
  )
}

export default function Notifications() {
  const { city } = useAppContext()
  const { updateMe, updateUser, user } = useAuthContext()

  //-------------pagination
  const {allData, nextPageAction, updateDataAction, isFetching, isInitialLoading} = usePagination(
    async (limit, page) => {
      const res: any = await apiClient.profileNotifications.getNotifications(limit, page)
      if (res.data)
        updateViewedNotifications(res.data).then(() => {
          apiClient.profileNotifications.getLatestNotification().then((res) => {
            if (res.data && res.status === 200) {
              updateUser({latestNotification: res.data.latestNotification, unreadNotificationsCount: res.data.unreadNotificationsCount})
            }
          })
        })
      return res
    }, {
      pageSize: 6,
      identity: item => item.id,
      startingPage: 1
    })

  useEffect(() => {
    const scrollHandler = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (scrollHeight - (scrollTop + clientHeight) < 250) {
        nextPageAction()
      }
    };
    document.addEventListener("scroll", scrollHandler);

    return function () {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  //--------------updating
  useEffect(() => {
    if (user && user.unreadNotificationsCount > 0) {
      //console.log("-------------------Update Notif-------------------")
      updateDataAction(user.unreadNotificationsCount)
    }
  }, [user?.unreadNotificationsCount])

  const updateViewedNotifications = async (notifications: Array<INotification>) => {
    const unViewedNotifications = notifications.filter((notification: INotification) => !notification.readAt)
      .map((notification: INotification) => (notification.id))

    if (unViewedNotifications?.length) {
      await apiClient.profileNotifications.readNotifications({notificationsIds: unViewedNotifications})
        .then(() => {}).catch(() => {})
    }
  }
  const [dayByNotificationId, setDayByNotificationId] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    let daysMap = new Map<string, string>()
    let lastDay = ''
    allData?.forEach((notification) => {
      const curDay = getDayAndFullMonth(new Date(notification.createdAt))
      if (lastDay !== curDay) {
        daysMap.set(notification.id, curDay)
        lastDay = curDay
      }
    })
    setDayByNotificationId(daysMap)
  }, [allData])

  const checkCurrentDay = (notification: INotification) => {
    const day = dayByNotificationId.get(notification.id);
    if (day) {
      return <p className="text-gray-30 xs:text-lg text-base mb-[18px]">
        {day}
      </p>
    }
  }


  return (
    <ProfileLayout>
      <div className={" lex flex-col gap-y-[25px] w-full md:pr-[3vh] xl:pr-[7vh]"}>
        <h2 className={"sm:text-[28px] text-2xl font-medium mb-[25px]"}>Уведомления</h2>

          <div className="flex flex-col ">
            {allData && allData.map((notification: INotification) => (
                <div key={notification.id}>
                  {
                    checkCurrentDay(notification)
                  }
                  <div className={'flex flex-col w-full pb-[20px]'}>
                    {
                      notification.type === 'distribution' ?
                      <DistributionCard distribution={notification.data} createdAt={notification.createdAt} /> : <></>
                    }
                    {
                      notification.type === 'common' ?
                        <CommonCard common={notification.data} createdAt={notification.createdAt} /> : <></>
                    }
                    {
                      notification.type === 'order-status-changed' ?
                        <OrderStatusChangeCard order={notification.data} city={city.slug} createdAt={notification.createdAt} /> : <></>
                    }
                  </div>
                </div>
              ))}
          </div>

        {!isInitialLoading && isFetching && <Preloader countOfDot={4} size="10px"/>}
        {isInitialLoading && <div className={'w-full py-[20vh]'}><Preloader countOfDot={4} size="10px"/></div>}

      </div>
    </ProfileLayout>
  );
}