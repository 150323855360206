import {localStorageWrap} from "../helpers/localStorageWrap";
import {cookieWrap} from "../helpers/cookieWrap";

export function prepareConfig(method: string, {anonymousRequest, ...options}: any = {}) {
  let user = null

  if (typeof window !== 'undefined' && !anonymousRequest) {
    user = JSON.parse(localStorageWrap.getItem('user') || 'null');
  }

  const config: {headers: any, method: string} = {
    headers: {},
    method,
    ...options
  };

  if (user?.token) {
    config.headers['Authorization'] = `Bearer ${user.token}`;
  }

  if (typeof window !== 'undefined' && sessionStorage.getItem('visitorId')) {
    config.headers['resultrest-visitor-id'] = sessionStorage.getItem('visitorId');
  }

  let branchId = cookieWrap.getCookie('branchId')

  if (!anonymousRequest && branchId) {
    config.headers['branch'] = branchId
  }

  config.headers['platform'] = 'vk';
  config.headers['Accept'] = 'application/json'
  config.headers['Content-Type'] = 'application/json'

  return config;
}

export async function checkResponse(response: any) {
  const {status} = response;

  const throwable = [413];

  if (401 === status) {
    if (localStorageWrap.getItem('user')) {
      localStorageWrap.removeItem('user')
      // await Router.push('/');
    }
    return 401;
  }

  if (throwable.includes(status)) {
    return status;
  }

  if (503 === status) {
    return 503;
  }

  return null;
}
