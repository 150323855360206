import React from 'react'

export default function NotFound() {
  return (
    <div className='min-h-screen flex items-center justify-center'>
      <div className='text-[100px]'>404</div>
      
    </div>
  )
}
