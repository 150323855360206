import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/App";
import { cookieWrap } from "./cookieWrap";


export default function useSelectCity() {
  const {reloadWithoutReload, city, branch} = useAppContext()
  const navigate = useNavigate();

  return async function selectCity(slug: string, targetBranchId?: string) {
    const atRoot = window.location.pathname === '/' || window.location.pathname === ''
    const fromBranchId = targetBranchId != null ? cookieWrap.getCookie('branchId') : `${branch?.id}`
    const toBranchId = targetBranchId != null ? `${targetBranchId}` : cookieWrap.getCookie('branchId')

    if (city?.slug !== slug || atRoot) {
      cookieWrap.setCookie('city', slug);
      if (targetBranchId != null)
        cookieWrap.setCookie('branchId', toBranchId)
      //setActive(false)
      const path = atRoot ? `${slug}` : window.location.href.replace(`/${city?.slug}`, `/${slug}`)
      console.log(`reloading city ${city?.slug}->${slug}`)
      try {
        await reloadWithoutReload();
      } catch(e) { console.error(e); }

      navigate(path)
    } else if (fromBranchId !== toBranchId) {
      console.log(`reloading branch ${fromBranchId}->${toBranchId}`)
      cookieWrap.setCookie('branchId', toBranchId)
      return await reloadWithoutReload();
    }
  }
}