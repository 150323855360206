export default async function getCity(pageProps: any, company: any, asPath: string) {
  if (pageProps?.cityInBasePage) { // Если пользователь зашёл только по домену и у него в куках был город
    let city = company.cities.filter((city: any) => city?.slug === pageProps?.cityInBasePage).at(0)
    if (city) {
      // нашли город по куке - выбираем его
      return {redirect: pageProps?.cityInBasePage, city}
    } else {
      // не нашли город по куке - выбираем первый
      return {redirect: company?.cities.at(0)?.slug, city: company?.cities.at(0)}
    }
  }
  // пытаемся получить город из юрла
  {
    let cityInReq: any = asPath.split('/').at(1)?.split('?').at(0)
    let city = company.cities.filter((city: any) => city.slug === cityInReq).at(0)
    if (city) {
      return { city }
    }
  }
  // если куки нет, но город только один
  if (company.cities.length === 1) { // Если пользователь зашёл только по домену, а у него в куках не было города, то проверяем если город у нас всего один то автоматом выбираем его
    return { redirect: company.cities.at(0)?.slug }
  }

  // если город загрузить обязательно - берем первый
  if (pageProps.alwaysLoadCity) {
    const city = company.cities.at(0)
    return { city }
  }

  // мы в корне - нужно открыть выбор города
  if (asPath === '/' || asPath === '')
    return { notFound: false }
  return { notFound: true }
}
