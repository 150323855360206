import { useEffect, useState } from "react";
import { useAppContext } from "../../../../contexts/App";
import useForm from "../../../../hooks/useForm";
import { apiClient } from "../../../../libs/api/apiClient";
import CloseIcon from "../../../../components/icons/CloseIcon";
import InputSelector from "../../../../components/common/form/input/InputSelector";
import { InputSelectorAddressSearchWithSaved } from "../../../../components/common/form/input/InputSelectorDadata";
import { TextInput } from "../../../../components/common/form/input/TextInput";
import { BaseButton } from "../../../../components/common/button/BaseButton";
import { useAddressContext } from "../../../../contexts/AddressContext";
import CustomRadioButton from "../../../../components/common/form/radio/CustomRadioButton";
import CustomTooltip from "../../../../components/common/tooltip";
import { formatAddress } from "../../../../libs/helpers/formatAddress";
import PopupActionSheet from "../../../../components/common/popup/PopupActionSheet";
import { useAuthContext } from "../../../../contexts/AuthContext";
import { Preloader } from "../../../../components/common/preloader";
import { ProfileLayout } from "../../../../components/layouts/profile";


interface ICreateAddress {
  data: any,
  updateData: (data: any) => void,
  selectAddress: (addr: any) => void,
  mode: string,
  onAction: (action: string) => void,
  allUserAddresses: any[],
}

export function CreateAddress({mode, onAction, data, updateData, selectAddress, allUserAddresses}: ICreateAddress){
  const { city, company, address: selectedAddress } = useAppContext()
  const { data: tempData, updateField: updateTempField } = useForm({...data, ...data.address});

  const [errorMessage, setErrorMessage] = useState(null)
  const [newCity, setNewCity] = useState<any>(city)
  const [ deliveryAddress, setDeliveryAddress] = useState<any>({name: '', addr: data.address})


  useEffect(() => {
    setDeliveryAddress({name: data.address?.title || '', addr: data.address})
  }, [data.address?.title])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (deliveryAddress?.addr) {
      const addr = {...tempData, address: deliveryAddress?.addr, city: newCity.guid};
      (mode === 'edit' ?
        apiClient.profileAddress.editAddress(addr, data.id) :
        apiClient.profileAddress.addAddress(addr)
      ).then(({data: newData, status, message}) => {
        if(status === 422) setErrorMessage(message)
        if (newData) {
          setErrorMessage(null)
          updateData(newData)
          onAction('save')
          if (data.id === selectedAddress?.point?.addressId) {
            selectAddress({...data, ...newData})
          }
        }
      })
    }
  }

  return (
    <form onSubmit={e => handleSubmit(e)}  className={"w-full flex flex-col gap-y-5 pt-5"}>
      {mode === 'create' && <div className={"flex justify-between"}>
          <p className={"text-lg font-medium"}>Новый адрес</p>
          <CloseIcon
              className={"w-[13px] h-[13px] cursor-pointer"}
              colorClassName={"fill-gray-30"}
              onClick={()=>onAction('none')}
          />
      </div>}
      <div className={"flex flex-col gap-y-4 sm:flex-row justify-between sm:gap-x-4"}>
        <InputSelector
          placeholder={"Выберите город"}
          variants={company.cities.map(({slug, title}:any) => ({id: slug, name: title}))}
          value={newCity.slug}
          onChange={slug => {
            setNewCity(company.cities.find((c: any) => c.slug === slug))
            setDeliveryAddress({name: ''})
            updateTempField('deliveryAddress', null)
            setErrorMessage(null)
          }}
        />
        <InputSelectorAddressSearchWithSaved
          value={deliveryAddress}
          placeholder={"Добавить новый адрес"}
          onChange={(a: any) => {
            setDeliveryAddress(a)
            setErrorMessage(null)
          }}
          guids={newCity ? [newCity.guid] : []}
          lat={newCity ? newCity.lat : null}
          lon={newCity ? newCity.lon : null}
          allUserAddresses={allUserAddresses.filter(o => o.id != null)}
          activeIcon={'iconSearch'}
        />
      </div>
      <div className={"flex flex-col gap-y-4 sm:flex-row sm:gap-x-4"}>
        <div className={"w-full flex flex-row gap-x-4"}>
          <TextInput
            placeholderInput={'Подъезд'}
            name={'entrance'}
            value={tempData.entrance || ''}
            onChange={(value)=>{updateTempField('entrance', value)}}
            className={"w-full "}
            classNameInput={""}
          />
          <TextInput
            placeholderInput={'Этаж'}
            name={'floor'}
            value={tempData.floor || ''}
            onChange={(value)=>{updateTempField('floor', value)}}
            className={"w-full"}
          />

          <TextInput
            required={true}
            placeholderInput={'Квартира'}
            name={'flat'}
            value={tempData.flat || ''}
            onChange={(value)=>{updateTempField('flat', value)}}
            className={"w-full "}
          />
        </div>
      </div>

        <div className={`${mode === 'edit' && (tempData.deliveryZone?.freeDeliveryFromTotal > 0 && tempData.deliveryZone?.freeDeliveryFromTotal !== null) ? 'opacity-1': 'hidden'} flex flex-row items-center gap-x-2`}>
          <div className={"block"}>
            <div className='cursor-default text-sm flex items-center justify-center h-5 w-5 border-[1.5px] border-main text-main font-medium rounded-full'>!</div>
          </div>
          <p className={"text-main"}>По выбранному адресу минимальная сумма до бесплатной доставки {tempData.deliveryZone?.freeDeliveryFromTotal} ₽</p>
        </div>
      {errorMessage && <p className={"text-main font-medium"}>{errorMessage}</p>}
      <div className={'flex flex-row gap-x-3'}>
        <BaseButton type={'submit'} className={"w-1/3 bg-main text-white font-medium hover:opacity-80"}>Сохранить</BaseButton>
        {mode === 'edit' && <BaseButton onClick={() => onAction('none')} className={"w-1/3 bg-orderbtn text-main font-medium"}>Отмена</BaseButton>}
      </div>
    </form>
  )
}

interface IAddress {
  data: {
    entrance?: string,
    floor?: string,
    apartment?: string,
    flat?: string,
    address?: any,
    city: any,
    deliveryZone?: any,
    id?:any,
  },
  updateData: (data: any) => void,
  mode: string,
  onAction: (action: string) => void,
  allUserAddresses: any[],
}
export function Address({data, updateData, mode, onAction,allUserAddresses}: IAddress) {
  const { address, entrance, floor, flat, city} = data
  const { address: selectedAddress, setAddress: setSelectedAddress, branch, company} = useAppContext()
  const {trySaveDeliveryPoint} = useAddressContext()
  const [isAcceptDeleteAddress, setIsAcceptDeleteAddress] = useState(false)

  const selectAddress = (point: any) => {
    const pointCity = company?.cities?.find((c: any) => c.guid === point?.city?.guid)
    const deliveryPoint = {...point.address, addressId: point.id, selectAddress: true, deliveryZoneId: point.deliveryZone?.id, city: pointCity?.slug};
    trySaveDeliveryPoint(pointCity, deliveryPoint).then((success) => {})
  }

  const editComponent = <CreateAddress
    data={data}
    updateData={updateData}
    selectAddress={selectAddress}
    mode={mode}
    onAction={onAction}
    allUserAddresses={allUserAddresses}
  />

  if (mode === 'create') return editComponent;

  return (
    <>
      <div className={"flex flex-row gap-x-4 items-center"}>
        <CustomRadioButton
          checked={data.id === selectedAddress?.point?.addressId}
          onClick={()=>{
            if (data.id !== selectedAddress?.point?.addressId) selectAddress(data)
          }}/>

        <div className={"flex flex-col gap-x-3 gap-y-2"}>
          <p className={`${mode !== 'none' ? 'text-gray-30' : ''}`}>{formatAddress(address, [])} {entrance? `под ${entrance},` : ``} {floor? `эт ${floor},` : ``} кв {flat}</p>
          <div className={'flex flex-row gap-x-3'}>
            { mode === 'none' && <p onClick={() => onAction('edit')} className={"text-main text-sm cursor-pointer hover:opacity-80"}>Изменить</p> }
            { mode === 'none' && <p onClick={() => setIsAcceptDeleteAddress(true)} className={"text-main text-sm cursor-pointer hover:opacity-80"}>Удалить</p> }
          </div>
          { mode !== 'none' && editComponent }
        </div>
        {
          mode === 'none' && (data.deliveryZone?.freeDeliveryFromTotal > 0 && data.deliveryZone?.freeDeliveryFromTotal !== null) &&
          <div className={"relative"}>
              <CustomTooltip
                  symbol={'!'}
                  color={"text-main"}
                  positionClassName={'z-50 top-[20px] sm:top-[20px] right-4 md:top-[38px]  md:right-[-25px] lg:right-[-50px] w-[230px]'}
              >
                  минимальная сумма до бесплатной доставки {data.deliveryZone?.freeDeliveryFromTotal} ₽
              </CustomTooltip>
          </div>

        }
      </div>
      <PopupActionSheet
        isActive={isAcceptDeleteAddress}
        closeIcon
        close={() => setIsAcceptDeleteAddress(false)}
      >
        <p className="text-center">Вы действительно хотите удалить адрес?</p>
        <BaseButton className="w-full bg-orderbtn text-main hover:bg-main hover:text-white mt-5" onClick={() => setIsAcceptDeleteAddress(false)}>Отмена</BaseButton>
        <BaseButton 
          className="w-full bg-main text-white hover:opacity-80 xs:mt-4 mt-3" 
          onClick={() => {
            onAction('delete')
            setIsAcceptDeleteAddress(false)
          }}
        >
          Удалить
        </BaseButton>
      </PopupActionSheet>
    </>
  )
}
export default function MyAddress() {

  const { branch } = useAppContext()
  const { user } = useAuthContext()
  const { fetchAllUserAddresses } = useAddressContext()


  const [editMode, setEditMode] = useState('none')
  const [editIndex, setEditIndex] = useState(-1)


  const [addresses, setAddresses] = useState<any>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  const removeAddress = (id: number) => {
    apiClient.profileAddress.removeAddress(id).then(() => {})
  }

  useEffect(() => {
    if (user == null || branch?.id == null) {
      setAddresses([])
      return;
    }
    setLoading(true)
    const fetchAddresses = () => {
      apiClient.profileAddress.addresses(branch.id)
      .then(({data}) => {
        setAddresses(data)
        setEditIndex(-1)
        setEditMode('none')
        setLoading(false)
      })
    }
    fetchAddresses()
  }, [])

  return (
    <ProfileLayout>
      <div className={"flex w-full gap-y-10 z-[45] static"}>
        <div className={"flex w-full flex-col gap-y-6"}>
          <p className={"text-2xl font-medium"}>Мои адреса</p>

          {isLoading && <div className={'w-full py-[20vh]'}><Preloader countOfDot={4} size="10px"/></div>}

          {
            addresses.map((c:any, index:any) => {
              const onAction = (action: string) => {
                if (action === 'delete' || action === 'save') {
                  fetchAllUserAddresses().then()
                }

                if (action === 'delete') {
                  removeAddress(c.id)
                  setAddresses(addresses.filter((o: any, i: any) => i !== index))
                  setEditMode('none')
                  setEditIndex(-1)
                }
                if (action === 'create' || action === 'edit') {
                  if (editMode === 'create') {
                    setAddresses(addresses.filter((o: any, i: any) => i !== editIndex))
                  }
                  setEditIndex(index);
                  setEditMode(action)
                }

                if (editIndex !== index)
                  return

                if (action === 'save') {
                  // send to server
                  setEditMode('none')
                  setEditIndex(-1)
                }
                if (action === 'none') {
                  if (editMode === 'create') {
                    // closed newly created
                    setAddresses(addresses.filter((o: any, i: any) => i !== index))
                  } else {
                    // closed when editing (not by saving)
                  }
                  setEditMode('none')
                  setEditIndex(-1)
                }
              };

              return <Address
                key={index}
                data={c}
                updateData={(d) => {
                  setAddresses(addresses.map((addr: any, i: any) => {
                    if (i === index) {
                      return {...addr, ...d}
                    } else {
                      return addr;
                    }
                  }))
                }}
                mode={editIndex === index ? editMode : 'none'}
                onAction={onAction}
                allUserAddresses={addresses}
              />
            })
          }

          {editMode === 'none' &&
              <BaseButton
                onClick={() => {
                  setEditMode('create')
                  setEditIndex(addresses.length)
                  setAddresses([...addresses, {address:{}}])
                }}
                className={"w-2/3 sm:w-1/2 border-[2px] border-main hover:bg-main font-medium text-main hover:text-white"} >
                Добавить новый адрес
              </BaseButton>
          }
        </div>
      </div>
    </ProfileLayout>
  )
}