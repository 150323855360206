import {apiClient} from "../api/apiClient";

export async function getDeliveryZoneAndBranchByCoords(city: { branches: { id: number }[] }, lat: number, lon: number, priorityBranches: number[] = []) {
  const {data, status} = await apiClient.delivery.getDeliveryZonesByPosition(lat, lon)
  if (status === 404 || !data)
    return null;

  return Object.values(data).map((z: any) => {
    let branches : number[] = z.branches;
    let branchIds = branches.filter((branchId: number) => city.branches.find((b: any) => b.id == branchId) != null)
    let priorityBranchId = priorityBranches.find(b => branchIds.find(id => id == b) != null);
    let branchId = priorityBranchId != null ? priorityBranchId : branchIds.at(0);
    return {branchId, deliveryZoneId: z.id as number, deliveryZone: z, _sort: priorityBranchId != null ? 1 : 0};
  }).sort((a, b) => b._sort - a._sort).find(o => o.branchId != null) as { branchId: number, deliveryZoneId: number, deliveryZone: any}
}

export async function getDeliveryZoneByForBranch(branchId: number | string, lat: number, lon: number) {
  const {data, status} = await apiClient.delivery.getDeliveryZoneByBranchAndPosition(branchId, lat, lon)
  if (status === 404 || !data)
    return null;
  return data;
}

export function getBranchIdFromDeliveryZone(deliveryZone: any, city: any, curBranch: any = null) {
  let branches : number[] = deliveryZone?.branches || []
  if (city?.branches?.length > 0) branches = branches.filter((branchId: number) => city.branches.find((b: any) => b.id == branchId) != null)
  let branchId = branches.find(b => b == curBranch?.id);
  if (branchId == null) branchId = branches[0];
  return branchId as number | undefined;
}
