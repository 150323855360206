import bridge from "@vkontakte/vk-bridge";

let inited = false;
let vkStorage: {[key: string]: any} = {}
let vkStorageFlushId = 0;

const TERMINATING_SEQUENCE = 'END'
const MAX_STORAGE_VARS = 8

function serializeJson(data: any, maxChars = 2048) {
  let str = JSON.stringify(data);
  let result : string[] = []
  while (str.length > maxChars) {
    result.push(str.substring(0, maxChars));
    str = str.substring(maxChars);
  }
  if (str.length > 0)
    result.push(str);
  if (result.length === 0)
    result.push('')
  return result.map((s, i) => ({key: `storage${i}`, value: i == result.length - 1 ? s + TERMINATING_SEQUENCE : s}))
}

function deserializeJson(arr: string[]) {
  // console.log('deserializing storage', arr)
  let str = ""
  for (let s of arr) {
    if (s.endsWith(TERMINATING_SEQUENCE)) {
      str += s.substring(0, s.length - TERMINATING_SEQUENCE.length);
      break;
    }
    str += s
  }
  let result = {};
  try {
    if (str.length > 0)
      result = JSON.parse(str);
  } catch(e) { console.log('failed to parse vk storage', str, arr); }
  return result;
}

function vkFlush() {
  vkStorageFlushId++;
  let flushId = vkStorageFlushId;
  setTimeout(() => {
    if (flushId !== vkStorageFlushId) return;
    let serStorage = serializeJson(vkStorage);
    // console.log('serialized storage', serStorage)
    serStorage.forEach((s) => bridge.send("VKWebAppStorageSet", s).then())
  }, 200);
}



export const localStorageWrap = {
  init: async () => {
    try {
      let keys = Array.from(Array(MAX_STORAGE_VARS).keys()).map((i) => `storage${i}`)
      let rawData = await bridge.send('VKWebAppStorageGet', {keys});
      let data: any = {}
      rawData.keys.forEach(({key, value}) => data[key] = value)
      let arr: string[] = []
      keys.forEach(key => data[key] && arr.push(data[key]))
      vkStorage = deserializeJson(arr)
      console.log("vk storage parsed", vkStorage)
    } catch(e) {
      console.log("error", e)
    }
    inited = true;
  },

  getItem: (key: string) : any => {
    if (!inited) throw new Error("localStorageWrap.init() wasn't called")
    return vkStorage[key] || null;
  },

  setItem: (key: string, value: any) : void => {
    if (!inited) throw new Error("localStorageWrap.init() wasn't called")

    vkStorage[key] = value;
    vkFlush();
  },

  removeItem: (key: string) => {
    if (!inited) throw new Error("localStorageWrap.init() wasn't called")
    delete vkStorage[key];
    vkFlush();
  },
}
