import {useState} from 'react'

interface IShowFullText {
  text: string,
  headerTextForClick: string,
  classNameHeaderTextForClick?: string,
}

export const ShowFullText = ({text, headerTextForClick, classNameHeaderTextForClick}: IShowFullText) => {
  const [isShowFullText, setIsShowFullText] = useState(false)

  return (
    <>
      {isShowFullText ?
        <>
          <div className={`text-gray-40  dark:text-white font-bold mb-[10px] xs:text-md text-sm ${classNameHeaderTextForClick}`}>
            {headerTextForClick}
          </div>
          <div className='text-gray-50 dark:text-gray-10 xs:text-base text-sm'>{text}</div>
          <div className={'text-main underline cursor-pointer mt-[10px]'} onClick={() => setIsShowFullText(false)}>Скрыть</div>
        </> 
        :
        <div 
          className={`text-gray-40 dark:text-white font-bold cursor-pointer xs:text-md text-sm ${classNameHeaderTextForClick}`} 
          onClick={() => setIsShowFullText(true)}
        >
          {headerTextForClick}
        </div>
      }
    </>
  )
}