import React, {useEffect, useMemo, useRef, useState} from 'react'
import Slider from 'react-slick';
import { useCartContext } from '../../../../contexts/CartContext';
import { useAppContext } from '../../../../contexts/App';
import ArrowIcon from '../../../icons/ArrowIcon';
import { useProductDetailCardContext } from '../../../../contexts/ProductDetailCardContext';
import { resizeImage } from '../../../../libs/helpers/resizeImage';
import productImgStub from '../../../../images/productImgStub.jpg'
import { formatCurrency } from '../../../../libs/helpers/formatCurrency';
import CrossedOldPriceIcon from '../../../icons/CrossedOldPriceIcon';
import { Preloader } from '../../../common/preloader';



export default function RecommendedProducts() {
  const { cart } = useCartContext()
  const { allProducts } = useAppContext()

  const [isShowLeftArrow, setIsShowLeftArrow] = useState(false)
  const [isShowRightArrow, setIsShowRightArrow] = useState(false)

  const slider = useRef<any>(null);

  const recommendableProducts = useMemo(() => {
    return Object.values(cart.options.recommendable.reduce((pIds: any, pId: number) => ({...pIds, [pId]: allProducts[pId]}), {}))
      .filter((product: any) => product)
  }, [JSON.stringify(cart.options.recommendable)])

  useEffect(() => {
    setIsShowRightArrow(recommendableProducts.length <= 2 ? false : true)
  }, [recommendableProducts])

  return (
    <>
      {
        !!cart.options.recommendable.length &&
        <div className={`pt-1 md:pt-[15px] mx-[-20px] `}>

          <div className='text-xl -tracking-[.01em] font-bold mt-1 md:mt-[15px] ml-5'>Рекомендуем к заказу</div>

          <div className={'relative'}>
              <button
                  className={`${isShowLeftArrow? 'lg:block' : 'lg:hidden'} absolute hidden h-full top-0 -left-[45px]`}
                  onClick={() => slider.current?.slickPrev()}
              >
                  <div className="rotate-180">
                      <ArrowIcon className="w-[13px] h-[21px]" />
                  </div>
              </button>

              <Slider
                  ref={slider}
                  variableWidth={true}
                  arrows={false}
                  infinite={false}
                  afterChange={(currentSlide) => {
                    if (currentSlide === 0) {
                      setIsShowLeftArrow(false)
                    } else{
                      setIsShowLeftArrow(true)
                    }
                    if (currentSlide >= recommendableProducts.length - 1){
                      setIsShowRightArrow(false)
                    }
                    else if (recommendableProducts.length <= 2 ){
                      setIsShowRightArrow(false)
                    } else {
                      setIsShowRightArrow(true)
                    }
                  }}
              >
                {
                  recommendableProducts.map((product: any) => (
                    <div className='mx-[20px] mr-[0px]' key={product.id}>
                      <Product product={product}/>
                    </div>
                  ))
                }
              </Slider>

              <button
                  className={`${isShowRightArrow? 'lg:block' : 'lg:hidden'} absolute hidden h-full top-0 -right-[45px]`}
                  onClick={() => slider.current?.slickNext()}
              >
                  <ArrowIcon className="w-[13px] h-[21px]" />
              </button>
          </div>

        </div>
      }
    </>
  )
}

function Product({ product }: any) {
  const { allProducts, branch } = useAppContext()
  const { openDetail } = useProductDetailCardContext()
  const { cart, addProductServer, updateProductServer } = useCartContext()
  const [load, setLoad] = useState(false)

  const productCart = useMemo(() => {
    return cart.products.filter(p => !p.isAdded && !p.isGift && !p.isChosenGift && p.productId == product.id)[0]
  }, [JSON.stringify(cart.products)])

  return (
    <div onClick={() => openDetail(product.id)} className='h-full w-[min(321px,93vw)] 3xs:w-[321px] xs:w-[341px] gap-1 3xs:gap-4 flex p-3 cursor-pointer bg-white dark:bg-gray-40 rounded-[29px] my-4 shadow-md'>
      {
        product.image ?
          <div className='rounded-[17px] overflow-hidden xs:w-[125px] xs:h-[125px] w-[106px] h-[106px]'>
            <picture className={'xs:h-[125px] h-[106px]'}>
              <source className={'xs:h-[125px] h-[106px]'} type="image/webp" srcSet={`${resizeImage(product.image, '125', 'webp')} 1x, ${resizeImage(product.image, '300', 'webp')} 2x, ${resizeImage(product.image, '400', 'webp')}3x`} />
              <source className={'xs:h-[125px] h-[106px]'} type="image/jpeg" srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`} />
              <img
                className={'xs:h-[125px] h-[106px]'}
                src={resizeImage(product.image, '125', 'jpg')}
                srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`}
                alt=""
              />
            </picture>
          </div> :
          <img
            className='xs:w-[125px] xs:h-[125px] w-[106px] h-[106px] rounded-[17px]'
            src={productImgStub}
            alt=""
          />
      }

      <div className='grow flex flex-col justify-between'>
        <div className='lg:text-lg text-md leading-[127.02%] -tracking-[.01em] font-semibold text-dark dark:text-light'>{product.title}</div>

        {
          !!(allProducts[product.id]?.canBuyForFree && !allProducts[product.id]?.price || allProducts[product.id]?.price) &&
          <div className={'xs:min-h-[50px] min-h-[45px] flex items-center'}>
            {
              !!(allProducts[product.id].skuGroupsValues.length || allProducts[product.id].optionsExists) ?
                <div
                  className='flex items-center justify-center w-[110px] h-[35px] bg-orderbtn rounded-[18px] cursor-pointer -tracking-[.01em] font-bold text-main hover:bg-main hover:text-white duration-500'
                >
                  Выбрать
                </div> :
                <div className={'flex flex-row items-center'}>
                    <div className={`flex items-center ${!!product.oldPrice ? 'flex-col' : 'flex-row'}`}>
                      <div className='text-main font-bold text-[16px] xs:text-lg flex items-center '>{formatCurrency(branch.extended.currency.code, product.price)}</div>
                      {!!product.oldPrice &&
                        <div className='relative w-[80%] text-sm leading-[17px] text-gray-50 dark:text-gray-30'>
                          <div className='flex items-center opacity-60'>{formatCurrency(branch.extended.currency.code, product.oldPrice)}</div>
                          <div className='absolute w-full h-full left-0 top-0'>
                            <CrossedOldPriceIcon width={'100%'} height={'auto'} />
                          </div>
                        </div>
                      }
                    </div>
                  {
                    !!productCart ?
                      <div
                        onClick={event => {
                          event.stopPropagation()
                          setLoad(true)
                          updateProductServer(productCart.uid, { count: 0 }).then(() => setLoad(false))
                        }}
                          className='w-[110px] h-[35px] flex items-center justify-center border-[2px] rounded-[18px] border-main text-main hover:bg-main hover:text-white font-bold cursor-pointer ml-[5px] 3xs:ml-2 '
                      >
                        {load ? <Preloader countOfDot={3} color={'orderbtn'} size={'8px'} className={'w-[110px] gap-2'} /> : 'Добавлено'}
                      </div> :
                      <div
                        onClick={event => {
                          event.stopPropagation()
                          setLoad(true)
                          addProductServer(product.id, [], product.minCount, { isFromRecommendable: true }).then(() => setLoad(false))
                        }}
                          className='w-[110px] h-[35px] flex items-center justify-center bg-orderbtn rounded-[18px] text-main hover:bg-main hover:text-white font-bold cursor-pointer ml-[5px] 3xs:ml-2 '
                      >
                        {load ? <Preloader countOfDot={3} color={'orderbtn'} size={'8px'} className={'w-[110px] gap-2'} /> : 'Добавить'}
                      </div>
                  }
                </div>
            }
          </div>
        }
      </div>
    </div>
  )
}
