import React, {useEffect, useRef, useState} from "react";
import PopupActionSheet from "../PopupActionSheet";
import { useAppContext } from "../../../../contexts/App";
import { useCartContext } from "../../../../contexts/CartContext";
import { TextInputPhone, isCompletedPhoneNumber } from "../../form/input/TextInputPhone";
import { apiClient } from "../../../../libs/api/apiClient";
import { localStorageWrap } from "../../../../libs/helpers/localStorageWrap";
import { Preloader } from "../../preloader";
import TgIcon from "../../../icons/TgIcon";
import VkIcon from "../../../icons/VkIcon";
import { Link } from "react-router-dom";
import { BaseButton } from "../../button/BaseButton";
import { TextInputCode } from "../../form/input/TextInput";


interface IAuth {
  isActive: boolean,
  close?: () => void,
  showCloseButton?:boolean,
  onSuccess: (x: {phone: string, type: string, token?: string, user?: any}) => void,
  startingType?:string,
  startingStep?:string,
  defaultPhone?:string,
  phoneConfirmMode?: 'always' | 'check',
  titleByMode?:any,
  modalFromCart?: boolean,
}

const openInNewTab = (link: string, widthTab = 600, heightTab= 300) => {
  if (!link) return;
  const theTop=((window.screen.height/2)-(heightTab/2))/2;
  const theLeft=(window.screen.width/2)-(widthTab/2);
  window.open(link, 'MyWindow',`width=${widthTab},height=${heightTab},top=${theTop},left=${theLeft}`)?.focus()
}

export default function AuthPopup({
isActive,
close,
onSuccess,
showCloseButton=true,
startingStep = 'none',
startingType = 'none',
defaultPhone = '',
phoneConfirmMode = 'always',
titleByMode={'none': 'Вход на сайт', 'phone': 'Вход на сайт', 'telegram': 'Вход на сайт через Telegram', 'vk': 'Вход на сайт через Вконтакте'},
modalFromCart= false
}: IAuth) {
  const { company, branch, city } = useAppContext()
  const { cart, updateCart } = useCartContext()

  const [mode, setMode] = useState<string>(startingStep)
  const [type, setType] = useState<string>(startingType)
  const [lastSendType, setLastSendType] = useState<string>('')

  const [phone, setPhone] = useState<string>(defaultPhone)
  const [code, setCode] = useState<string>('')
  const [hash, setHash] = useState<string>('')
  const [statusCode, setStatusCode] = useState<string>('none')

  const [linkExternal, setLinkExternal] = useState<string>('')

  const [codeFailMessage, setCodeFailMessage] = useState<string>('')
  const [errorsMessage, setErrorsMessage] = useState<string | null>(null)

  const [isLoadingButton, setLoadingButton] = useState<boolean>(false)
  const [counterTimer, setCounterTimer] = useState<number>(0)

  const isPhoneComplete = isCompletedPhoneNumber(phone, branch?.extended.phoneCode.code, branch?.extended.phoneCode.mask);

  const timerRef = useRef<any>(null)
  const timer = (t: number) => {
    setCounterTimer(t);
    if (timerRef.current != null)
      clearInterval(timerRef.current)
    if (t > 0) {
      timerRef.current = setInterval(() => {
        setCounterTimer(x => {
          if (x <= 1) {
            clearInterval(timerRef.current)
          }
          return x - 1
        })
      }, 1000)
    }
  }

  const refreshTimer = () => {
    if (!isActive || branch?.id == null) return;
    apiClient.auth.retryAfter(branch?.id).then(({retryAfter}: any) => {
      if (retryAfter != null) {
        timer(retryAfter);
      }
    })
  }

  useEffect(refreshTimer, [isActive, type, branch?.id]);

  useEffect(() => {
    if (code.length === 4) {
      setStatusCode('wait')
      let dataForCodeCheck: any = { code }

      if (type === 'phone' && hash) {
        dataForCodeCheck.hash = hash
      }

      apiClient.auth.codeCheck(dataForCodeCheck).then(({message, data}: any) => {
        if (data) {
          onSuccess({phone, type, token: data.token, user: data.user});
          setStatusCode('success')
        } else {
          setStatusCode('fail')
          setCodeFailMessage(message)
        }
      })
    }
  }, [code])

  const cancel = () => {
    if (close)
      close()
    setPhone(defaultPhone)
    setCode('')
    setMode(startingStep)
    setLoadingButton(false)
    setType(startingType)
    setErrorsMessage(null)
    setCodeFailMessage('')
    setStatusCode('none')
  }

  useEffect(() => {
    if (!isActive) {
      setPhone(defaultPhone)
      setCode('')
      setMode(startingStep)
      setType(startingType)
      setErrorsMessage(null)
      setCodeFailMessage('')
      setStatusCode('none')
    }
  }, [isActive])

  useEffect(() => setType(startingType), [startingType])
  useEffect(() => setMode(startingStep), [startingStep])
  useEffect(() => setPhone(defaultPhone), [defaultPhone])


  useEffect(() => {
     if (type === 'telegram' && mode === 'phoneNumber') {
      setMode('code')
      requestCode('telegram')
    }
  },[type])

  useEffect(() => {
    if (type === 'vk' && mode === 'phoneNumber') {
      requestCode('vk')
      localStorageWrap.removeItem('tokenVk')
      const handle = setInterval(()=>{
        const tokenVk = localStorageWrap.getItem('tokenVk')
        if (tokenVk) {
          apiClient.auth.authVkCheck(tokenVk).then(({status, data, message}: any) => {
            if (status === 200 && data) {
              const {token, user} = data
              if (user) {
                onSuccess({phone, type, token, user})
              }
            } else {
              setErrorsMessage(message)
            }
          })
          localStorageWrap.removeItem('tokenVk')
          clearInterval(handle)
        }
      }, 50)
      return () => {
        clearInterval(handle)
      }
    }
  }, [type, mode])

  const loginTg = () => {
    apiClient.auth.loginTelegram(branch.id, {phone}).then(({message, status, link, CODE}: any) => {
      if (CODE) console.log("USE THIS CODE " + CODE)
      if (status === 200 || status === 201) {
        window.open(link, '_blank')?.focus()
        setMode('code')
        setLinkExternal(link)
      } else {
        setErrorsMessage(message);
      }
      setStatusCode('none')
      setLastSendType('telegram')
      setLoadingButton(false)
    })
  }

  const confirmPhoneViaTelegram = () => {
    apiClient.auth.confirmPhoneViaTelegram(branch.id, { phone }).then(({ message, status, link, CODE }: any) => {
      if (status === 200 || status === 201) {
        window.open(link, '_blank')?.focus()
        setMode('code')
        setLinkExternal(link)
      } else {
        setErrorsMessage(message);
      }
      setStatusCode('none')
      setLastSendType('telegram')
      setLoadingButton(false)
    })
  }

  const loginPhone = () => {
    apiClient.auth.loginPhone(branch.id, {phone}).then(({message, using, status, hash, retryAfter, CODE}: any) => {
      if (CODE) console.log("USE THIS CODE " + CODE)
      if (using) console.log('CODE SENT USING', using)
      if (status !== 200 && status !== 201) {
        setMode('phoneNumber')
        setCode('')
        setErrorsMessage(message)
      } else {
        setMode('code')
      }
      setStatusCode('none')
      setLastSendType(using === 'sms' ? 'sms' : 'call')
      if (retryAfter != null) {
        timer(retryAfter)
      } else {
        refreshTimer();
      }
      setHash(hash)
      setLoadingButton(false)
    })
  }

  const loginVk = () => {
    apiClient.auth.loginVk(branch.id, window.location.href.split('?')[0].replace('#', '')).then(({status, redirectTo}: any) => {
      if (status === 200 || status === 201) {
        openInNewTab(redirectTo)
        setLinkExternal(redirectTo)
      }
    })
  }

  const tryToCheckOrLogin = (allowedAuthorizeTypes: any, confirm: boolean = false) => {
    if (allowedAuthorizeTypes.includes('call') || allowedAuthorizeTypes.includes('sms')) {
      loginPhone();
    } else if (allowedAuthorizeTypes.includes('vk')) {
      setType('vk')
      loginVk();
    } else {
      if (confirm) {
        confirmPhoneViaTelegram()
      } else {
        setType('telegram')
        loginTg()
      }
    }
  }

  const requestCode = (sendType: string) => {
    setErrorsMessage(null)
    setCodeFailMessage('')
    setStatusCode('wait')
    setCode('')

    if (sendType === 'phone') {
      setLoadingButton(true)

      if (phoneConfirmMode === 'check') {
        apiClient.cart.update(branch.id, {cartId: cart.cartId, clientPhone: phone}).then((data: any) => {
          if (data.isPhoneConfirmRequired) {
            if (data?.allowedAuthorizeTypesConfirmingPhone && 
              data?.allowedAuthorizeTypesConfirmingPhone instanceof Array) {
              setErrorsMessage(data.message + '')
              tryToCheckOrLogin(data?.allowedAuthorizeTypesConfirmingPhone, true)
              return
            }

            tryToCheckOrLogin(allowedAuthorizeTypes)
            /*if (allowedAuthorizeTypes.includes('call') || allowedAuthorizeTypes.includes('sms')) {
              loginPhone();
            } else {
              setType('telegram')
              loginTg();
            }*/
          } else {
            if (data.status < 300) {
              onSuccess({type, phone})
              cancel()
            } else {
              setMode('phoneNumber')
              setCode('')
              setLoadingButton(false)
              setErrorsMessage(data.message + '')
            }
          }
        })
        // apiClient.auth.phoneCheck({phone}).then(({exists, message, status}) => {
        //   if (status === 200) {
        //     if(branch?.extended?.allowGuestOrderInMobile && phoneConfirmMode === 'check') {
        //       onSuccess({type, phone})
        //       cancel()
        //       return
        //     }
        //     if (!exists) {
        //       onSuccess({type, phone})
        //       cancel()
        //       return
        //     } else {
        //       loginPhone();
        //     }
        //   } else {
        //     setMode('phoneNumber')
        //     setCode('')
        //     setLoadingButton(false)
        //     setErrorsMessage(message)
        //   }
        // })
      } else {
        if (allowedAuthorizeTypes.includes('call') || allowedAuthorizeTypes.includes('sms')) {
          loginPhone();
        } else {
          setType('telegram')
          loginTg();
        }
      }
    }

    if (sendType === 'telegram') {
      loginTg();
    }

    if (sendType === 'vk') {
      loginVk()
    }
  }

  const allowedAuthorizeTypes = Object.values(branch?.extended?.allowedAuthorizeTypes || {})

  return (
    <PopupActionSheet isActive={isActive} closeIcon={showCloseButton} close={cancel}>

        {mode === 'none' && <AuthPopupTypeModeSelect
            allowedAuthorizeTypes={allowedAuthorizeTypes}
          setType={setType}
          setMode={setMode}
          requestCode={requestCode}
          title={titleByMode['none']}
        />}

        {mode === 'phoneNumber' && <AuthPopupPhoneInput
          setMode={setMode}
          type={type}
          startingStep={startingStep}
          phoneConfirmMode={phoneConfirmMode}
          phone={phone}
          setPhone={setPhone}
          isPhoneComplete={isPhoneComplete}
          errorsMessage={errorsMessage}
          allowedAuthorizeTypes={allowedAuthorizeTypes}
          requestCode={requestCode}
          isLoadingButton={isLoadingButton}
          statusCode={statusCode}
          counterTimer={counterTimer}
          title={titleByMode[type]}
          linkExternal={linkExternal}
          modalFromCart={modalFromCart}
        />}

        {mode === 'code' && <AuthPopupCodeInput
          type={type}
          setType={setType}
          startingType={startingType}
          allowedAuthorizeTypes={allowedAuthorizeTypes}
          phone={phone}
          setCode={setCode}
          setMode={setMode}
          statusCode={statusCode}
          code={code}
          counterTimer={counterTimer}
          requestCode={requestCode}
          isPhoneComplete={isPhoneComplete}
          isLoadingButton={isLoadingButton}
          lastSendType={lastSendType}
          codeFailMessage={codeFailMessage}
          title={titleByMode[type]}
          linkExternal={linkExternal}
        />}

      </PopupActionSheet>
  )
}

function ButtonWithTimer({counterTimer, text, timerPrefix, timerPostfix, disabled, preloader, ...props}: any) {
  if (counterTimer <= 0)
    return <BaseButton disabled={disabled || preloader} {...props}>
      {preloader ? <Preloader countOfDot={4} color={'white'} className={'gap-x-2 py-1.5'} size={'8px'}/> : text}
    </BaseButton>
  return <BaseButton disabled={true} {...props}>
    {timerPrefix} {counterTimer > 60 ? `${Math.floor(counterTimer / 60)}:${counterTimer % 60}` : counterTimer + ' секунд'} {timerPostfix}
  </BaseButton>
}
//------------------------------------------------SelectType------------
function AuthPopupTypeModeSelect({
 allowedAuthorizeTypes,
 setType,
 setMode,
 title,
 requestCode
}: any) {

  return (<>
    <p className={"text-xl font-medium mb-2"}>{title}</p>
    <p>Выберите способ авторизации</p>
    <div className={"mt-6 flex flex-col gap-y-4"}>
      {/*allowedAuthorizeTypes.includes('tg') && */}
      {(<BaseButton onClick={() => {
        setType('telegram')
        requestCode('telegram')
      }} className={"bg-orderbtn w-full text-main gap-x-3 hover:opacity-80"}>
        <TgIcon/>
        <p>Войти с помощью Telegram</p>
      </BaseButton>)
      }

      {allowedAuthorizeTypes.includes('vk') && (<BaseButton  onClick={() => {
        setType('vk')
        setMode('phoneNumber')
        requestCode('vk')
      }} className={"bg-orderbtn w-full text-main gap-x-3 hover:opacity-80"}>
        <VkIcon colorClassName={"fill-main"} className={"w-[17px] h-[10px]"}/>
        <p>Войти с помощью Vk</p>
      </BaseButton>)
      }

      { (allowedAuthorizeTypes.includes('call') || allowedAuthorizeTypes.includes('sms')) && (<BaseButton onClick={() => {
        setType('phone')
        setMode('phoneNumber')
      }} className={"bg-main w-full text-white hover:opacity-80"}>По номеру телефона
      </BaseButton>)
      }
    </div>
  </>)
}
//------------------------------------------------PhoneInput------------
function AuthPopupPhoneInput({
  setMode,
  type,
  startingStep,
  phone,
  setPhone,
  isPhoneComplete,
  errorsMessage,
  requestCode,
  counterTimer,
  isLoadingButton,
  title,
  phoneConfirmMode,
  linkExternal,
  modalFromCart
}: any) {
  const {branch} = useAppContext()

  return (
    <>
      <p className={"text-xl font-medium mb-2"}>{title}</p>
      <div className={"flex flex-col gap-y-5 mt-5"}>

        {type === 'phone' &&
          <>
            {!modalFromCart && <p className={"text-gray-40"}>Номер телефона</p>}
              
              <TextInputPhone
                  value={phone}
                  mask={branch?.extended.phoneCode.code}
                  format={branch?.extended.phoneCode.mask}
                  onChange={setPhone}
                  onEnterPressed={() => ((counterTimer <= 0 && isPhoneComplete) || type === 'telegram') && requestCode(type)}
                  className={`w-full text-xl bg-gray-20 ${isPhoneComplete ? 'text-main' : 'text-gray-40 dark:text-gray-30'} ${errorsMessage ? 'border-[2px] border-main' : ''}`}
                  classNameInput={`h-9`}
              />
            {errorsMessage && <p className={"font-medium w-full text-center text-main"}>{errorsMessage}</p>}
              <ButtonWithTimer
                  onClick={() => {
                    isPhoneComplete && requestCode('phone')
                  }}
                  preloader={isLoadingButton}
                  counterTimer={counterTimer}
                  text={phoneConfirmMode === 'check' ? 'Подтвердить' : 'Получить код'}
                  timerPrefix={counterTimer > 120 ? 'Слишком много попыток, код через' : 'Получите код через '}
                  className={`${isPhoneComplete && counterTimer <= 0 ? 'bg-main text-white' : 'bg-orderbtn text-main'}  w-full `}
                  disabled={!isPhoneComplete}
              />

            {
              startingStep == 'none' &&
                <BaseButton className="bg-orderbtn text-main hover:bg-main hover:text-white" onClick={() => setMode('none')}>
                    Назад
                </BaseButton>
              }
          </>}

        {type === 'telegram' &&
            <p>Вы будете перенаправлены на страницу Telegram для авторизации, пожалуйста следуйте инструкциям</p>
            
        }

        {type === 'vk' &&
          (errorsMessage ? <p className={""}><span className={"text-main"}>Ошибка!</span> {errorsMessage}</p>
          : <div className={"flex flex-col gap-y-4"}>
              <p>Вы будете перенаправлены на страницу ВК для авторизации, пожалуйста следуйте инструкциям</p>
              <div className={"w-full text-center flex flex-col items-center justify-center text-gray-40"}>
                <p className={""}>
                  Если у вас автоматически не открылась страница ВК, то
                </p>
                <div onClick={() => openInNewTab(linkExternal)} className={"text-main border-b-[1.5px] w-fit text-center border-b-main cursor-pointer"}>нажмите сюда </div>
              </div>
            </div>)
        }

        <hr className={"text-gray-30"}/>

        <div className={"text-center xs:text-sm text-xs"}>
          <span className={"text-gray-40"}>Продолжая, вы соглашаетесь <span className={"text-main"}>со сбором и обработкой персональных данных и пользовательским соглашением</span></span>
        </div>
      </div>
    </>
  )
}
//------------------------------------------------CodeInput--------------
function AuthPopupCodeInput({
phone,
setCode,
setMode,
setType,
startingType,
statusCode,
code,
counterTimer,
requestCode,
lastSendType,
codeFailMessage,
isLoadingButton,
title,
isPhoneComplete,
linkExternal,
}:any) {

  return (
    <>
      <p className={"text-xl font-medium mb-2"}>{title}</p>
      <div className={"flex flex-col gap-y-4 mt-5"}>

        {lastSendType === 'sms' && <span className={"text-gray-40"}>Код отправили сообщением на <span
            className={"text-black font-medium"}>{phone}</span>
            <span className={"mx-1"}>(
                 <span className={"text-main underline cursor-pointer"} onClick={() => {
                   setCode('')
                   setMode('phoneNumber')
                 }}>изменить</span>)
            </span>
        </span>}

        {lastSendType === 'call' && <span className={"text-gray-40"}>На ваш номер <span
            className={"text-black font-medium"}>{phone}</span>
            <span className={"mx-1"}>(
                 <span className={"text-main underline cursor-pointer"} onClick={() => {
                   setCode('')
                   setMode('phoneNumber')
                 }}>изменить</span>)
            </span> будет совершен звонок. Для входа
        введите 4 последние цифры этого номера.
        </span>}

        {lastSendType === 'telegram' && <span className={"text-gray-40"}>Введите код подтверждения из чат-бота</span>}


        <TextInputCode statusCode={statusCode} value={code} onChange={setCode} failText={codeFailMessage}/>

        {(lastSendType === 'call' || lastSendType === 'sms') && <>
          <ButtonWithTimer
            onClick={() => {
              isPhoneComplete && requestCode('phone')
            }}
            preloader={isLoadingButton}
            counterTimer={counterTimer}
            text={'Получить код'}
            timerPrefix={counterTimer > 120 ? 'Слишком много попыток, новый код через' : 'Получите новый код через '}
            className={counterTimer > 0 ? `bg-orderbtn text-main w-full hover:bg-main hover:text-white` : `bg-main text-white w-full`}
          />

          {lastSendType === 'call' && <div className={"w-full text-center text-gray-40"}><p className={"text-black font-medium"}>Не поступил
            звонок?</p>Проверьте правильность номера телефона.
          </div>}
          {lastSendType === 'sms' && <div className={"w-full text-center text-gray-40"}><p className={"text-black font-medium"}>Не поступило
              смс?</p>Проверьте правильность номера телефона.
          </div>}
        </>}

        {lastSendType === 'telegram' && 
          <div className={"w-full text-center text-gray-40"}>
            <p className={""}>
              Если у вас автоматически не открылся telegram-бот, то<br/>
              <Link className={"text-main border-b-[1.5px] border-b-main  cursor-pointer"} to={linkExternal} target={"_blank"}>нажмите сюда</Link>
            </p>
          </div>
        }

        {lastSendType === 'telegram' && <>
            <BaseButton
                onClick={() => requestCode('telegram')}
                className={`bg-main text-white w-full hover:opacity-80`}>
                Получить код
            </BaseButton>
            <BaseButton className="bg-orderbtn text-main hover:bg-main hover:text-white" onClick={() => {
              if (startingType === 'phone') {
                setMode('phoneNumber')
                setType('phone')
              } else {
                setMode('none')
              }
            }}>
                    Назад
                </BaseButton>
        </>}

        <hr className={"text-gray-30"}/>
        <div className={"text-center xs:text-sm text-xs"}>
          <span className={"text-gray-40"}>Продолжая, вы соглашаетесь <span className={"text-main"}>со сбором и обработкой персональных данных и пользовательским соглашением</span></span>
        </div>
      </div>
    </>
  )
}
