import React, {useEffect, useRef, useState} from "react";
import InputSelector from "./InputSelector";
import SearchIcon from "../../../icons/SearchIcon";
import { TextInput } from "./TextInput";
import { apiClient } from "../../../../libs/api/apiClient";
import { formatAddress } from "../../../../libs/helpers/formatAddress";

export function InputSelectorDadata({
  onChange,
  makeVariants,
  sendRequest,
  allowEnterWithoutSelection=true,
  arrowSelect = true,
  renderClosed,
  defaultQuery = '',
  minRequestDelay = 1000,
  activeIcon = '',
  requestDeps = [],
  placeholderInput = "Введите адрес...",
  icon,
  query,
  setQuery,
  ...props
}: any) {
  const [query0, setQuery0] = useState(defaultQuery)
  if (!setQuery) {
    query = query0;
    setQuery = setQuery0;
  }
  useEffect(() => setQuery(defaultQuery), [defaultQuery])

  let defaultVariants = makeVariants([]) || []
  const [variants, setVariants] = useState<{name: string, id: number}[]>(defaultVariants)
  useEffect(() => setVariants(defaultVariants), [JSON.stringify(defaultVariants)])

  const requestsRef = useRef<any>({
    lastSentTime: 0,
    lastInProgress: false,
    lastController: null,
  }).current;
  requestsRef.minRequestDelay = minRequestDelay;
  requestsRef.makeVariants = makeVariants;
  requestsRef.setVariants = setVariants;
  requestsRef.sendRequest = sendRequest;
  requestsRef.query = query;
  useEffect(() => {
    const send = () => {
      // console.log(`[${new Date().getSeconds()}] sending request '${requestsRef.query}', time since last request: ${Date.now() - requestsRef.lastSentTime}ms`)
      let controller = new AbortController();
      requestsRef.lastController = controller;
      requestsRef.lastSentTime = Date.now();
      requestsRef.lastInProgress = true;
      requestsRef.sendRequest(requestsRef.query, {signal: controller.signal}).then((data: any[]) => {
        requestsRef.lastInProgress = false;
        if (data) {
          let vs = requestsRef.makeVariants(data);
          if (vs) {
            requestsRef.setVariants(vs)
          }
        }
      }).catch((e: any) => {
        requestsRef.lastInProgress = false;
        throw e;
      })
    }

    setTimeout(() => {
      if (requestsRef.lastSentTime + requestsRef.minRequestDelay - 10 > Date.now())
        return;
      const exec = () => {
        if (!requestsRef.lastInProgress) {
          send()
        } else {
          setTimeout(exec, 50);
        }
      }
      exec();
    }, Math.max(0, requestsRef.lastSentTime + requestsRef.minRequestDelay - Date.now()))
  }, [query, requestsRef, ...requestDeps])

  const arrowSelectIdxRef = useRef(-1);
  return <InputSelector
    noFilter={true}
    variants={variants}
    arrowSelect={arrowSelect}
    arrowSelectIdxRef={arrowSelectIdxRef}
    onChange={(id: any) => {
      let variant = variants.find(o => o.id === id)
      if (variant) {
        onChange(variant)
        setQuery(variant.name || '')
      }
    }}
    icon={icon || activeIcon}
    customComponent={(curVal: any, f: any) => {
      const {show, setShow} = f;
      if (!show)
        return renderClosed && renderClosed(curVal, f);
      return <div
        className={'h-9 flex items-center justify-between'}>
        {activeIcon === 'iconSearch' && <SearchIcon className={"mx-3"} color={"#848484"}/>}
        <TextInput
          className={`w-full !bg-transparent ${activeIcon !== '' ? '!px-[0px]' : ''}`}
          classNameInput={'dark:placeholder:text-gray-40'}
          autoFocus={true}
          value={query}
          onChange={setQuery}
          placeholderInput={placeholderInput}
          onEnterPressed={(e: any) => {
            if (allowEnterWithoutSelection && arrowSelectIdxRef.current === -1) {
              sendRequest(query, {}).then((data: any[]) => {
                let vs = makeVariants(data);
                if (vs.length > 0) {
                  onChange(vs[0])
                  setShow(false);
                  setQuery(vs[0].name)
                }
              })
              e.preventDefault();
              return true;
            }
          }}
          onKeyDown={(e: any) => {
            if (arrowSelect && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) e.preventDefault();
          }}
        />
      </div>
    }}
    {...props}/>;
}

export function InputSelectorAddressSearch({value, onChange, guids = [], ...props}: any) {
  return <InputSelectorDadata
    sendRequest={(query: string, options: any) => {
      if (query.trim() === '') return new Promise<any[]>(resolve => resolve([]))
      return apiClient.suggestions.getAddressSuggestions(query, guids, options)
    }}
    makeVariants={ (data: any[]) => data.map((o: any, i: number) => ({id: i, name: formatAddress(o, [])})) }
    valueOverride={value}
    defaultQuery={value}
    onChange = {(v: any) => {
      onChange(v.name);
    }}
    {...props}
  />
}

export function InputSelectorAddressSearchWithSaved({value, onChange, allUserAddresses, guids = [], suggestionsGuids = [], filterUserAddresses = true, requestDeps = [], without = ['city'], ...props}: any) {
  return <InputSelectorDadata
    requestDeps={[allUserAddresses, guids.join(","), ...requestDeps]}
    sendRequest={(query: string, options: any) => {
      if (query.trim() === '' || formatAddress(allUserAddresses[0]?.address, without) === query) {
        return new Promise((resolve) => {
          resolve(allUserAddresses
            .filter((o : any) => !filterUserAddresses || guids.length === 0 || guids.includes(o.city?.guid))
            .map((o: any) => ({...o, ...o.address, addressId: o.id})))
        })
      }
      return apiClient.suggestions.getAddressSuggestions(query, suggestionsGuids.length > 0 ? suggestionsGuids : guids, options)
    }}
    makeVariants={(data: any[]) => {
      return data.map(({addressId, ...o}: any, i: number) => ({id: i, addressId, name: formatAddress(o, []), addr: o}))
    }}
    valueOverride={value?.name}
    defaultQuery={value?.name}
    onChange = {(v: any) => {
      onChange({...v, name: formatAddress(v.addr, without)});
    }}
    {...props}
  />
}

export function InputSelectorPointSearch({variants, value, onChange, guids = [], ...props}: any) {
  return <InputSelectorDadata
    sendRequest={(query: string, options: any) => {
      if (query.trim() === '') return new Promise<any[]>(resolve => resolve([]))
      return apiClient.suggestions.getAddressSuggestions(query, guids, options)
    }}
    makeVariants={ (data: any[]) => {
      if (data && data[0]) {
        let {lat: lat0, lon: lon0} = data[0];
        const distSq = (lat: any, lon: any) => {
          const dx = lon0 - parseFloat(lon);
          const dy = lat0 - parseFloat(lat);
          return dx * dx + dy * dy;
        }
        let sorted = variants.map((o: any) => ({...o, dis: distSq(o.lat, o.lon)}))
        sorted.sort((a: any, b: any) => a.dis - b.dis);
        return sorted
      }
      return variants
    }}
    value={value}
    onChange = {(v: any) => {
      onChange(v?.id, v);
    }}
    {...props}
  />
}

export function InputSelectorCitySelectSearch({cities, value, onChange, minCitiesToSearch=4, ...props}: any) {
  const findCity = (substr: any) => {
    return cities.filter((c: any) => {
      return c.title.toLowerCase().includes(substr);
    })
  }

  if (cities.length < minCitiesToSearch) {
    return <InputSelector
      variants={cities.map((o: any) => ({name: o.title, id: o.slug}))}
      value={value}
      onChange={onChange}
      {...props}
    />
  }

  return <InputSelectorDadata
    sendRequest={(query: string, options: any) => {
      let cs = cities;
      query = query.trim();
      if (query && query.length > 0) {
        cs = findCity(query.toLowerCase());
      }
      return Promise.resolve(cs.map((o: any) => ({name: o.title, id: o.slug})))
    }}
    makeVariants={(o: any) => o}
    value={value}
    onChange = {(v: any) => {
      onChange(v?.id);
    }}
    valueOverride={cities.find((c: any) => c.slug === value)?.title}
    minRequestDelay={50}
    {...props}
  />
}
