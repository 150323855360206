import React from 'react'
import { useAppContext } from '../../contexts/App';

export default function IIcon({className='w-[19px] h-[19px]', color='text-[#4F4F4F]', isCartTooltipIcon = false}: {
  className?: string;
  color?: string;
  isCartTooltipIcon?: boolean
}) {
  const {company} = useAppContext()

  return (
  <svg className={`${className} ${color}`} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.5" cy="9.5" r="8.75" fill={isCartTooltipIcon? "currentColor" : "transparent"} stroke="currentColor" strokeWidth="1.5"/>
    <path d="M10.2023 5.40781C10.2023 5.62008 10.0302 5.79216 9.81795 5.79216C9.60566 5.79216 9.43359 5.62008 9.43359 5.40781C9.43359 5.19552 9.60567 5.02344 9.81795 5.02344C10.0302 5.02344 10.2023 5.19552 10.2023 5.40781Z" fill={isCartTooltipIcon? company.template.options.mainColor : 'currentColor'} stroke={isCartTooltipIcon? company.template.options.mainColor : 'currentColor'} strokeWidth="1.5"/>
    <path d="M9.81566 13.0704C9.52311 13.0704 9.36328 12.8622 9.36328 12.686V8.06014C9.36328 7.86009 9.54759 7.67578 9.74764 7.67578H9.81566C10.0157 7.67578 10.2 7.86009 10.2 8.06014V12.686C10.2 12.9119 10.0586 13.0704 9.81566 13.0704Z" fill={isCartTooltipIcon? company.template.options.mainColor : 'currentColor'} stroke={isCartTooltipIcon? company.template.options.mainColor : 'currentColor'} strokeWidth="1.5"/>
  </svg>
  )
}
