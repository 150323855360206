import SearchIcon from "../../../icons/SearchIcon"; 
import React, {useEffect, useState} from "react";
import Popup from '../index';
import { useAppContext } from './../../../../contexts/App';
import useSelectCity from './../../../../libs/helpers/selectCity';
import { Preloader } from "../../preloader";
import { TextInput } from "../../form/input/TextInput";

interface ICitiesSelector {
  isActive: boolean,
  setActive: (v: boolean) => void,
  movePopup?: boolean,
}
export default function CitiesSelector({isActive, setActive, movePopup}: ICitiesSelector) {
  const { company } = useAppContext();

  const [str, setSearchStr] = useState<string>('')
  const [isLoading, setLoading] = useState(false)

  const selectCity = useSelectCity();

  const findCity = (substr: any) => {
    return company.cities?.filter((c: any) => {
      return c.title.toLowerCase().includes(substr);
    })
  }

  if (company.cities.length === 0) return (
    <Popup
      isActive={isActive}
      close={() => setActive(false)}
      width={'470px'}
    >
      <div className="text-center text-2xl leading-7 font-medium">
        Для работы приложения нужно привязать эту группу хоты бы к одному филиалу
      </div>
    </Popup>)

  return (
    <Popup
      isActive={isActive}
      close={()=>setActive(false)}
      width={'470px'}
      movePopup={movePopup}
    >
      {isLoading && <div className={'w-full h-full absolute top-0 left-0 rounded-3xl flex justify-center items-center'}>
        <div className={'absolute top-0 left-0 rounded-[28px] bg-gray-20 dark:bg-gray-40 opacity-50 w-full h-full '}></div>
        <Preloader countOfDot={4}/>
      </div>}


      <div className="mb-[20px] text-2xl leading-7 font-bold dark:text-white">Выберите город</div>
      {company.cities.length > 4 &&
        <div className={'h-9 flex items-center justify-between bg-gray-20 dark:!bg-gray-40 rounded-[10px]'}>
            <SearchIcon className={"mx-3 "} color={"#BDBDBD"}/>
            <TextInput
                onChange={(str) => {
                  setSearchStr(str.trim().toLowerCase())
                }}
                className={'w-full !px-[0px] dark:!bg-gray-40'}
                classNameInput={'dark:!text-gray-20 dark:placeholder:text-gray-30 placeholder:text-gray-30'}
                placeholderInput='Поиск...'
                value={str}
            />
        </div>
      }

      <div style={ company.cities.length > 8 ? { gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'} : {}} className={`grid gap-2 mt-3`}>
        {
          findCity(str).map((city: any) =>  {
              return <div onClick={() => {
                setLoading(true)
                selectCity(city.slug).then(()=> {
                  setActive(false)
                  setLoading(false)
                })}} key={city.slug} className="cursor-pointer font-medium text-lg text-gray-50 dark:text-light hover:text-main hover:dark:text-main duration-300">{city.title}</div>
            }
          )
        }
        { findCity(str).length === 0 && <p className={"text-gray-40 dark:text-light"}>Пока что нас нет в этом городе</p>}
      </div>
    </Popup>
  )
}
