import { Outlet } from "react-router-dom";
import { AppContextWrapper } from './contexts/App';

export default function AppLayout() {
  return (
    <>
      <AppContextWrapper>
        <Outlet/>
      </AppContextWrapper>
    </>
  )
}
