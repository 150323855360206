import { apiClient } from "../api/apiClient";
import {cookieWrap} from "../helpers/cookieWrap";

export default async function getBranch(domain: string, city: any) {
  let branchId: any
  branchId = cookieWrap.getCookie('branchId');

  // проверка наличие филиала в городе
  if (branchId && city?.branches.map((branch: any) => branch.id).includes(+branchId)) {
    // Запрос филиала по branchId
    let branch = await apiClient.branch.get(branchId, domain).then(({data, message}: any) => {
      return data
    })

    return { branch, autoSelectBranch: false }
  } else {
    // Запрос первого филиала
    let branch = await apiClient.branch.get(city.branches[0].id, domain).then(({data, message}: any) => {
      return data
    })

    cookieWrap.setCookie('branchId', branch.id);
    return { branch, autoSelectBranch: true }
  }
}
