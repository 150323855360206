export function formatAddress(point: any, without: string[]) {
  if (!point) return ''
  let addr: string[] = [];

  const hasHouse = (point?.house && !without.includes('house')) && ('null' !== `${point?.house}`);
  const hasCity = (point?.city && !without.includes('city'));
  const hasStreet = (point?.streetWithType && !without.includes('street')) && ('null' !== `${point?.streetWithType}`);
  const hasBlock = (point?.block && !without.includes('block') && 'null' !== `${point?.block}`)

  hasCity && addr.push(`${point.cityWithType}` + (hasStreet ? ',' : ''));
  hasStreet && addr.push(`${point.streetWithType}` + (hasHouse ? ',' : ''));
  hasHouse && addr.push(`д ${point.house}`);
  hasBlock && addr.push(`/${point?.block}`);
  return addr.join(' ');
}

