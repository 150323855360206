import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { useAppContext } from '../../../../contexts/App';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useAddressContext } from '../../../../contexts/AddressContext';
import { useCartContext } from '../../../../contexts/CartContext';
import { formatAddress } from '../../../../libs/helpers/formatAddress';
import { localStorageWrap } from '../../../../libs/helpers/localStorageWrap';
import { TextInput } from '../../../common/form/input/TextInput';
import { Preloader } from '../../../common/preloader';
import PickupPointsSelector from '../../../common/form/PickupPointsSelector';
import { InputSelectorAddressSearchWithSaved } from '../../../common/form/input/InputSelectorDadata';
import DateInput from '../../../common/form/DateInput';
import InputSelector from '../../../common/form/input/InputSelector';
import Textarea from '../../../common/form/textarea';
import pluralize from '../../../../libs/helpers/pluralize';
import { ChangeBranchPopup } from '../../../common/popup/citites/Modals';
import Popup from '../../../common/popup';
import { BaseButton } from '../../../common/button/BaseButton';
import { useNavigate } from 'react-router-dom';


export default function Form({ data, updateField, errors, updateErrors }: any) {
  const { branch, city, company, address, isOpen } = useAppContext()
  const { user } = useAuthContext()
  const {trySaveDeliveryPoint, trySavePickupPoint, allUserAddresses, addressRef, addressError, setAddressError} = useAddressContext()
  const { cart } = useCartContext()
  const navigate = useNavigate();

  const [pickupPoint, setPickupPoint] = useState<any>(null)
  const [deliveryAddress, setDeliveryAddress] = useState<any>(address?.type === 'delivery' ? {name: formatAddress(address.point, []), addr: address.point} :{name: ''})//query address

  const [showChangePhonePopup, setShowChangePhonePopup] = useState(false)

  const [isLoadingButton, setLoadingButton] = useState('none')

  useEffect(() => {
    if (address?.type === 'pickup') {
      const point = address.point
      console.log("updating pickup point", point)
      if (point)
      {
        updateField('deliveryType', 'pickup')
        updateField('pickupPointId', point.id)
        setPickupPoint({...point, title: formatAddress(point, []) || ''})
        setAddressError(null)
      }
    } else if (address?.type === 'delivery') {
      const point = address.point
      console.log("updating delivery address", point)
      if (point) {
        updateField('entrance', point.entrance || '')
        updateField('floor', point.floor || '')
        updateField('flat', point.flat || '')
        updateField('deliveryAddress', point)
        updateField('deliveryZoneId', point.deliveryZoneId)
        updateField('branchId', point.branchId)
      } else {
        updateField('deliveryAddress', null)
        updateField('branchId', null)
        updateField('deliveryZoneId', null)
      }
      updateField('deliveryType', 'courier')
      setAddressError(null)
      setDeliveryAddress({name: formatAddress(address.point, []) || '', addr: address.point})
    }
  }, [address])

  useEffect(() => {
    updateField('deliveryCity', city.guid)
  }, [city?.slug])


  useEffect(() => {
    updateField('clientName', (user?.name) || '')
    updateField('clientEmail', (user?.email) || '')
    updateField('clientPhone', (user?.phone?.length) ? user?.phone || '' : localStorageWrap.getItem('clientPhone') || '')
  }, [user])

  const allPickupPoints: any[] = []
  city.branches.forEach((branch: any) => {
    branch.pickup_points?.forEach((pickupPoint: any) => {
      allPickupPoints.push({city: city.slug, branchId: branch.id, ...pickupPoint})
    });
  });

  useEffect(()=> {
    if (allPickupPoints.length === 0) {
      updateField('deliveryType', 'courier')
    }
  }, [allPickupPoints.length, data.deliveryType])


  const [variantsTimePreorder, setVariantsTimePreorder] = useState([])
  const [minDate, setMinDate] = useState(new Date())

  function getVariantsTimePreorder(date: any) {
    let now = new Date()

    if (date.getTime() == new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()) {
      return branch.order.preorderAllowedTimes
        .filter(({time}: any) => moment(time, 'hh:mm') > moment())
        .map(({time, title}: any) => ({name: title, id: time}))
    }
    
    return branch.order.preorderAllowedTimes.map(({time, title}: any) => ({name: title, id: time}))
  }

  useEffect(() => {
    let now = new Date()
    let day = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    let variants = getVariantsTimePreorder(day)

    if (!variants.length) {
      day.setDate(day.getDate() + 1);
      updateField('deliveryDate', new Date(day))
      variants = getVariantsTimePreorder(day)
    } else {
      updateField('deliveryDate', new Date(day))
    }
    
    updateField('deliveryTime', variants?.at(0)?.id || '00:00')
    setMinDate(day)
  }, [])

  useEffect(() => {
    if (data.deliveryDate) {
      let variants = getVariantsTimePreorder(data.deliveryDate)
      setVariantsTimePreorder(variants)

      if (moment(data.deliveryTime, 'hh:mm') < moment()) {
        updateField('deliveryTime', variants?.at(0)?.id || '00:00')
      }

      let time
      if (data?.deliveryTime) {
        time = moment(data.deliveryTime, 'hh:mm') 
      } else {
        time = moment(variants?.at(0)?.id || '00:00', 'hh:mm') 
      }

      let preorderAt = new Date(data.deliveryDate)
      
      preorderAt.setHours(preorderAt.getHours() + time.hour());
      preorderAt.setMinutes(preorderAt.getMinutes() + time.minute());

      updateField('preorderAt', preorderAt.toISOString())
    }
  }, [data?.deliveryDate, branch?.id])

  useEffect(() => {
    if (data?.deliveryTime) {
      let time = moment(data.deliveryTime, 'hh:mm') 
      let preorderAt = new Date(data.deliveryDate)
      
      preorderAt.setHours(preorderAt.getHours() + time.hour());
      preorderAt.setMinutes(preorderAt.getMinutes() + time.minute());

      updateField('preorderAt', preorderAt.toISOString())
    }
  }, [data?.deliveryTime])

  const [paymentTypes, setPaymentTypes] = useState(branch.order.paymentTypes)

  useEffect(() => {
    if (cart.options.paymentTypes.length) {
      // Берём доступные способы оплаты, но фильтруем по полю cart.options.paymentTypes т.к. тип оплаты может быть отключен воронкой
      setPaymentTypes(branch.order.paymentTypes.filter((type: {label: string, value: string}) => cart.options.paymentTypes.includes(type.value)).map((type: {label: string, value: string}) => ({name: type.label, id: type.value})))
    } else {
      setPaymentTypes(branch.order.paymentTypes.map((type: {label: string, value: string}) => ({name: type.label, id: type.value})))
    }
  }, [cart, branch])

  return (
    <div className='flex flex-col gap-5'>
      <div>
        <TextInput
          name={'clientName'}
          value={data.clientName || ''}
          onChange={(value) => updateField('clientName', value)}
          placeholderInput={"Имя"}
          errors={errors}
        />
      </div>


      <div>

        <TextInput
          name={'clientPhone'}
          value={data.clientPhone || ''}
          onChange={(value) => updateField('clientPhone', value)}
          errors={errors}
          disabled={!!user?.phone || !!localStorageWrap.getItem('clientPhone')}
          getAuxElement={() => {
            if (!!user?.phone || !!localStorageWrap.getItem('clientPhone')) {
              return <span onClick={() => {
                setShowChangePhonePopup(true);
              }}>{'Изменить'}</span>
            }
            return <></>
          }}
        />
      </div>
      
      {
        branch.order.fields?.clientEmail.isUsed && 
        <TextInput
          name={'clientEmail'}
          value={data.clientEmail || ''}
          onChange={(value) => {updateField('clientEmail', value)}}
          placeholderInput={`Email${branch.order.fields?.clientEmail.isRequired ? '*' : ''}`}
          errors={errors}
        />
      }

      {
        branch.order.fields?.deliveryType?.isUsed && city.branches.reduce((accumulator: boolean, currentValue: any) => accumulator || (currentValue.has_delivery_zones && !currentValue.pickup_only), false) ?
        <div className='flex gap-[30px]'>
          <div 
            className={`flex items-center justify-center h-[38px] w-full rounded-[18px] text-sm font-bold cursor-pointer duration-500 
            ${data.deliveryType === 'courier' ? 'bg-main text-white' : 'bg-orderbtn text-main hover:bg-main hover:text-white'}
            ${isLoadingButton === 'pickup' ? 'opacity-50 !cursor-default' : ''}
            `}
            onClick={() => {
              if (isLoadingButton === 'pickup') return
              if (data.deliveryType === 'courier') return
              if (deliveryAddress.addr) {
                setLoadingButton('courier')
                trySaveDeliveryPoint(city, deliveryAddress.addr).then((success) => {
                  if (success) {
                    updateField('deliveryType', 'courier')
                  }
                  setLoadingButton('none')
                })
              } else {
                updateField('deliveryType', 'courier')
              }

            }}
          >{isLoadingButton === 'courier'? <div> <Preloader size={'10px'} color={'white'}/></div>: <p>Доставка</p>}</div>

          <div
            className={`flex items-center justify-center h-[38px] w-full rounded-[18px] text-sm font-bold cursor-pointer duration-500 
            ${data.deliveryType === 'pickup' ? 'bg-main text-white' : 'bg-orderbtn text-main hover:bg-main hover:text-white'} 
            ${allPickupPoints.length === 0 || isLoadingButton === 'courier' ? 'opacity-50 !cursor-default' : ''}`}
            onClick={() => {
              if (isLoadingButton === 'courier') return
              if (data.deliveryType === 'pickup') return
              if (allPickupPoints.length !== 0) {
                if (pickupPoint) {
                  setLoadingButton('pickup')
                  trySavePickupPoint(city, pickupPoint).then((success) => {
                    if (success) {
                      updateField('deliveryType', 'pickup')
                    }
                    setLoadingButton('none')
                  })
                } else {
                  updateField('deliveryType', 'pickup')
                }
              }
            }}
          >{isLoadingButton === 'pickup'? <div> <Preloader size={'10px'} color={'white'}/></div>: <p>Самовывоз</p>}</div>
        </div> :
        <div className='text-main font-bold'>{ data.deliveryType === 'courier' ? 'Адрес доставки' : 'Адрес самовывоза' }</div>
      }
      
      {
        data.deliveryType === 'pickup' ? 
        <div>
          <PickupPointsSelector
            value={pickupPoint}
            onChange={(point: any) => {
              if (point) {
                setLoadingButton('pickup')
                trySavePickupPoint(city, point).then(() => {
                  setLoadingButton('none')
                })
              }
            }}
            filterByCity={true}
            reverseEllipsis={true}
            city={city}
          />
        </div> : 
        <>
          <InputSelectorAddressSearchWithSaved
            value={deliveryAddress}
            placeholder={"Добавить новый адрес"}
            onChange={(point: any) => {
              if (point?.addr) {
                setLoadingButton('courier')
                trySaveDeliveryPoint(city, point.addr).then(() => {
                  setLoadingButton('none')
                })
              }
            }}
            without={[]}
            guids={[]}
            suggestionsGuids={city ? [city.guid] : []}
            allUserAddresses={allUserAddresses}
            reverseEllipsis={true}
          />
          
          <div className="flex gap-5">
            {
              branch.order.fields?.entrance.isUsed &&
              <TextInput
                className='w-full'
                type='number'
                name={'entrance'}
                value={data.entrance || ''}
                onChange={(value) => updateField('entrance', (+value > 0 || !value) ? value : +value * -1)}
                placeholderInput={`Подъезд${branch.order.fields?.entrance.isRequired ? '*' : ''}`}
                errors={errors}
                errorLabel={false}
                min='1'
              />
            }
            {
              branch.order.fields?.floor.isUsed &&
              <TextInput
                className='w-full'
                type='number'
                name={'floor'}
                value={data.floor || ''}
                onChange={(value) => updateField('floor', (+value > 0 || !value) ? value : +value * -1)}
                placeholderInput={`Этаж${branch.order.fields?.floor.isRequired ? '*' : ''}`}
                errors={errors}
                errorLabel={false}
                min='1'
              />
            }
            {
              branch.order.fields?.flat.isUsed &&
              <TextInput
                className='w-full'
                type='number'
                name={'flat'}
                value={data.flat || ''}
                onChange={(value) => updateField('flat', (+value > 0 || !value) ? value : +value * -1)}
                placeholderInput={`Квартира${branch.order.fields?.flat.isRequired ? '*' : ''}`}
                errors={errors}
                errorLabel={false}
                min='1'
              />
            }
          </div>
        </>
      }

      {
        addressError?.message &&
        <div className='text-yellow text-xs'>{addressError?.message}</div>
      }
      {
        (!isLoadingButton && data.deliveryType === 'courier' && data.deliveryZoneId == null && data.deliveryAddress && company.isCustomerAddressMustBeInDeliveryZone) &&
        <div className='text-yellow text-xs'>Выбранный адрес не входит в зону доставки</div>
      }
      {
        (!isLoadingButton && data.deliveryType === 'courier' && data.branchId != null && data.branchId !== branch.id && company.isCustomerAddressMustBeInDeliveryZone) &&
        <div className='text-yellow text-xs'>Выбранный адрес входит в зону доставки другого филиала</div>
      }
      {
        errors?.deliveryType && 
        <div className='text-yellow text-xs'>{ errors?.deliveryType.at(0) }</div>
      }

      {
        !!branch.order.fields.deliveryDate.isUsed && 
        <>
          <div className='h-[40px] w-full rounded-[10px] bg-orderbtn p-[3px]'>
            <div className='relative h-full w-full flex '>
              <div className={`absolute h-full w-1/2 rounded-[10px] bg-main duration-500 ${data.preorder ? 'translate-x-full' : 'translate-x-0'}`} />
              <div 
                onClick={() => {
                  if (isOpen) {
                    updateField('preorder', false);
                    updateErrors('')
                  }
                }} className={`w-1/2 flex items-center justify-center cursor-pointer z-[5] text-sm font-bold duration-500 ${!isOpen && 'opacity-50 !cursor-default'} ${ data.preorder ? 'text-main' : 'text-white' }`}>Ближайшее время</div>
              <div onClick={() => {
                updateField('preorder', true)
                updateErrors('')
              }} className={`w-1/2 flex items-center justify-center cursor-pointer z-[5] text-sm font-bold duration-500 ${ !data.preorder ? 'text-main' : 'text-white' }`}>Ко времени</div>
            </div>
          </div>

          {
            data.preorder &&
            <div className='flex gap-[30px]'>
              <div className={'w-full'}>
                <DateInput
                  value={data.deliveryDate}
                  onChange={(date: any) => updateField('deliveryDate', date)}
                  minDate={minDate}
                  maxDate={moment().add(1, 'months').toDate()}
                  className={''}
                />
              </div>
              
              <InputSelector
                variants={variantsTimePreorder}
                value={data.deliveryTime}
                onChange={(date: any) => updateField('deliveryTime', date)}      
              />
            </div>
          }

          {
            errors?.preorderAt && 
            <div className='text-yellow text-xs'>{ errors?.preorderAt.at(0) }</div>
          }
        </>
      }

      {
        branch.order.fields?.comment.isUsed &&
        <Textarea
          maxLength={1000}
          name='comment'
          value={data.comment}
          onChange={(value) => updateField('comment', value)}
          placeholder={`Комментарии к заказу${branch.order.fields?.comment.isRequired ? '*' : ''}`}
          errors={errors}
          textareaClassName={'placeholder-gray-30'}
        />
      }
      
      <div className='flex gap-4'>
        {
          branch.order.fields?.personsCount.isUsed &&
          <InputSelector
            className='w-full'
            placeholder={`Кол-во персон (приборы)${branch.order.fields?.personsCount.isRequired ? '*' : ''}`}
            // name='personsCount'
            variants={Array.from({length: 20}, (_, index) => index + 1).map((value: number) => ({name: `${value} ${pluralize(value, ['персона', 'персоны', 'персон'])}`, id: value}))}
            value={data.personsCount}
            onChange={(value) => {updateField('personsCount', value)}}  
            noFilter={true}
          />
        }
      </div> 
      
      {
        paymentTypes.length > 1 ?
        <div className='mt-9'>
          <div className='mb-3 text-[22px] font-bold -tracking-[.01em]'>Способы оплаты</div>

          <InputSelector
            variants={paymentTypes}
            value={data.paymentType}
            onChange={(value: any) => {updateField('paymentType', value)}}      
          />
        </div> : 
        <div>
          <div className='text-[22px] font-bold -tracking-[.01em]'>Способ оплаты <span className='text-main'> { paymentTypes.at(0).label } </span></div>
        </div>
      }

      {
        branch.order.fields?.changeFromBill.isUsed && data.paymentType === 'cash' &&
        <TextInput  
          name='changeFromBill'
          value={data.changeFromBill}
          onChange={(value) => updateField('changeFromBill', value)}
          placeholderInput={`Сдачи с купюры${branch.order.fields?.changeFromBill.isRequired ? '*' : ''}`}
          type='number'
          className='w-full'
          errors={errors}
        />
      }

      <ChangeBranchPopup/>

      <Popup
        isActive={showChangePhonePopup}
        close={() => setShowChangePhonePopup(false)}
        closeIcon
        classNamePopup="m-0 self-center" 
        width = "475px"
      >
        <div className='text-center'>Вы точно хотите изменить ваш телефон? Согласившись, процесс оформления заказа придется начать заново.</div>
        <div className='flex w-full gap-6 mt-6'>
          <BaseButton onClick={() => setShowChangePhonePopup(false)} className={'bg-orderbtn text-main w-full !font-bold'}>Нет</BaseButton>
          <BaseButton 
            onClick={() => {
              if (!user) {
                setShowChangePhonePopup(false)
                localStorageWrap.removeItem('clientPhone')
                navigate(`/${city.slug}`)
                return
              }

              if (user?.phone_confirmed) {
                setShowChangePhonePopup(false)
                navigate(`/${city.slug}/account/profile`)
              } else {
                setShowChangePhonePopup(false)
                localStorageWrap.removeItem('clientPhone')
                navigate(`/${city.slug}/account/profile`)
              }
            }} 
            className={'bg-main text-white w-full !font-bold'}
          >
            Да
          </BaseButton>
        </div>
      </Popup>
    </div>
  )
}
