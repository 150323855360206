import React, { useState, useEffect } from 'react'
import Price from '../Price';
import Check from './Check'
import Form from './Form';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import { useCartContext } from '../../../../contexts/CartContext';
import { useAppContext } from '../../../../contexts/App';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { NotificationBlock, sendEvent, useVisitorContext } from '../../../../contexts/VisitorContext';
import { AdditionalFields, useAdditionalFieldsFunnelsContext } from '../../../../contexts/AdditionalFieldsFunnelsContext';
import useForm from '../../../../hooks/useForm';
import { apiClient } from '../../../../libs/api/apiClient';
import { localStorageWrap } from '../../../../libs/helpers/localStorageWrap';
import { useGuardedCallback } from '../../../../hooks/useGuardedCallback';
import { Link, useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../../../libs/helpers/formatCurrency';
import ArrowIcon from '../../../icons/ArrowIcon';
import { Preloader } from '../../../common/preloader';



export default function OrderStep({ back, setCartErrors }: any) {
  const { cart, updateCart } = useCartContext()
  const { city, branch, address, company, isOpen } = useAppContext()
  const { updateMe, user, updateUser } = useAuthContext()
  const { actionsController } = useVisitorContext()
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(true)

  useEffect(() => {
    if (sessionStorage.getItem('visitorId')) {
      sendEvent('section-visited', {target: 'Оформление заказа'})
    }
  }, [typeof sessionStorage !== 'undefined' && sessionStorage.getItem('visitorId')])

  let {data, changedFields, changedFieldsRefresh, updateField, errors, updateErrors, submit} = useForm({
    preorder: !isOpen,
    deliveryType: address?.type === 'delivery' ? 'courier' : 'pickup',
    paymentType: branch.order.paymentTypes.at(0).value,
    personsCount: 1
  })

  const AdditionalFieldsContext = useAdditionalFieldsFunnelsContext()

  useEffect(() => {
    let handler = setTimeout(() => {
      if (Object.values(changedFields).length) {
        updateCart(changedFields)
        changedFieldsRefresh()
      }
    }, 1000)

    return () => clearTimeout(handler)
  }, [changedFields])

  const handleSubmit = () => {
    setLoaded(false)
    return submit(async (form: FormData) => {
      let body: any = {
        ...Object.fromEntries(form.entries()),
        cartId: cart.cartId,
      }
      if (body?.comment === '') {
        delete body['comment']
      }
      if (data.deliveryType === 'courier') {
        body['deliveryAmount'] = cart.options.freeDeliveryFromTotal && !cart.options.isFreeDeliveryDisabled && cart.total - (cart.options.deliveryAmount || 0) >= (cart.options.freeDeliveryFromTotal || 0) ? 0 : cart.options.deliveryAmount || 0
      }
      if (body?.personsCount === '') {
        delete body['personsCount']
      }

      await apiClient.order.create(branch.id, {body}).then(async (res: any) => {
        let { errors, status, data, message } = res

        setLoaded(true)

        if (status === 500 && message) {
          toast.error(`${message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: company.template.options.theme,
          });
          
          return
        }

        actionsController({order: data?.actions || []})

        if (status === 422) {
          // Проверяем пришла валидация формы или корзины 
          if (errors) {
            // Форма
            updateErrors(errors)
            AdditionalFieldsContext.updateErrors(errors)
            animateScroll.scrollToTop()
          } else {
            // Корзина
            setCartErrors(res?.products?.filter((p: any) => p?.errors?.length))
          }
          
          return
        } else if (status === 409) {
          back(message)
        } else {
          if(user && !user?.email && body?.clientEmail) {
            await apiClient.profileInfo.infoProfile({'email': body?.clientEmail})
              .then(({data, status}: any) => {
                if(status === 200){
                  updateUser(data)
                  return true;
                }
                return false;
              })
          }
          let cartData: any = {
            deliveryType: address?.type === 'delivery' ? 'courier' : 'pickup',
            preorder: !isOpen,
            paymentType: branch.order.paymentTypes.at(0).value,
            personsCount: 1
          }
    
          if (user?.phone || localStorageWrap.getItem('clientPhone')) {
            cartData['clientPhone'] = user?.phone || localStorageWrap.getItem('clientPhone')
          }
          
          if (data?.paymentLink) {
            document.location.href = data.paymentLink
          } else {
            navigate(`/${city.slug}/order/${data.hash}`)
          }
          await updateCart(cartData)
        }
      })
    })
  }

  const handleSubmitGuarded = useGuardedCallback(handleSubmit)

  const confirmEnabled = cart.count
    && ((((cart?.total || 0) >= (cart.options.deliveryFromTotal || 0) || !company.isCustomerAddressMustBeInDeliveryZone) && data.deliveryType === 'courier') || data.deliveryType === 'pickup')
    // allow courier delivery only for valid address in the current branch
    && (data.deliveryAddress && data.deliveryZoneId != null || data.deliveryType !== 'courier' || !company.isCustomerAddressMustBeInDeliveryZone)
    && (data.deliveryType === 'courier' && branch.order.useCourier || data.deliveryType === 'pickup' && branch.order.usePickup)
    && !cart.options.isMakingOrderDisabled

  return (
    <>
      <NotificationBlock place='order-top'/>

      <div className={`max-w-[1000px] w-full mx-auto px-[15px] md:px-[75px]`}>
        <div className='flex gap-[30px] mt-[34px]'>
          <div className="grow">
            <div className="text-[28px] -tracking-[.01em] font-bold mb-5">Заказ на {data.deliveryType == 'courier'? 'доставку': 'самовывоз'}</div>
            <AdditionalFields place='top-in-order'/>

            <Form data={data} updateField={updateField} errors={errors} updateErrors={updateErrors}/>

            <AdditionalFields place='bottom-in-order'/>
          </div>
      
          <Check
            deliveryAmount={cart.options.freeDeliveryFromTotal && !cart.options.isFreeDeliveryDisabled && cart.total - (cart.options.deliveryAmount || 0) >= (cart.options.freeDeliveryFromTotal || 0) ? 0 : cart.options.deliveryAmount || 0}
            deliveryShow={data.deliveryType === 'courier'}
          />
        </div>

        {
          data.deliveryType === 'courier' && cart.deliveryZoneId && 
          <>
            {
              // Если есть минимальная цена для доставки, и пользователь её ещё не набрал
              !!(cart.options.deliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) < cart.options.deliveryFromTotal) && 
              <div className={'mt-3 flex items-center gap-[6px] text-[20px] font-bold -tracking-[.01em] text-yellow'}>Минимальная сумма заказа для доставки {formatCurrency(branch.extended.currency.code, cart.options.deliveryFromTotal)}</div>
            }
            {
              // Если есть цена после, которой доставка бесплатная, то выводим сколько пользователю ещё осталось добрать, но не выводим если не набран минимум для доставки, если он есть конечно
              !!(cart.options.freeDeliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) < cart.options.freeDeliveryFromTotal && (cart.options.deliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) >= cart.options.deliveryFromTotal || !cart.options.deliveryFromTotal)) &&
              <div className={'mt-3 flex items-center gap-[6px] text-[20px] font-bold -tracking-[.01em] text-main'}>До бесплатной доставки ещё {formatCurrency(branch.extended.currency.code, cart.options.freeDeliveryFromTotal - cart.total + (cart.options.deliveryAmount || 0))}</div>
            }
          </>
        }

        <Price 
          allProductsPrice={cart.amount} 
          totalPrice={cart.total} 
          totalDiscount={cart.discounts.discountWithoutBonus} 
          deliveryAmount={cart.options.freeDeliveryFromTotal && !cart.options.isFreeDeliveryDisabled && cart.total - (cart.options.deliveryAmount || 0) >= (cart.options.freeDeliveryFromTotal || 0) ? 0 : cart.options.deliveryAmount || 0}
          deliveryShow={data.deliveryType === 'courier'}
          bonus={cart.discounts.bonusDiscount}
        />

        {
          branch.extended.orderPageText &&
          <div className="mt-[50px] -tracking-[.02em] text-gray-50 dark:text-gray-10 text-sm md:text-base">
            { branch.extended.orderPageText }
          </div>
        }

        {
          !!(cart.cartPromoCode.promoCode && cart.cartPromoCode.reason && !cart.cartPromoCode.activated) &&
          <div className='mt-5 text-yellow'>Промокод не активирован: { cart.cartPromoCode.reason }</div>
        }

        {
          !!cart.options.disabledMakingOrderComment &&
          <div className='text-lg font-bold text-yellow mt-5'>
            { cart.options.disabledMakingOrderComment }
          </div>
        }

        <div className='text-xs font-medium text-gray-40 mt-[55px]'>
          Отправляя заказ, вы даете согласие на <Link to={`/${city.slug}/personalDataPolicy`} className='text-main relative after:content-0 after:absolute after:w-full after:h-[1px] after:left-0 after:bg-main after:bottom-[-2px]'>обработку&nbsp;своих&nbsp;персональных&nbsp;данных </Link>,{' '}
          <Link to={`/${city.slug}/termsOfUse`} className='text-main ml-1 relative after:content-0 after:absolute after:w-full after:h-[1px] after:left-0 right-1 after:bg-main after:bottom-[-2px]'>соглашаетесь&nbsp;с&nbsp;пользовательским&nbsp;соглашением</Link>
        <span>и</span> <Link to={`/${city.slug}/offer`} className='text-main relative after:content-0 after:absolute after:w-full after:h-[1px] after:left-0 after:bg-main after:bottom-[-2px]'>пользовательским&nbsp;соглашением&nbsp;(офертой)</Link> 
        </div>
        
        <div className='flex flex-col md:flex-row xs:gap-y-[25px] gap-y-4 md:items-center justify-between mt-[50px] mb-[50px] md:mb-[100px]'>
          
          <div onClick={() => back()} className='w-full md:w-[240px] h-10 md:h-[45px] pl-[27px] flex items-center rounded-full bg-gray-30/30 cursor-pointer'>
            <ArrowIcon className='rotate-180 h-[9px] w-[6px]' colorClassName='fill-gray-50 dark:fill-gray-10' />
            <div className="grow ml-[-30px] text-center text-gray-50 dark:text-gray-10 text-lg font-bold hover:opacity-80">Назад в корзину</div>
          </div>


          <div
            onClick={handleSubmitGuarded}
            className={`${!confirmEnabled && 'opacity-50 pointer-events-none'} flex items-center justify-center  w-full md:max-w-[489px] h-10 md:h-[45px] bg-main text-white text-lg font-bold hover:opacity-80 rounded-full cursor-pointer`}
          >
            {
              loaded ?
              <span>Оформить заказ</span> :
              <Preloader color='white' countOfDot={3} size='10px'/>
            }
          </div>
        </div>
      </div>

      <NotificationBlock place='order-bottom'/>
    </>
  )
}
