import React, {useEffect} from "react";
import {YMaps} from "@pbe/react-yandex-maps";
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
import { useAppContext } from "../../contexts/App";
import { useDetectorBrowser } from "../../hooks/useDetectorBrowser";
import VisitorContextWrapper, { NotificationBlock } from "../../contexts/VisitorContext";
import { AddressContextWrapper } from "../../contexts/AddressContext";
import { AnimationCartProductsContextWrapper } from "../../contexts/AnimationCartContext";
import NotFound from "../../pages/404";
import { ToastContainer } from 'react-toastify';
import SearchContextWrapper from "../../contexts/SearchContext";
import { CategoriesHeaderContextWrapper } from "../../contexts/CategoriesHeaderContext";
import { AdditionalFieldsFunnelsContextWrapper } from "../../contexts/AdditionalFieldsFunnelsContext";
import { LoaderContextWrapper } from "../../contexts/LoaderContext";
import Loader from "../pages/Loader";
import { ProductDetailCardContextWrapper } from "../../contexts/ProductDetailCardContext";

type Props = {
  children: React.ReactNode,
  className?: string,
}

export default function PrimaryLayout({children, className=''}: Props) {

  const {vkLaunchParams, notFound} = useAppContext()
  const isMobileVk = !!vkLaunchParams?.vk_platform?.includes("mobile")
  const isIphone = useDetectorBrowser().includes('iphone')
  return (
    <VisitorContextWrapper>
      <AddressContextWrapper>
        <link rel="preconnect" href="https://api-maps.yandex.ru"/>
        <link rel="preconnect" href="https://vk.com"/>
        <YMaps
          preload={false}
          query={{ apikey: process.env.REACT_APP_YMAPS_API_KEY, load: "package.full" }}>
          <SearchContextWrapper>
            <CategoriesHeaderContextWrapper>
              <AnimationCartProductsContextWrapper>
                {
                  notFound ?
                  <NotFound/> :
                  <>
                    <AdditionalFieldsFunnelsContextWrapper>
                      <LoaderContextWrapper>
                        <ProductDetailCardContextWrapper>
                          <Loader />
                          <div className={`flex flex-col min-h-screen`}>
                            <Header />
                            {isMobileVk && isIphone ?<div className={'h-[20px]'}></div>: <></>}
                            <main className={`flex-grow flex flex-col pt-[63px] xs:pt-[60px] ${className}`}>
                              <NotificationBlock place='header'/>
                              {children}
                            </main>
                            <Footer />
                          </div>
                          <ToastContainer />
                        </ProductDetailCardContextWrapper>
                      </LoaderContextWrapper>
                    </AdditionalFieldsFunnelsContextWrapper>
                  </>
                }
              </AnimationCartProductsContextWrapper>
            </CategoriesHeaderContextWrapper>
          </SearchContextWrapper>
        </YMaps>
      </AddressContextWrapper>
    </VisitorContextWrapper>
  );
}


        