import React, {useEffect, useRef, useState, useLayoutEffect, MutableRefObject} from 'react'
import * as Scroll from 'react-scroll';
import { useAppContext } from '../../../../contexts/App';
import { useCategoriesHeaderContext } from '../../../../contexts/CategoriesHeaderContext';
import { timeoutUntilSuccess } from '../../../../libs/helpers/timeoutUntilSuccess';
import { useSearchParams } from 'react-router-dom';


export default function Categories({isSearchInHeader}: {isSearchInHeader?: boolean}) {
  const { catalog } = useAppContext()
  const [searchParams, setSearchParams] = useSearchParams()
  
  const {activeCategoryRef, activeCategoryIndex} = useCategoriesHeaderContext()

  const wrapperRef = useRef<null | HTMLDivElement>(null)
  const categoriesRefs = useRef<{[k: string] : MutableRefObject<any>}>({}).current;

  const scrollToCategory = (currentCategoryId: string | number | undefined, isMounting: boolean) => {
    if(currentCategoryId){
      const elem: HTMLElement = document.getElementById(`category_${currentCategoryId}`) as HTMLElement
      const offsetTop = Number(elem?.offsetTop - 120)
      if(isMounting){
        setTimeout(()=>{
          const elem: HTMLElement = document.getElementById(`category_${currentCategoryId}`) as HTMLElement
          const offsetTop = isMounting? Number(elem?.offsetTop - 120 ): Number(elem?.offsetTop - 120)
          Scroll.animateScroll.scrollTo(offsetTop, null)
        }, 1000)
        return
      }
      Scroll.animateScroll.scrollTo(offsetTop, null)
    }
  }

  const onScrollByGetParams = (isMounting?: boolean) => {
    const currentCategoryId = searchParams.get('category')
    scrollToCategory(currentCategoryId as string, isMounting || false)
  }
  

  useEffect(() => {
    onScrollByGetParams(true)
    window.addEventListener('hashchange', () => onScrollByGetParams(false))
  }, [])


  useEffect(() => {
    if (activeCategoryRef.id && searchParams.get('product')){
      const currentCategoryId = activeCategoryRef.id.split(`_`)[1]
      setSearchParams(params => {
        params.set("category", currentCategoryId);
        return params;
      });
    }
  }, [activeCategoryRef.id])

  useEffect(() => {
    if (categoriesRefs[activeCategoryIndex]?.current) {
      wrapperRef.current?.scrollTo({
        left: categoriesRefs[activeCategoryIndex].current.offsetLeft - wrapperRef.current?.offsetLeft - 5,
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [activeCategoryIndex])

  const allCategories = catalog.filter((category: any) => category.goods.length)

  const [showNextArrow, setShowNextArrow] = useState(false)
  const updateShowNextArrow = ()=> {
    if (allCategories.length > 0) {
      const lastId = `element-${allCategories.length - 1}`
      const element = document.getElementById(lastId);
      const elementWrap = document.getElementById('categories-rect');

      if (element == null || elementWrap == null) return false
      const rect = element.getBoundingClientRect();
      const rectWrap = elementWrap.getBoundingClientRect();

      const ARROW_SIZE = 30
      if (rectWrap.width == 0) return false
      if (rect.right < rectWrap.right)
        setShowNextArrow(false)
      if (rect.right > rectWrap.right + ARROW_SIZE)
        setShowNextArrow(true)
    } else {
      setShowNextArrow(false)
    }
    return true
  }

  useEffect(() => {
    return timeoutUntilSuccess(updateShowNextArrow)
  })

  const handleNext = () => {
    if (allCategories[activeCategoryIndex + 1]) {
      const idx = activeCategoryIndex + 1
      scrollToCategory(allCategories[idx].id, false)
    }
  }

  const handlePrev = () => {
    if (allCategories[activeCategoryIndex - 1]) {
      const idx = activeCategoryIndex - 1
      scrollToCategory(allCategories[idx].id, false)
    }
  }

  return (
    <div id={'categories-rect'} className='flex flex-row items-center overflow-hidden  w-full '>
      <div className={`w-[50px] sm:w-[40px] h-[40px] ${activeCategoryIndex !== 0 ? '' : 'mr-[10px] sm:mr-[20px] md:mr-[30px]'}`}></div>
      <button
        className={`${activeCategoryIndex !== 0 ? 'block' : 'hidden'} flex items-center justify-center w-[40px] h-full`}
        onClick={() => handlePrev()}>
        <div className='rotate-180 '>
          <Arrow/>
        </div>
      </button>

      <div ref={wrapperRef} className={'pretty-scroll3 py-2 overflow-x-auto w-full h-full flex flex-row overflow-hidden'}>
        {
          allCategories.map((category: any, index: number, array: any) => {
              if (!categoriesRefs[index]) categoriesRefs[index]= {current: null}

              const categoryRef =  categoriesRefs[index]
              return <Scroll.Link
                key={`category_${category.id}`}
                to={`category_${category.id}`}
                smooth={true}
                offset={-110}
              >
                <div
                  id={'element-' + index}
                  ref={categoryRef}
                  className={`whitespace-nowrap ${(index == array.length - 1) ? '' : 'mr-[17px]'} cursor-pointer md:hover:text-main md:dark:hover:text-main text-sm dark:text-gray-10 ${!isSearchInHeader && 'font-medium'}  -tracking-[.01em] md:hover:text-main duration-200 ${activeCategoryRef.id === `category_${category.id}` && '!text-main'}`}>
                  { category.title }
                </div>
              </Scroll.Link>

            }
          )
        }
      </div>

      <button
        className={`${showNextArrow ? 'block' : 'hidden'} flex items-center justify-end w-[30px] h-full`}
        onClick={() => handleNext()}>
        <Arrow/>
      </button>
    </div>
  )
}

function Arrow() {
  return (
    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.706956 2.25511C0.316431 1.86458 0.316431 1.23142 0.706956 0.840895L0.848464 0.699387C1.23899 0.308863 1.87215 0.308863 2.26268 0.699388L7.0006 5.43731V5.6261L2.26268 10.364C1.87215 10.7545 1.23899 10.7545 0.848464 10.364L0.706956 10.2225C0.316431 9.83199 0.316431 9.19883 0.706956 8.8083L3.98355 5.53171L0.706956 2.25511Z" className='fill-dark dark:fill-gray-10 '/>
    </svg>
  )
}

