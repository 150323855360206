import { useAddressContext } from "../../../../contexts/AddressContext"
import { useAppContext } from "../../../../contexts/App"
import { useCartContext } from "../../../../contexts/CartContext"
import { formatCurrency } from "../../../../libs/helpers/formatCurrency"
import { BaseButton } from "../../button/BaseButton"
import PopupActionSheet from "../PopupActionSheet"


export function ChangeBranchPopup() {
  const {changeBranchPopupCallback, deletedProducts} = useAddressContext()
  const {cart} = useCartContext()
  const {branch} = useAppContext()

  return <PopupActionSheet
    closeIcon={true}
    isActive={changeBranchPopupCallback !== null}
    close={() => changeBranchPopupCallback && changeBranchPopupCallback(false)}>
    <p className={"text-2xl font-medium mb-2"}>Предупреждение</p>
    <p>Подтвердите смену адреса, при смене адреса из вашей корзины будут удалены позиции</p>
    <div className={'flex flex-col my-2 max-h-[40vh] overflow-y-auto pretty-scroll'}>
      {
        deletedProducts.map((product: any) => {
          return <div key={product.uid} className={'flex flex-row items-center gap-x-3 w-full my-[15px]'}>
            {
              product?.image ?
                <img className={`h-[80px] !w-auto xs:w-full rounded-[17px] grayscale`} src={product?.image} alt="" /> :
                <div className={`w-[80px] h-[80px] bg-gray-30 rounded-[17px]`}></div>
            }
            <div className={'flex flex-col '}>
              <p className={'font-medium -tracking-[.01em]'}>{product?.title}</p>
              {
                !!(product?.weight || product?.size) &&
                  <div className={` text-sm flex gap-2 -tracking-[.01em] font-semibold text-gray-30`}>
                    { product?.weight && <div>{ product?.weight } г.</div> }
                    { product?.size && <div>{ product?.size } { product?.measure_unit }</div> }
                  </div>
              }
              <div className={'flex items-center gap-1 font-bold '}>
                <span> {product?.count} x {formatCurrency(branch.extended.currency.code, product?.itemAmount)}</span>
              </div>
            </div>
          </div>
      })
      }
    </div>
    <div className={"mt-4 flex flex-row justify-between w-full gap-x-4"}>
      <BaseButton onClick={() => changeBranchPopupCallback && changeBranchPopupCallback(false)}
                  className={"w-full bg-orderbtn text-main"}>Отмена</BaseButton>
      <BaseButton onClick={() => {
        changeBranchPopupCallback && changeBranchPopupCallback(true)
      }} className={"w-full bg-main text-white"}>Подтвердить</BaseButton>
    </div>
  </PopupActionSheet>
}
