import {cookieWrap} from "../helpers/cookieWrap";

export default async function getAddress(city: any, branch: any) {

  let addressCookes: any = cookieWrap.getCookie('address') || 'null';

  addressCookes = JSON.parse(addressCookes)

  // Проверяем отношение адреса к филиалу по зоне доставки или по точкам самовывоза
  if (addressCookes && addressCookes.point) {
    if (addressCookes.type === 'pickup' ) {
      // Адрес самовывоза
      if (addressCookes.point.city == city.slug && +addressCookes.point.branchId === branch.id) {
        // TODO: Здесь нужна ещё проверка наличие этого адреса в точках самовывоза филиала
        return { address: addressCookes }
      }
      //console.log(`address reset: pickup point branch/city do not match city=${addressCookes.point.city}/${city.slug} branch=${+addressCookes.point.branchId}/${branch.id}`)
    } else if (addressCookes.type === 'delivery') {
      // Адрес доставки
      if (addressCookes.point.city == city.slug) {
        return {address: addressCookes}
      }
      //console.log("address reset: delivery city do not match")
    }
  }

  return { address: null }
}
