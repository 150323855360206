import { useEffect, useState } from "react"


export const TextareaForThirdStepReviews = ({maxLength, id, answers, errors}: any) => {
	const [textareaValue, setTextareaValue] = useState('')
	const [enteredSymbols, setEnteredSymbols] = useState(0);

	const [error, setError] = useState(null)

  useEffect(() => {
    if(errors){
      setError(answers.map((answer: any, index: number) => {
          if(errors[`answers.${index}.answer`] && answer.npsQuestionId === id){
            return errors[`answers.${index}.answer`]
          }
        }).filter((answer: any) => answer))
    }
  }, [errors])

	const updateCurrentAnswer = (value: any) => {
    const currentAnswer = answers.find((answer: any) => answer.npsQuestionId === id)
    if(currentAnswer){
      currentAnswer.answer = value
    } else {
      answers.push({npsQuestionId: id, answer: value})
    }
  }
  
  return (
    <div>
			<textarea
				maxLength={maxLength}
				placeholder='Комментарий'
				value={textareaValue}
				onChange={e => {
					setTextareaValue(e.target.value)
					setEnteredSymbols(e.target.value.length)
					updateCurrentAnswer(e.target.value)
				}}
        style={{outlineStyle: 'none'}}
				className={`h-[135px] resize-none outline-0 disabled:opacity-50 disabled:pointer-events-none py-[10px] px-[25px] dark:[&::-webkit-input-placeholder]:text-white w-full text-sm font-medium bg-gray-20 dark:bg-gray-40 rounded-[10px] "text-gray-50 h-[150px]"`}
			></textarea>
			<p className="xs:text-sm text-xs text-gray-40 text-end mt-[5px]">
				{enteredSymbols} / {maxLength}
			</p>
      {error && 
        <p className={'mt-[10px] text-yellow text-center text-xs font-medium'}>{error}</p>
      }
    </div>
  )
}