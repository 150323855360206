import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import { useAppContext } from "./App";
import { sendEvent, useVisitorContext } from "./VisitorContext";

interface ContextType {
  searchCategoriesWrapper: any;
  searchInHeader: boolean;
  setSearchInHeader: (v: boolean) => void;
  activeCategoryRef: any,
  headerRef: any,
  activeCategoryIndex: any
}

const CategoriesHeaderContext = createContext<ContextType>({
  searchCategoriesWrapper: null,
  searchInHeader: false,
  setSearchInHeader: () => {},
  activeCategoryRef: null,
  headerRef: null,
  activeCategoryIndex: null,
})

export function CategoriesHeaderContextWrapper({children}: any) {
  const { catalog } = useAppContext()

  const [searchInHeader, setSearchInHeader] = useState(false)
  const searchCategoriesWrapper: any = useRef(null)
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(-1)

  // Активная сейчас категория
  const activeCategoryRef = useRef({
    id: null as null | string,
    index: null as null | number,
    sentCategories: [] as any[],
    sendTimeoutHandle: null as any,
    setCategoryId: (() => {}) as (id: string) => void
  }).current

  activeCategoryRef.setCategoryId =  (id: string | null) => {
    if (activeCategoryRef.id !== id) {
      activeCategoryRef.id = id

      // Отправка уведомления о посещении категории
      if (activeCategoryRef.sendTimeoutHandle) {
        clearTimeout(activeCategoryRef.sendTimeoutHandle)
      }
      activeCategoryRef.sendTimeoutHandle = setTimeout(() => {
        if (activeCategoryRef.id && sessionStorage.getItem('visitorId')) {
          const currentCategoryId = activeCategoryRef.id.split(`_`)[1]
          if (!activeCategoryRef.sentCategories.includes(currentCategoryId)) {
            const c = catalog.filter((category: any) => category.id == currentCategoryId).at(0);
            if (c) {
              sendEvent('section-visited', {target: `Раздел ${c.title}`, categoryId: currentCategoryId}).then()
              activeCategoryRef.sentCategories.push(currentCategoryId)
            }
          }
        }
      }, 1000)
    }
  }

  const headerRef = useRef<any>(null)
  let categoriesOffsetsRef = useRef<any>([])
  useEffect(() => {
    categoriesOffsetsRef.current = []
  }, [catalog])

  useEffect(() => {
    function handleScroll(e: any) {
      // Показ прилепающего меню в хедере
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (searchCategoriesWrapper && headerRef.current) {
        const searchHeight = searchCategoriesWrapper.current?.offsetHeight;
        const headerHeight = headerRef.current.offsetHeight - searchHeight;

        if (searchInHeader){
          if(scrollTop + headerHeight - 10 < searchCategoriesWrapper.current?.offsetTop){
            setSearchInHeader(false)
          }
        } else {
          if(scrollTop + searchHeight + headerHeight + 10 > searchCategoriesWrapper.current?.offsetTop){
            setSearchInHeader(true)
          }
        }
      }

      if (categoriesOffsetsRef.current.length === 0) {
        categoriesOffsetsRef.current = Array.from(document.querySelectorAll('.catalog-category')).map((category: any, index) => ({id: category.id, index: index, offset: category.offsetTop}))
      }

      let categoriesOffsets = categoriesOffsetsRef.current
      for (let index = categoriesOffsets.length - 1; index >= 0; index--) {
        if (categoriesOffsets[index].offset - 200 < scrollTop) {
          activeCategoryRef.setCategoryId(categoriesOffsets[index].id)
          setActiveCategoryIndex(categoriesOffsets[index].index)
          break
        }
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [searchCategoriesWrapper, headerRef, searchInHeader]);

  return (
    <CategoriesHeaderContext.Provider value={{searchCategoriesWrapper, searchInHeader, setSearchInHeader, activeCategoryRef, headerRef, activeCategoryIndex}}>
      {children}
    </CategoriesHeaderContext.Provider>
  )
}

export function useCategoriesHeaderContext() {
  return useContext(CategoriesHeaderContext)
}
