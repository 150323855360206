import React, { useState, useEffect } from 'react'
import Promo from './Promo'
import Bonus from './Bonus'
import GiftСhoice from './GiftСhoice';
import Products from './Products';
import Price from '../Price';
import RecommendedProducts from './RecommendedProducts';
import { useCartContext } from '../../../../contexts/CartContext';
import { useAppContext } from '../../../../contexts/App';
import { NotificationBlock, sendEvent } from '../../../../contexts/VisitorContext';
import { localStorageWrap } from '../../../../libs/helpers/localStorageWrap';
import Popup from '../../../common/popup';
import { formatCurrency } from '../../../../libs/helpers/formatCurrency';
import { AdditionalFields } from '../../../../contexts/AdditionalFieldsFunnelsContext';
import { Preloader } from '../../../common/preloader';


export default function CartStep({ nextOrderPage, loaded, bonusAmount, backToCartMessage }: any) {
  const { city, company, branch, address, isOpen, updateCurrentBranch } = useAppContext()
  const { cart, updateCart, synchronize, isInitCart, loading } = useCartContext()
  //-----------------Updating
  // useUpdatePage(
  //   () => {
  //     updateCurrentBranch() //если в будущем придумают как из филиала обновлять определенные поля - заюзать
  //     updateCart({})
  //   }
  // )

  useEffect(() => {
    if (sessionStorage.getItem('visitorId')) {
      sendEvent('section-visited', {target: 'Корзина'})
    }
  }, [typeof sessionStorage !== 'undefined' && sessionStorage.getItem('visitorId')])

  const [showDeliveryInfoPopup, setShowDeliveryInfoPopup] = useState(false)
  const [showedDeliveryInfoPopup, setShowedDeliveryInfoPopup] = useState(false)

  useEffect(() => {
    // Показываем попап с информацией о доставке если не хватает до минимальной суммы заказа на доставку или до порога бесплатной доставки
    if (address?.type === 'delivery' &&
      !showedDeliveryInfoPopup && 
      cart.deliveryZoneId && 
      (
        (cart.options.deliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) < cart.options.deliveryFromTotal) || 
        (!!(cart.options.freeDeliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) < cart.options.freeDeliveryFromTotal && (cart.options.deliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) > cart.options.deliveryFromTotal || !cart.options.deliveryFromTotal)) && !cart.options.isFreeDeliveryDisabled)
      )
    ) {
      setShowDeliveryInfoPopup(true)
      setShowedDeliveryInfoPopup(true)
    } else {
      setShowDeliveryInfoPopup(false)
      setShowedDeliveryInfoPopup(false)
    }
  }, [address, cart, cart.deliveryZoneId])

  const [load, setLoad] = useState(true)

  useEffect(() => {
    // На случай если юзверь перейдёт в корзину через url надо это отловить и синхронизировать корзину
    if (!JSON.parse(localStorageWrap.getItem('isSynchronizedCart') || 'false') && isInitCart) {
      synchronize().then(() => setTimeout(() => setLoad(false), 500))
    } else {
      setLoad(false)
    }
  }, [isInitCart])

  return (
    <>
      <NotificationBlock place='cart-top'/>
      
      <div className={`w-full max-w-[1000px] mx-auto px-[15px] md:px-[75px]`}>
        <Popup
          isActive={showDeliveryInfoPopup}
          close={() => setShowDeliveryInfoPopup(false)}
          closeIcon
        >
          <div className='text-lg font-bold'>Доставка</div>
          {
            !!cart.deliveryZoneId &&
            <div>
              {
                // Если есть минимальная цена для доставки, и пользователь её ещё не набрал
                (cart.options.deliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) < cart.options.deliveryFromTotal) && 
                <div className={'mt-3 flex items-center gap-1 -tracking-[.01em] text-yellow'}>
                  Минимальная сумма заказа для доставки {formatCurrency(branch.extended.currency.code, cart.options.deliveryFromTotal)}
                </div>
              }
              {
                // Если есть цена после, которой доставка бесплатная, то выводим сколько пользователю ещё осталось добрать, но не выводим если не набран минимум для доставки, если он есть конечно
                !!(cart.options.freeDeliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) < cart.options.freeDeliveryFromTotal && (cart.options.deliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) > cart.options.deliveryFromTotal || !cart.options.deliveryFromTotal)) && !cart.options.isFreeDeliveryDisabled &&
                <div className={'mt-3 flex items-center gap-1 -tracking-[.01em] text-main'}>
                  До бесплатной доставки ещё {formatCurrency(branch.extended.currency.code, cart.options.freeDeliveryFromTotal - cart.total + (cart.options.deliveryAmount || 0))}
                </div>
              }
            </div>
          }
        </Popup>

        {backToCartMessage && <div className={'mt-6 gap-[6px] font-bold -tracking-[.01em] text-yellow'}>
          <span className='md:flex md:items-center inline-block justify-center'>{ backToCartMessage }</span>                
        </div>}

        <div className={`flex flex-col md:flex-row gap-5 md:gap-[30px] mt-4 md:mt-8`}>
          {
            branch.order.fields.promoCode.isUsed &&
            <Promo className='w-full md:w-1/2 md:mb-6'/>
          }

          {
            company.loyaltyProgram !== 'none' && !!cart.discounts?.maxAppliedBonusDiscount && !cart.options.isBonusDiscountsDisabled &&
            <Bonus className='w-full md:w-1/2' />
          }
        </div>

        <AdditionalFields place='top-in-cart'/>
        
        {
          load ? 
          <Preloader countOfDot={3} color={'main'} size={'8px'} className={'py-[100px] min-w-[122px] gap-2'}/> :
          <>
            <GiftСhoice/>

            <Products/>

            <RecommendedProducts/>
          </> 
        }

        <AdditionalFields place='bottom-in-cart'/>

        {
          branch.extended.cartText &&
          <div className="mt-7 -tracking-[.02em] text-gray-50 dark:text-gray-10 text-sm md:text-base">
            { branch.extended.cartText }
          </div>
        }

        {
          cart.discounts?.comments?.map((comment: string, index) => (
            <div key={index} className={'mt-2 text-[18px] -tracking-[.01em] text-main'}>
              { comment }
            </div>
          ))
        }

        {
          address?.type === 'delivery' && cart.deliveryZoneId && 
          <>
              {
                // Если есть минимальная цена для доставки, и пользователь её ещё не набрал
                !!(cart.options.deliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) < cart.options.deliveryFromTotal) && 
                <div className={'mt-6 gap-[6px] text-[20px] font-bold -tracking-[.01em] text-yellow'}>
                  <span className='md:flex md:items-center inline-block justify-center'><span className='align-middle'>Минимальная сумма заказа для доставки {formatCurrency(branch.extended.currency.code, cart.options.deliveryFromTotal)}</span></span>                
                </div>
              }
              {
                // Если есть цена после, которой доставка бесплатная, то выводим сколько пользователю ещё осталось добрать, но не выводим если не набран минимум для доставки, если он есть конечно
                !!(cart.options.freeDeliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) < cart.options.freeDeliveryFromTotal && (cart.options.deliveryFromTotal && cart.total - (cart.options.deliveryAmount || 0) >= cart.options.deliveryFromTotal || !cart.options.deliveryFromTotal)) && !cart.options.isFreeDeliveryDisabled &&
                <div className={'mt-6 flex items-center gap-[6px] text-[20px] font-bold -tracking-[.01em] text-main justify-center'}>До бесплатной доставки ещё {formatCurrency(branch.extended.currency.code, cart.options.freeDeliveryFromTotal - cart.total + (cart.options.deliveryAmount || 0))}</div>
              }
          </>
        }

        {
          cart.options.disabledMakingOrderComment &&
          <div className={'mt-6 gap-[6px] text-[20px] font-bold -tracking-[.01em] text-yellow'}>
            <span className='md:flex md:items-center inline-block justify-center'><span className='align-middle'>{cart.options.disabledMakingOrderComment}</span></span>                
          </div>
        }

        <Price 
          allProductsPrice={cart.amount} 
          totalPrice={cart.total} 
          totalDiscount={cart.discounts?.discountWithoutBonus || 0} 
          deliveryAmount={cart.options.freeDeliveryFromTotal && !cart.options.isFreeDeliveryDisabled && cart.total - (cart.options.deliveryAmount || 0) >= (cart.options.freeDeliveryFromTotal || 0) ? 0 : cart.options.deliveryAmount || 0}
          deliveryShow={address?.type === 'delivery'}
          bonus={cart.discounts?.bonusDiscount}
        />
          
        <div className='flex flex-col md:flex-row gap-y-[25px] items-center justify-end mt-[50px] mb-[100px]'>

          <div  
            onClick={nextOrderPage}
            className={`${(!cart?.raw?.filter(p => !p.isGift).length || ((cart?.total || 0) - (cart.options.deliveryAmount || 0) < (cart.options.deliveryFromTotal || 0) && address?.type === 'delivery' && company.isCustomerAddressMustBeInDeliveryZone) || (!isOpen && !branch.extended.allowOrderAfterHours) || cart.options.isMakingOrderDisabled) && 'opacity-50 pointer-events-none'} flex items-center justify-center w-full md:max-w-[489px] h-10 md:h-[45px] bg-orderbtn text-main hover:bg-main hover:text-white text-lg font-bold rounded-full cursor-pointer`}
          >
            {
              loaded && !loading ?
              <span>К оформлению заказа</span> :
              <Preloader countOfDot={3} size='10px'/>
            }
          </div>
        </div>
      </div>

      <NotificationBlock place='cart-bottom'/>
    </>
  )
}
