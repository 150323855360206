import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import { useAppContext } from "./App";
import { useAuthContext } from "./AuthContext";
import useSelectCity from "../libs/helpers/selectCity";
import { useCartContext } from "./CartContext";
import { apiClient } from "../libs/api/apiClient";
import { cookieWrap } from "../libs/helpers/cookieWrap";
import { getDeliveryZoneAndBranchByCoords } from "../libs/helpers/deliveryZones";


interface IAddressContext {
  allUserAddresses: any[],
  setAllUserAddresses: (v: any[]) => void,
  fetchAllUserAddresses: () => Promise<void>,
  changeBranchPopupCallback: ((v: boolean) => void) | null,
  addressError: {message: string, type: string} | null,
  trySaveDeliveryPoint: (city: any, point: any) => Promise<boolean>,
  trySavePickupPoint: (city: any, point: any) => Promise<boolean>,
  setAddressError: (v: {message: string, type: string} | null) => void,
  addressRef: React.MutableRefObject<any>,
  deletedProducts: any[],
  successSelectCallback: () => void;
  setSuccessSelectCallback: (f: () => void) => void;
}

const AddressContext = createContext<IAddressContext>({
  trySaveDeliveryPoint: () => Promise.resolve(false),
  trySavePickupPoint: () => Promise.resolve(false),
  allUserAddresses: [],
  setAllUserAddresses: () => {},
  fetchAllUserAddresses: () => Promise.resolve(),
  changeBranchPopupCallback: null,
  addressError: null,
  setAddressError: () => {},
  addressRef: { current: null },
  deletedProducts: [],
  successSelectCallback: () => {},
  setSuccessSelectCallback: () => {},
})

type Props = {
  children: React.ReactNode,
}

export function AddressContextWrapper ({children}: Props) {
  const { city, company, branch, address, setAddress, allProducts } = useAppContext();
  const { user } = useAuthContext()
  const selectCity = useSelectCity()
  const {cart, updateCart, synchronize} = useCartContext()

  const [allUserAddresses, setAllUserAddresses] = useState<any[]>([])
  const [changeBranchPopupCallback, setChangeBranchPopupCallback] = useState<((v: boolean) => void) | null>(null)
  const [addressError, setAddressError] = useState<{message: string, type: string} | null>(null)
  const [successSelectCallback, setSuccessSelectCallback] = useState(() => () => {})
  const addressRef = useRef<any>()
  addressRef.current = address

  const fetchAllUserAddresses = async () => {
    if (user == null || branch?.id == null ) {
      setAllUserAddresses([])
      return;
    }
    try {
      await apiClient.profileAddress.addresses(branch?.id).then(({data}) => {
        setAllUserAddresses(data || [])
      })
    } catch(e) {}
  }

  useEffect(() => {
    fetchAllUserAddresses().then()
  }, [user, branch?.id])

  const savePickupPoint = (newCity: any, newPickupPoint: any) : Promise<void> => {
    const point = {...newPickupPoint, city: newCity.slug, deliveryZone: null}
    cookieWrap.setCookie('address', {type: 'pickup', point})
    setAddress(addressRef.current = {type: 'pickup', point})
    //console.log(newPickupPoint.branchId || branch?.id)
    return selectCity(newCity.slug, newPickupPoint.branchId || branch?.id)
  }

  const saveDeliveryPointLocally = async (newCity: any, point: any) => {
    const addr = {type: 'delivery', point: {...point, city: newCity.slug, deliveryZone: null}}
    cookieWrap.setCookie('address', addr)
    setAddress(addressRef.current = addr)
    await selectCity(newCity.slug, point.branchId || branch?.id)
  }

  const saveDeliveryPoint = (newCity: any, point: any) : Promise<boolean> => {
    if (point.deliveryZoneId != null) {
      updateCart({deliveryZoneId: point.deliveryZoneId})
    }

    if (user == null) {
      if (!point.house) {
        setAddressError({type: 'message', message: 'Введите корректный адрес'})
        return Promise.resolve(false);
      }
      return saveDeliveryPointLocally(newCity, point).then(() => true)
    }

    // avoid adding complete duplicates
    const addressToOverride: any = allUserAddresses.find(o =>
      o.address &&
      point.title === o.address.title &&
      // point.lon === o.address.lon && point.lat === o.address.lat &&
      point.house === o.address.house &&
      (point.entrance == null || point.entrance == o.entrance) &&
      (point.flat == null || point.flat == o.flat) &&
      (point.floor == null || point.floor == o.floor) ||
      (point.addressId === o.id && (point.selectAddress || point.editAddress)))

    console.log(point, addressToOverride)

    if (point.selectAddress && addressToOverride) {
      return saveDeliveryPointLocally(newCity, point).then(() => true)
    }

    //console.log(point, addressToOverride)

    const req = {flat: '0', address: point, city: newCity.guid}
    const res = (addressToOverride?.id ?
      apiClient.profileAddress.editAddress(req, addressToOverride?.id) :
      apiClient.profileAddress.addAddress(req))
    return res.then(({data, message, status}) => {
      if (status !== 200 && status !== 201) {
        setAddressError({type: 'message', message: message + ''})
        return false
      } else if (data) {
        setAddressError(null)
        return saveDeliveryPointLocally(newCity, {...point, ...data.address, addressId: data.id}).then(() => true)
      }
      return false;
    }).catch(err => {
      setAddressError({type: 'message', message: err + ''})
      return false
    })
  }

  const [deletedProducts, setDeletedProducts] = useState<any[]>([])

  const getDeletedProducts = (newBranchId: number) : Promise<any[]> => {
    if (!cart.cartId) return Promise.resolve([])
    return apiClient.cart.willBeDeleted(newBranchId, cart.cartId).then((data) => {
      if (data?.products) {
        const result = Object.values(data.products).map((c : any) => {
          let productData: any = cart.raw.find((productInCart: any) => {
            return productInCart.productId === c.productId;
          })
          let productInfo = allProducts[productData.productId]
          return {...productData, ...productInfo}
        })
        setDeletedProducts(result)
        return result
      }
      return []
    }).catch(() => [])
  }

  //------------------------check delivery zone------------------------------
  const trySaveDeliveryPoint = (newCity: any, deliveryPoint: any) : Promise<boolean> => {
    if (deliveryPoint) {
      return synchronize().then(() => {
        return getDeliveryZoneAndBranchByCoords(newCity, deliveryPoint.lat, deliveryPoint.lon, [branch?.id]).then(async (zoneAndBranchId)=>{
          if (!zoneAndBranchId) {
            if (company.isCustomerAddressMustBeInDeliveryZone) {
              setAddressError({type: "notZone", message: 'Выбранный адрес не входит ни в одну зону доставки'})
              return false;
            } else {
              return saveDeliveryPoint(newCity, {...deliveryPoint, branchId: branch.id, notZone: true, city: newCity.slug }).then(() => true)
            }
          } else {
            const { branchId, deliveryZoneId } = zoneAndBranchId;
            const pointToSave = {...deliveryPoint, branchId, deliveryZoneId, city: newCity.slug }
            if (branch.id !== branchId) {
              let deletedProducts = await getDeletedProducts(branchId)
              if (deletedProducts.length > 0) {
                return new Promise<boolean>(resolve => {
                  setChangeBranchPopupCallback(() => (v: boolean) => {
                    if (v) {
                      saveDeliveryPoint(newCity, pointToSave).then((v) => {
                        setChangeBranchPopupCallback(null)
                        resolve(v)
                      })
                    } else {
                      setChangeBranchPopupCallback(null)
                      resolve(false)
                    }
                  })
                })
              }
            }
            return saveDeliveryPoint(newCity, pointToSave)
          }
        })}
      )
    }
    return Promise.resolve(false)
  }

  //----------------------------------------try save pickup point
  const trySavePickupPoint = async (newCity: any, newPickupPoint: any) => {
    synchronize().then(async () => {
      if (branch.id !== newPickupPoint.branchId) {
        let deletedProducts = await getDeletedProducts(newPickupPoint.branchId)
        if (deletedProducts.length > 0) {
          return new Promise<boolean>(resolve => {
            setChangeBranchPopupCallback(() => (v: boolean) => {
              if (v) {
                savePickupPoint(newCity, newPickupPoint).then(() => {
                  setChangeBranchPopupCallback(null)
                  resolve(true)
                })
              } else {
                setChangeBranchPopupCallback(null)
                resolve(false)
              }
            })
          })
        }
      }
      await savePickupPoint(newCity, newPickupPoint)
    })

    return true
  }

  return (
    <AddressContext.Provider value={{
      trySavePickupPoint, trySaveDeliveryPoint,
      allUserAddresses, setAllUserAddresses, fetchAllUserAddresses,
      addressError, setAddressError,
      changeBranchPopupCallback,
      addressRef,
      deletedProducts,
      successSelectCallback, setSuccessSelectCallback}}>
      { children }
    </AddressContext.Provider>
  )

}

export function useAddressContext() {
  return useContext(AddressContext)
}
