import React, { useEffect, useMemo, useState } from 'react'
import Slider from "react-slick";
import { useCartContext } from '../../../../contexts/CartContext';
import { useAppContext } from '../../../../contexts/App';
import { resizeImage } from '../../../../libs/helpers/resizeImage';
import productImgStub from '../../../../images/productImgStub.jpg'
import { Preloader } from '../../../common/preloader';


export default function GiftСhoice() {
  const { cart } = useCartContext()
  const { allProducts } = useAppContext()

  const countChosenGifts = useMemo(() => {
    return cart.products.filter(p => p.isChosenGift).length
  }, [JSON.stringify(cart.products)])

  const giftsForChoose = useMemo(() => {
    return Object.values(cart.options.giftsForChoose.filter((p: any) => allProducts[p.goodId]).reduce((ps: any, p: any) => ({...ps, [p.goodId]: {...allProducts[p.goodId], comment: p?.comment}}), {}))    
  }, [JSON.stringify(cart.options.giftsForChoose)])

  if (!cart.options.giftsForChoose.length) {
    return <></>
  }

  return (
    <div className={`pt-1 md:pt-[15px] mx-[-20px] rounded-b-[29px] overflow-hidden`}>
      <div className={'flex items-center justify-between mt-1 md:mt-[15px]'}>
        <div className='text-xl -tracking-[.01em] font-bold  ml-5'>Подарок на выбор</div>

        {cart.options.maxChosenGifts > 1 && <div className='text-xl -tracking-[.01em] font-bold text-main'>{countChosenGifts} / {cart.options.maxChosenGifts}</div>}
      </div>

      <Slider 
        variableWidth={true}
        arrows={false}
        infinite={false}
      >
        {
          giftsForChoose.map((product: any) => (
            <div className='mx-[15px] h-full ' key={product.id}>
              <Product product={product} maxChosenGifts={cart.options.maxChosenGifts == countChosenGifts}/>
            </div>
          ))
        }
      </Slider>

    </div>
  )
}

function Product({ product, maxChosenGifts }: any) {
  const { allProducts } = useAppContext()
  const { cart, addProductServer, updateProductServer } = useCartContext()
  const [load, setLoad] = useState(false)

  const productCart = useMemo(() => {
    return cart.products.filter(p => p.isChosenGift && p.productId == product.id)[0]
  }, [JSON.stringify(cart.products)])

  return (
    <div onClick={() => {}} className='w-[280px] h-full lg:w-[331px] flex gap-4 p-3 lg:p-[15px] cursor-pointer bg-white dark:bg-gray-40 rounded-[29px] my-4 md:mt-[30px] md:mb-[20px] shadow-[4px_4px_20px_#00000030]'>
      {
        product.image ?
        <div className='rounded-[17px] overflow-hidden h-[125px] min-w-[125px]'>
          <picture className={'h-[125px]'}>
            <source className={'h-[125px]'} type="image/webp" srcSet={`${resizeImage(product.image, '125', 'webp')} 1x, ${resizeImage(product.image, '300', 'webp')} 2x, ${resizeImage(product.image, '400', 'webp')}3x`}/>    
            <source className={'h-[125px]'} type="image/jpeg" srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`}/>
            <img 
              className={'h-[125px]'}
              src={resizeImage(product.image, '125', 'jpg')} 
              srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`}
              alt=""
            />
          </picture>
        </div> :
        <img
          className='w-[125px] h-[125px] rounded-[17px]'
          src={productImgStub} 
          alt=""
        />
      }

      <div className='grow flex flex-col justify-between'>
        <div className='lg:text-lg leading-[127.02%] -tracking-[.01em] font-semibold text-dark dark:text-light'>{ product.title }</div>

        {product.comment && <div className='text-xs text-main my-1'>{product.comment}</div>}

        <>          
          {
            productCart ?
            <div 
              onClick={event => {
                event.stopPropagation()
                setLoad(true)
                updateProductServer(productCart.uid, {count: 0}).then(() => setLoad(false))
              }} 
              className='w-full h-[35px] flex items-center justify-center border-[2px] rounded-[18px] border-main text-main font-bold cursor-pointer'
            >
              {load ? <Preloader countOfDot={3} color={'white'} size={'8px'} className={'min-w-[122px] gap-2'}/> : 'Выбрано'}
            </div>:
            <div
              onClick={event => {
                event.stopPropagation()
                setLoad(true)
                addProductServer(product.id, [], 1, {isChosenGift: true}).then(() => setLoad(false))
              }}
              className={`${maxChosenGifts && 'opacity-50 pointer-events-none'} flex items-center justify-center w-full h-[35px] bg-orderbtn rounded-[18px] cursor-pointer -tracking-[.01em] font-bold text-main hover:bg-main hover:text-white duration-500`}
            >
              {load ? <Preloader countOfDot={3} color={'white'} size={'8px'} className={'min-w-[122px] gap-2'}/> : 'Выбрать'}
            </div>
          }
        </>
      </div>
    </div>
  )
}