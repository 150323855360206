import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useAppContext } from '../../../contexts/App';
import { apiClient } from '../../../libs/api/apiClient';
import StatusBar from '../../../components/pages/OrderPage/StatusBar';
import { BaseButton } from '../../../components/common/button/BaseButton';
import { formatAddress } from '../../../libs/helpers/formatAddress';
import Products from '../../../components/pages/OrderPage/Products';
import Price from '../../../components/pages/OrderPage/Price';
import { Preloader } from '../../../components/common/preloader';
import ArrowSecondaryIcon from '../../../components/icons/ArrowSecondaryIcon';
import ArrowIcon from '../../../components/icons/ArrowIcon';
import { Link, useParams } from 'react-router-dom';


export default function Order() {
  const { city, company, branch } = useAppContext()
  const [order, setOrder] = useState<any>(null)
  const [status, setStatus] = useState<any>(null)

  let { hash, payed } = useParams();

  function getOrder() {
    apiClient.order.getByHash(branch.id, hash || '').then(({ data }) => {
      setOrder(data)
      setStatus(company.statuses.filter((s: any) => s.code === data.status).at(0))
    })
  }

  useEffect(() => {
    getOrder()

    const timer = setInterval(() => {
      getOrder()
    }, 30000);
    
    return () => clearInterval(timer);
  }, [])


  return (
    <>
      <div className='w-full h-[50px] border-b-[1px] border-gray-20 dark:border-gray-50 relative flex items-center'>
        <div className='relative max-w-[1290px] w-full mx-auto'>
          <div className={`max-w-[1000px] mx-auto px-[15px] md:px-[75px] flex items-center justify-between h-full`}>
            <Link to={`/${city.slug}`} className='xl:absolute left-0'>
              <ArrowSecondaryIcon className='w-[12px] md:w-[15px] h-[8px] md:h-[10px]'/>
            </Link>
            <div className={`text-xs md:text-lg md:font-medium text-gray-40 opacity-50`}>Корзина</div>
            <ArrowIcon className={`w-[4px] md:w-[6px] h-[7px] md:h-[9px] opacity-50`} colorClassName={`fill-gray-40`}/>

            <div className={`text-xs md:text-lg md:font-medium text-gray-40 opacity-50`}>Оформление заказа</div>
            <ArrowIcon className={`w-[4px] md:w-[6px] h-[7px] md:h-[9px] opacity-50`} colorClassName={`fill-gray-40`} />

            {/* <div className='text-xs md:text-lg md:font-medium text-gray-40 opacity-50'>Оплата</div>
            <ArrowIcon className='w-[4px] md:w-[6px] h-[7px] md:h-[9px] opacity-50' colorClassName='fill-gray-40' /> */}

            <div  className={`text-xs md:text-lg md:font-medium text-main`}>Заказ принят</div>

          </div>
        </div>
      </div>    
      <div className={`w-full  max-w-[1000px] mx-auto px-[15px] md:px-[75px] ${!order && 'flex grow'}`}>
        {
          order ? 
          <div className={'-tracking-[.01em]'}>
            <div className={'mt-[34px] text-[24px] md:text-[28px] font-bold'}>Заказ №{order.id}</div>
            <div className={'mt-[15px] text-[18px] md:text-[20px] font-bold'}>{ status?.notification }</div>
            {!!branch?.messageTemplates?.descriptionOnPageAfterOrderPlaced && <div className={'mt-[10px] md:text-lg'}>{branch.messageTemplates.descriptionOnPageAfterOrderPlaced}</div>} 
            <StatusBar statuses={order.statusTraces} orderCreatedAt={order.createdAt} className='mt-8'/>
            <div className='flex items-center justify-between'>
              <div className='text-[22px] md:text-[24px] font-bold text-main my-[40px] -trackig-[.01em]'>{ status?.title }</div>
              {
                order.paymentType === 'online' && 
                !order.statusTraces.map((status: any) => status.status).includes('payed') && 
                order?.payment?.paymentLink &&
                payed !== 'true' &&
                <a href={order?.payment?.paymentLink}> 
                  <BaseButton className='bg-main text-white font-bold'> Оплатить заказ</BaseButton>
                </a>
              }
            </div>
            <div className='md:text-[20px] font-bold -trackig-[.01em]'>
              {order?.address ? 'Доставка' : 'Самовывоз'} {moment(order.createdAt).format('DD.MM.YYYY HH:mm')}
            </div>
            <div className='mt-[10px] md:text-[20px] text-gray-50 dark:text-gray-30 -trackig-[.01em]'>
              {formatAddress(order?.address ? order?.address : order?.pickupPoint?.address, [])}
              <span className="ml-[5px]">
                {order?.address && order?.entrance && <span className="mr-[3px]">{`под ${order.entrance},`}</span> }
                {order?.address && order?.floor && <span className="mr-[3px]">{`эт ${order.floor},`}</span> }
                {order?.address && order?.flat && <span className="mr-[3px]">{`кв ${order.flat}`}</span> }
            </span>
            </div>
            <Products className='my-4 md:my-[60px]' order={order}/>
            <Price amount={order.amount} discount={order.discount} total={order.total} bonus={order.bonusDiscount} />
            <div className='mt-[50px] mb-[55px] md:mb-[100px] w-full h-[35px] md:h-[45px] flex items-center justify-center bg-orderbtn rounded-full cursor-pointer text-lg font-bold text-main hover:bg-main hover:text-white'>Связаться с нами</div>
          </div> : 
          <div className='flex items-center justify-center grow'><Preloader size='10px'/></div>
        }
      </div>
    </>
  )
}
