import React, { useEffect, useState, useMemo } from 'react'
import productImgStub from "../../../../images/productImgStub.jpg";
import LinesEllipsis from 'react-lines-ellipsis'
import { useAppContext } from '../../../../contexts/App';
import { useCartContext } from '../../../../contexts/CartContext';
import { useProductDetailCardContext } from '../../../../contexts/ProductDetailCardContext';
import { SVGWrap } from '../../../common/util/SVGWrap';
import { resizeImage } from '../../../../libs/helpers/resizeImage';
import { formatCurrency } from '../../../../libs/helpers/formatCurrency';
import CrossedOldPriceIcon from '../../../icons/CrossedOldPriceIcon';
import { useAnimationProductsCartContext } from '../../../../contexts/AnimationCartContext';
import Counter from '../../../common/button/Counter';

export default function ProductCard({ productId, emptyImageClassName, isLastProduct = false }: any) {
  const { company, allProducts, address, branch } = useAppContext()
  const { setNoAddressWarning, setTextInAddressAlert,setHeaderTextInAddressAlert } = useCartContext()
  const { openDetail } = useProductDetailCardContext()
  const [imageLoaded, setImageLoaded] = useState(false)
  const actuallyTags = company.tags.filter((tag: any) => allProducts[productId]?.tags?.includes(tag.id))
  const product = allProducts[productId]

  let orientationIsVertical = company.template.options.cartMobileOrientation === 'vertical'

  if (!product) {
    return <></>
  }

  return (
    <>
      <div onClick={() => openDetail(product.id)}
        className={`flex 
        ${orientationIsVertical 
          ? `flex-col bg-white dark:bg-gray-50 shadow-md  rounded-[29px] p-[15px] my-[25px] xs:my-0` 
          : `xs:p-[12px] 
          md:hover:transform md:hover:scale-[1.03] scale-100 duration-200 gap-x-[20px] py-[25px] px-0 xs:flex-col xs:bg-white xs:dark:bg-gray-50 xs:shadow-lg xs:hover:shadow-2xl xs:rounded-[29px] `
        } cursor-pointer`}
      >

        <div className='relative overflow-hidden'>
          <div className={'z-[11] h-full w-full absolute grid xs:pt-1 lg:gap-y-0 lg:pt-0 justify-items-center items-center grid-cols-3 grid-rows-3 xs:grid-cols-5 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 xs:grid-rows-5'}>
            {product.tags &&
              actuallyTags.map((tag: any, index: number) => <div key={index} className='bg-white xs:w-[32px] xs:h-[32px] w-[25px] h-[25px] rounded-full'>
                <div className='rounded-full xs:w-[32px] xs:h-[32px] w-[25px] h-[25px] flex justify-center items-center'
                     style={{backgroundColor: tag.background}}>
                  <SVGWrap width={"13px"} height={"13px"} src={tag.icon} color={tag.color}/>
                </div>
              </div>)
            }
          </div>

          <div className={`relative rounded-[17px] overflow-hidden ${orientationIsVertical ? 'w-full' : 'w-[120px] xs:w-full'}`}>
            <img
              className={`${orientationIsVertical ? 'w-full ' : 'w-[120px] xs:w-full z-[5]'} p-[0.5px] rounded-[17px] ${emptyImageClassName}` }
              src={productImgStub}
              alt=""
            />
            <div className={'absolute z-[10] top-0 left-0 h-full w-full'}>
              {product.image && <picture style={{height: 'auto'}} className={`min-w-full`}>
                <source className={'w-full'} type="image/webp"
                        srcSet={`${resizeImage(product.image, '400', 'webp')} 1x, ${resizeImage(product.image, '600', 'webp')} 2x, ${resizeImage(product.image, 0, 'webp')} 2.5x`}/>
                <source className={'w-full'} type="image/jpeg"
                        srcSet={`${resizeImage(product.image, '400', 'jpg')} 1x, ${resizeImage(product.image, '600', 'jpg')} 2x, ${resizeImage(product.image, 0, 'jpg')} 2.5x`}/>
                <img
                  onLoad={() => setImageLoaded(true)}
                  loading={'lazy'}
                  className={`w-full duration-500 border-dark ${imageLoaded ? '' : 'opacity-0'}`}
                  src={resizeImage(product.image, '400', 'jpg')}
                  srcSet={`${resizeImage(product.image, '600', 'jpg')} 2x, ${resizeImage(product.image, 0, 'jpg')} 2.5x`}
                  alt=""
                />
              </picture>}
            </div>
          </div>
        </div>


        <div className={`flex flex-col grow  xs:w-full ${orientationIsVertical? 'w-full' : 'w-[50%]'}`}>
          <div className={`${orientationIsVertical ? 'mt-[25px] text-xl leading-[24px] ' : 'xs:mt-[25px]'} font-bold -tracking-[.01em] dark:text-gray-10`}>{ product.title }</div>

          {
            !!(product.weight || product.size || product.volume) ?
            <div className={`${orientationIsVertical ? 'mt-[9px]' : 'mt-[2px] xs:mt-[9px] text-sm xs:text-base'} flex gap-2 -tracking-[.01em] font-bold text-gray-30`}>
              { !!product.weight && <div>{ product.weight } г.</div> }
              { !!product.size && <div>{ product.size } { product.measure_unit }</div> }
              { !!product.volume && <div>{ product.volume } мл.</div> }
            </div> :
            <div className={`${orientationIsVertical ? 'h-[33px]' : 'h-0 xs:h-[33px]'}`}></div>
          }

          <div className={`${orientationIsVertical ? 'text-sm' : 'text-xs xs:text-sm'} grow lg:h-fit h-[42px] xs:overflow overflow-hidden -tracking-[.01em]  leading-[17px] text-gray-50 dark:text-gray-10`}>
            {window.innerWidth > 1100
              ?
              product.shortDescription
            :
            <LinesEllipsis
              text={product.shortDescription}
              basedOn="letters"
              maxLine={2}
            />
            }

          </div>

          {/*--------------------V-------------------*/}
          <div className={`mt-[17px] ${orientationIsVertical ? 'flex' : 'hidden xs:flex'} min-h-[50px] md+:min-h-[45px] items-center justify-between`}>
            <div className='font-bold'>
              <div className={`flex  ${!!(product?.skuGroupsValues.length || product?.optionsExists) ? 'md+:text-[15px] md+:leading-[15px]':'md+:text-[17px] md+:leading-[17px]'} text-xl leading-[24px] xl:text-xl xl:leading-[24px] dark:text-gray-10`}>
                {!!(product?.skuGroupsValues.length || product?.optionsExists) && 'От '}
                {formatCurrency(branch.extended.currency.code, product.price)}
              </div>
              {
                !!product.oldPrice &&
                <div className='relative w-[73%] ml-[2px] text-sm leading-[17px] text-gray-50 dark:text-gray-30'>
                  <div className='flex items-center opacity-60'>{formatCurrency(branch.extended.currency.code, product.oldPrice)}</div>
                  <div className='absolute w-full h-full left-0 top-0'>
                    <CrossedOldPriceIcon width={'100%'} height={'auto'}/>
                  </div>
                </div>
              }
            </div>

            {
              !!(product?.canBuyForFree && !product?.price || product?.price) && 
              <>
                {
                  !!(product?.skuGroupsValues.length || product?.optionsExists || (!product.canBuyForFree && product.price === 0)) ?
                  <div
                    onClick={(event) => {
                      event.stopPropagation()
                      if(address?.point == null) {
                        setTextInAddressAlert('Чтобы добавить блюдо в корзину нужно указать адрес. Хотим убедиться, что вы в зоне доставки.')
                        setHeaderTextInAddressAlert('Какой у вас адрес?')
                        setNoAddressWarning('toast')
                      } else {
                        openDetail(product.id)
                      }
                    }}
                    className='flex items-center justify-center w-[110px] md+:w-[100px] lg:w-[110px]  h-[35px] bg-orderbtn rounded-[18px] cursor-pointer -tracking-[.01em] font-bold text-main hover:bg-main hover:text-white duration-500'
                  >
                    Выбрать
                  </div> :
                  <ActionBlock product={product} orientationIsVertical={true}/>
                }
              </>
            }


          </div>

          {/*------------------H-----------------*/}
          {
            !!(product?.canBuyForFree && !product?.price || product?.price) && 
            <div className={`${orientationIsVertical ? 'hidden' : 'flex xs:hidden'} ${product.weight || product.size || product.volume? '' : 'mt-[10px]' } items-center gap-3`}>
              {
                !!(product?.skuGroupsValues.length || product?.optionsExists || (!product.canBuyForFree && product.price === 0)) ?
                <div
                  onClick={(event) => {
                    event.stopPropagation()
                    if(address?.point == null) {
                      setTextInAddressAlert('Чтобы добавить блюдо в корзину нужно указать адрес. Хотим убедиться, что вы в зоне доставки.')
                      setHeaderTextInAddressAlert('Какой у вас адрес?')
                      setNoAddressWarning('toast')
                    } else {
                      openDetail(product.id)
                    }
                  }}
                  className='flex items-center justify-center min-w-[110px] py-[4px] bg-main rounded-[18px] cursor-pointer -tracking-[.01em] font-bold text-white hover:bg-main hover:text-white duration-500'
                >
                  От <p className='ml-1'>{formatCurrency(branch.extended.currency.code, product.price)}</p>
                </div> :
                <ActionBlock product={product} orientationIsVertical={false}/>
              }
    
              {
                ((product?.skuGroupsValues.length || product?.optionsExists) && !!product.oldPrice) &&
                <div className='relative w-min  font-bold text-gray-50 dark:text-gray-30'>
                  <div className='flex items-center gap-1 opacity-60'>{formatCurrency(branch.extended.currency.code, product.oldPrice)}</div>
                  <div className='absolute w-full h-full left-0 top-0'>
                    <CrossedOldPriceIcon width={'100%'} height={'auto'}/>
                  </div>
                </div>
              }
            </div>
          }

        </div>
      </div>

      {!isLastProduct && <div
        className='xs:hidden bg-gradient-to-r from-gray-20/20 via-gray-20 to-gray-20/20 dark:from-gray-50/50 dark:via-gray-40 dark:to-gray-50/50 min-h-[2px]'></div>}
    </>
  )
}

function ActionBlock({ product, orientationIsVertical=false }: any) {
  const { address, branch } = useAppContext()
  const {addProductToAnimation, removeProductFromAnimation} = useAnimationProductsCartContext()
  const { cart, addProduct, getProductCart, updateProduct } = useCartContext()

  const cartProduct = useMemo(() => {
    return getProductCart(product.id, [])
  }, [cart.raw, product.id])

  if (orientationIsVertical) {
    return (
    <>
      {
        !!cartProduct?.count ?
        <Counter
          count={cartProduct.count}
          add={() => {
            if(address){
              addProductToAnimation(product.id)
            }
            
            updateProduct(cartProduct.uid, {count: cartProduct.count + 1})
          }}
          remove={() => {
            removeProductFromAnimation(product.id)
            
            updateProduct(cartProduct.uid, {count: (product.minCount && product.minCount == cartProduct.count) ? 0 : cartProduct.count - 1})
          }}
          disabledAdd={product.stockBalance && cartProduct.count >= product.stockBalance}
        /> :
        <div
          onClick={event => {
            event.stopPropagation()
            if(address){
              addProductToAnimation(product.id)
            }
            addProduct(product.id, [], product.minCount)
          }}
          className='flex items-center justify-center w-[110px] h-[35px] bg-orderbtn rounded-[18px] cursor-pointer -tracking-[.01em] font-bold text-main hover:bg-main hover:text-white duration-500'
        >
          В корзину
        </div>
      }
    </>
    )
  }

  return (
    <>
      {
        !!cartProduct?.count ?
        <Counter
          count={cartProduct.count}
          add={() => {
            if(address){
              addProductToAnimation(product.id)
            }
            
            updateProduct(cartProduct.uid, {count: cartProduct.count + 1})
          }}
          remove={() => {
            removeProductFromAnimation(product.id)
            
            updateProduct(cartProduct.uid, {count: (product.minCount && product.minCount == cartProduct.count) ? 0 : cartProduct.count - 1})
          }}
          disabledAdd={product.stockBalance && cartProduct.count >= product.stockBalance}
        /> :
        <div className={'flex flex-row items-center gap-x-3'}>
          <div
            onClick={event => {
              event.stopPropagation();
              addProduct(product.id, [], product.minCount)
              if(address){
                addProductToAnimation(product.id)
              }
            }}
            className='flex gap-1 !w-[110px] items-center justify-center bg-main rounded-[18px]  cursor-pointer -tracking-[.01em] font-bold text-white px-[15px] py-[4px]'>
            {formatCurrency(branch.extended.currency.code, product.price)}
          </div>
          {
            !!product.oldPrice &&
              <div className='relative w-min  font-bold text-gray-50 dark:text-gray-30'>
                  <div className='flex items-center gap-1 opacity-60'>{formatCurrency(branch.extended.currency.code, product.oldPrice)}</div>
                  <div className='absolute w-full h-full left-0 top-0'>
                      <CrossedOldPriceIcon width={'100%'} height={'auto'}/>
                  </div>
              </div>
          }
        </div>
      }
    </>
  )
}
