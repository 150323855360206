import React, { useEffect, useState } from 'react'

import Search from './Search'
import Categories from './Categories'
import Popular from './Popular'
import ProductCard from './ProductCard'
import { useAppContext } from '../../../../contexts/App'
import { useCategoriesHeaderContext } from '../../../../contexts/CategoriesHeaderContext'
import { useSearchContext } from '../../../../contexts/SearchContext'
import { apiClient } from '../../../../libs/api/apiClient'
import { SVGWrap } from '../../../common/util/SVGWrap'


export default function Catalog() {
  const { company, catalog, setCatalog, initialCatalog, branch } = useAppContext()
  const { searchCategoriesWrapper } = useCategoriesHeaderContext()
  const { searchQuery, searchTags, setSearchTags, noSearchResult } = useSearchContext()
  const [invalidateTagColor, setInvalidateTagColor] = useState('#4F4F4F')


  const filterCatalogByTags = (id: number) => {
    const actuallyTags = searchTags.includes(id) ? searchTags.filter((tag: number) => tag !== id) : Array.from(new Set([...searchTags, id]))
    setSearchTags(actuallyTags)
    apiClient.catalog.get(branch.id, {byTags: actuallyTags, search: searchQuery}).then(({data}: any) => {
      if (data) {
        setCatalog(data)
      }
    })
  }

  useEffect(() => {
    setInvalidateTagColor('#4F4F4F')
  }, [searchTags])

  return (
    <div className='mt-[30px] mb-[40px]'>
      <div className='relative flex items-center h-[40px]' ref={searchCategoriesWrapper}>
        <Search className='absolute z-[100]'/>
        <Categories/>
      </div>

      <div className={`${searchQuery ? 'hidden' : 'opacity-100'} duration-200`}><Popular/></div>

      <div className={`flex gap-10 items-center w-full overflow-x-auto no-scroll-bar ${searchQuery ? 'mt-[20px]' : ''}`}>
        {
          company.tags.filter((tag: any) => tag.goodsCount !== 0).map((tag: any) => (
            <div key={tag.id} className={`flex gap-3 items-center cursor-pointer w-max`} onClick={() => filterCatalogByTags(tag.id)}>
              <SVGWrap src={tag.icon} width={"16px"} height={"16px"} color={tag.color}/>
              <div className={`font-semibold whitespace-nowrap`} style={{color: searchTags.includes(tag.id)? tag.color : invalidateTagColor}}>{ tag.text }</div>
            </div>
          ))
        }
      </div>

      <div className={`min-h-[30vh] mt-[25px] 2xl:mt-[30px] ${noSearchResult && 'flex items-center justify-center'}  mt-[22px]`}>
        
        {noSearchResult && <div className='flex flex-row justify-center mt-[30px] dark:text-gray-10 xs:text-lg text-sm'>
            <p>Товары не найдены</p>
          </div>
        }

        {
          catalog.map((category: any) => {
            if (category.goods.length) {
              return (
                <div className='catalog-category mb-[30px] xs:mb-[45px] ' key={category.id} id={`category_${category.id}`}>
                  <div className={`text-2xl md:text-4xl font-bold mb-[15px] -tracking-[.01em] text-[32px]`}>
                    { category.title }
                  </div>
                  <div className={`
                  grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 md+:grid-cols-4 xs:mt-7 2xl:mt-9 
                  ${company.template.options.cartMobileOrientation === 'vertical' ? 'gap-5 md:gap-[30px]' 
                    : 'mt-2 gap-0 xs:gap-5 xl:gap-[30px]'}`}>
                    {
                      category.goods.map((productId: any, index: number) => (
                        <ProductCard  key={productId} productId={productId} isLastProduct={category.goods.length - 1 == index}/>
                      ))
                    }
                  </div>
                </div>
              )
            }
          })
        }
      </div>
    </div>
  )
}


