
import { useEffect, useState } from "react"
import LinesEllipsis from "react-lines-ellipsis"
import { useWindowResize } from "../../../hooks/useWindowResize";
import { useAnimationProductsCartContext } from "../../../contexts/AnimationCartContext";

export const AnimateAddingGoods = ({title, id}: any) => {
  const [isFade, setIsFade] = useState(false)
  const {removeProductFromAnimation} = useAnimationProductsCartContext()
  const isMobileScreen = useWindowResize({cvt: (w) => w < 768})

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFade(true)
    }, 3000);

    const timerDeleteElement = setTimeout(() => {
      setIsFade(false)
      removeProductFromAnimation(id)
    }, 3300);

    return () => {
      clearTimeout(timer);
      clearTimeout(timerDeleteElement);
    }
  }, []);

  return (
    <div className={`bg-gray-50 xs:w-full w-[220px] h-[200px] rounded-xl p-3 h-fit mb-[10px] ${isFade? 'animate-slideOut' : 'animate-slideIn'} `}>
      <p className='font-bold text-gray-10'>Добавлено:</p>
      {isMobileScreen?
        <LinesEllipsis
        text={title}
        basedOn="letters"
        maxLine={1}
        className="text-white"
        />
        :
        <p className='text-white'>{title}</p>
      }
    </div>
  )
}