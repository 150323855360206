import {useEffect, useRef, useState} from "react";

export function usePagination(
  apiCallback: (limit: number, page: number) => Promise<{ data: any[], meta: any }>,
  {
    identity = () => '',
    pageSize = 4,
    startingPage = 0,
    deps = []
  }: {
    pageSize?: number,
    startingPage?: number,
    identity?: (item: any) => string,
    deps?: any[]
  } = {}) {

  const [allData, setAllData] = useState<any[]>([])
  const [additionalData, setAdditionalData] = useState<any>({})

  const [isFetching, setFetching] = useState(false); // end page
  const [isInitialLoading, setInitialLoading] = useState(true); // start loading page

  const [nextPage, setNextPage] = useState<number>(startingPage)
  const [lastPage, setLastPage] = useState<number>(startingPage)
  const [targetCount, setTargetCount] = useState<number>(pageSize)

  useEffect(() => {
    setAllData([])
    setNextPage(startingPage)
    setLastPage(startingPage)
    setTargetCount(pageSize)
    setInitialLoading(true)
  },deps)

  const filterOutExisting = (allData: any[], newData: any[]) => {
    return allData.filter(c => {
      const idc = identity(c)
      return !newData.find((w: any) => {
        const idw = identity(w)
        return idc == idw;
      });
    })
  }

  const updateDataAction = (count: number) => {
    apiCallback(count, startingPage).then(({data, ...additional}: any) => {
      if (!data)
        return
      setAllData((allData) => [...data, ...filterOutExisting(allData, data)])
      setAdditionalData(additional)
    })
  }

  const updateItemAction = (idx: number) => {
    apiCallback(1, startingPage + idx).then(({data, ...additional}: any) => {
      if (!data)
        return
      const item = data[0]
      if (!item)
        return
      const id = identity(item)
      setAllData((allData) => allData.map((o, idx) => (identity(o) === id ? item : o)))
      setAdditionalData(additional)
    })
  }

  const loadNextPage = async () => {
    setNextPage(p => Math.max(p, nextPage + 1))
    await apiCallback(pageSize, nextPage).then(({data, ...additional}) => {
      setInitialLoading(false)
      if (!data)
        return
      setAllData(allData => [...allData, ...filterOutExisting(data, allData)])
      setLastPage(additional.meta.last_page)
      setAdditionalData(additional)
    })
  }

  useEffect(() => {
    //console.log(targetCount, allData.length, nextPage, lastPage)
    if (targetCount > allData.length && nextPage <= lastPage) {
      setFetching(true)
      loadNextPage().then()
    } else {
      setFetching(false)
    }
  }, [targetCount, allData]);

  const allDataLengthRef = useRef(0)
  allDataLengthRef.current = allData.length
  const nextPageAction = () => {
    setTargetCount(allDataLengthRef.current + pageSize - 1)
  }

  return {allData, nextPageAction, updateDataAction, updateItemAction, isFetching, isInitialLoading, additionalData}
}
