import {localStorageWrap} from "./localStorageWrap";

export const cookieWrap = {
  setCookie: (key: string, value: any) => {
    try {
      localStorageWrap.setItem(`__${key}`, value);
    } catch {return;}
  },

  getCookie: (key: string) => {
    try {
      let item = localStorageWrap.getItem(`__${key}`);
      if (typeof item === 'object')
        item = JSON.stringify(item)
      return item;
    } catch { return; }
  }
}
