import React, { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/App";
import { useAuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Preloader } from "../common/preloader";
import { useWindowResize } from "../../hooks/useWindowResize";
import { useUpdatePage } from "../../hooks/useUpdatePage";
import { NavPhone, NavSite } from "../pages/profile/NavProfile";
import avatarImg from '../../images/avatar.svg'
import CustomTooltip from "../common/tooltip";
import { ChangeBranchPopup } from "../common/popup/citites/Modals";


export function ProfileGuard({ children } : any) {
  const { user, isLoadingUser } = useAuthContext()
  const { city } = useAppContext()
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !isLoadingUser) navigate(`/${city.slug}`)
  },[user, isLoadingUser])

  if (isLoadingUser || !user) return (
    <div className={"h-[90vh] flex justify-center items-center"}>
      <Preloader countOfDot={4}/>
    </div>
  )

  return children
}

export function ProfileLayout({ children, name, phone }: any) {
  const { user, updateUser, updateMe } = useAuthContext()
  const { company } = useAppContext()
  const isMobileScreen = useWindowResize({cvt: (w) => w < 768})

  //--------updating
  useUpdatePage(
    () => {
      if (user) {
        updateMe()
        // apiClient.profileInfo.getBonusStatus().then((res)=>{
        //   if (res.data && res.status === 200) {
        //     updateUser({bonusScore: res.data.bonusScore, bonusProgramStatus: res.data.bonusProgramStatus, notEnoughStatusScoreForNextBonusStatus: res.data.notEnoughStatusScoreForNextBonusStatus})
        //   }
        // })
      }
    }, {interval: 1000 * 60 * 10}
  )

  return (
    <ProfileGuard>
      {user && (
        <div className={"flex flex-col justify-center"}>
          <NavPhone />
          <div className={"h-[72px] container flex  xs:flex-row justify-between items-center "}>
            <div className={"grow flex flex-row gap-x-3 md+:items-center"}>
              <div className="relative h-[21px] w-[21px] rounded-full bg-additional flex items-center justify-center md+:mt-0 mt-[4px]">
                <img src={avatarImg} alt='' width={"13"} height={"13"}/>
              </div>
              <div className={"md+:flex sm:gap-x-3 items-end sm:items-center sm:h-[36px]"}>
                <p className={"font-bold text-lg xs:w-full w-[200px] break-all"}>{name? name : user.name}</p>
                <p className={"whitespace-nowrap text-gray-40 xs:mt-[2px]"}>{phone? phone : user.phone}</p>
              </div>
            </div>

            {
              company.loyaltyProgram !== 'none' &&
              <div className="flex xs:flex-row xs:mt-0 sm:items-center justify-between xs:w-fit w-full gap-x-0 xs:gap-x-2  sm:gap-x-5 xs-gap-y-0">
                <div className="relative flex gap-x-2 items-center xs:text-base ">
                  <div className="xs:flex flex-row gap-x-4 hidden">
                    {user.bonusProgramStatus?.title?
                      <>
                        <p className="font-bold">Статус</p>
                        <p className="xs:mr-2 mr-1 font-bold text-main">{user.bonusProgramStatus?.title}</p>
                      </>
                      :
                      <span></span>
                    }
                  </div>  
                </div>

                <div className={"flex flex-col  items-end justify-center sm:flex-row sm:gap-x-2 sm:items-center"}>
                  <div className="flex flex-row items-center gap-x-8">
                    {user.notEnoughStatusScoreForNextBonusStatus?
                      <CustomTooltip positionClassName={'z-50 top-[200px] sm:top-[30px] md:top-[38px] right-[35px] md:right-[-25px] lg:right-[-50px] w-[230px]'}>
                        <div className="xs:hidden flex gap-x-2 mb-2">
                          <p className="font-bold text-gray-50">Статус</p>
                          <p className="xs:mr-2 mr-1 font-bold text-main">{user.bonusProgramStatus?.title}</p>
                        </div>
                        До следующего статуса осталось {Math.ceil(user.notEnoughStatusScoreForNextBonusStatus)} статусных баллов
                      </CustomTooltip>
                      :
                      <span></span>
                    } 
                    <p className={"font-bold text-main text-lg"}>{ Math.floor(user.bonusScore) }</p>
                  </div>
                  <p className={" font-bold text-main text-end"}>{isMobileScreen? 'бонусов' :'бонусных баллов'} </p>
                </div>
              </div>

            }
          </div>
          <hr className={"border-b-[1px] border-gray-20 dark:border-gray-50"} />
          <div className={"mt-11 mb-[20vh]"}>
            <div className={"container flex justify-between flex-row"}>
              {children}
              <NavSite />
            </div>

          </div>

          <ChangeBranchPopup/>
        </div>
      )}
    </ProfileGuard>
  );
}
