import { apiClient } from '../libs/api/apiClient'; 
import React, { createContext, useContext, useEffect, useState, } from 'react'
import { useAppContext } from './App';
import useDebounce from '../hooks/useDebounce';


interface ContextType {
  activeSearch: boolean;
  setActiveSearch: (v: boolean) => void;
  searchQuery: string;
  setSearchQuery: (v: string) => void;
  searchTags: number[];
  setSearchTags: (v: number[]) => void;
  noSearchResult: boolean;
}

const SearchContext = createContext<ContextType>({
  activeSearch: false,
  setActiveSearch: () => {},
  searchQuery: '',
  setSearchQuery: () => {},
  searchTags: [],
  setSearchTags: () => {},
  noSearchResult: false,
})

export default function SearchContextWrapper({children}: any) {
  const { branch, setCatalog } = useAppContext()
  const [activeSearch, setActiveSearch] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchTags, setSearchTags] = useState<number[]>([])
  const [noSearchResult, setNoSearchResult] = useState(false)

  const debouncedSearch = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (activeSearch) {
      apiClient.catalog.get(branch.id, {search: searchQuery.trim(), byTags: searchTags}).then(({data}) => {
        setCatalog(data)

        if (data.reduce((productsCount: number, category: any) => productsCount + category.goods.length, 0)) {
          setNoSearchResult(false)
        } else {
          setNoSearchResult(true)
        }
      })
    }
  }, [debouncedSearch])

  useEffect(() => {
    if (!activeSearch) {
      setNoSearchResult(false)
    }
  }, [activeSearch])

  return (
    <SearchContext.Provider value={{ activeSearch, setActiveSearch, searchQuery, setSearchQuery, searchTags, setSearchTags, noSearchResult }}>
      {children}
    </SearchContext.Provider>
  )
}

export function useSearchContext() {
  return useContext(SearchContext)
}
