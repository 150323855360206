import React, { createContext, useState } from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { localStorageWrap } from './../libs/helpers/localStorageWrap';
import { apiClient } from './../libs/api/apiClient';

interface AuthContextType {
  user: any;
  isLoadingUser: boolean;
  logout: () => void;
  updateUser: (data: any) => any;
  updateMe: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isLoadingUser: false,
  logout: () => {},
  updateUser: () => ({}),
  updateMe: async () => {},
})

type Props = {
  children: React.ReactNode,
  user: any,
  setUser: (value: any) => void,
}

let globalVkInitAuthPromise = Promise.resolve(true)

export function AuthContextWrapper({ 
  children,
  user,
  setUser,
}: Props) {
  const [isLoadingUser, setLoadingUser] = useState<boolean>(true)

  function logout() {
    setLoadingUser(false);
    setUser(null);
    apiClient.auth.logout();
    localStorageWrap.removeItem('user');
  }

  function updateUser(newData: any) {
    const updated = {...(user ? user : {}), ...newData}
    setUser(updated)
    localStorageWrap.setItem('user', JSON.stringify(updated))
    return updated
  }
  
  function updateMe() {
    if (user) {
      setLoadingUser(true)
      return apiClient.profileInfo.user().then(data => {
        updateUser(data)
        setLoadingUser(false)
      }).catch(e => {
        setUser(null)
        setLoadingUser(false)
      })
    }
    return Promise.resolve()
  }

  function fetchUserOnFirstLoad() {
    const storedUser = JSON.parse(localStorageWrap.getItem('user') || 'null');
    if (storedUser) {
      if (localStorageWrap.getItem('vkUserLoaded') === 'true') {
        // login was already performed
        setUser(storedUser);
        setLoadingUser(false);
        return;
      }
      // user is in local storage - update data from server
      apiClient.profileInfo.user().then(({status, ...data}) => {
        const newUser = {...storedUser, ...data}
        setUser(newUser)
        localStorageWrap.setItem('user', JSON.stringify(newUser))
        setLoadingUser(false);
      }).catch(e => {
        setUser(null);
        setLoadingUser(false);
      });
    } else {
      // user is not in local storage - clear it and stop loading
      setUser(null);
      setLoadingUser(false);
    }
  }

  // first load
  useEffect(() => {
    if (typeof window !== 'undefined') {
      globalVkInitAuthPromise.then(fetchUserOnFirstLoad)
    }
  }, [])

  return (
    <AuthContext.Provider value={{user, logout, updateUser, updateMe, isLoadingUser}}>
      { children }
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  return useContext(AuthContext)
}

export function setVkInitAuthPromise(p : Promise<boolean>) {
  globalVkInitAuthPromise = p
}
