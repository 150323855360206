import { useAppContext } from "../../../../../contexts/App"
import { SVGWrap } from "../../../../common/util/SVGWrap"


export default function Tags({ product }: any) {
  const { company } = useAppContext()

  return (
    <>
      {
        !!product?.tags?.length &&
        <div className={'flex items-center gap-4 md:mt-[5px] flex-wrap'}>
          {
            company.tags.filter((tag: any) => product.tags.includes(tag.id)).map((tag: any) => (
              <div key={tag.id} className='flex w-max gap-3 items-center cursor-pointer '>
                <SVGWrap width={"13px"} height={"13px"} src={tag.icon} color={tag.color}/>
                <div className='font-semibold'style={{color: tag.color}}>{ tag.text }</div>
              </div>
            ))
          }
        </div>
      }
    </>
  )
}
