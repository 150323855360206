import CitiesSelector from "../components/common/popup/citites/CitiesSelector";
import { useAppContext } from "../contexts/App";
import { cookieWrap } from "../libs/helpers/cookieWrap"; 

export default function Home() {
  const { company } = useAppContext()

  return (
    <>
      <div className="relative min-h-screen">
        <img className="max-w-[470px] w-full m-auto pt-10" src={company.template.logo} alt="" />

        <div className="absolute w-screen h-screen left-0 top-0 bg-[#bdbdbd9e] flex items-center justify-center">

          <CitiesSelector
            isActive={true}
            setActive={() => {}}
            movePopup={false}
          />
        </div>
      </div>
    </>
  )
}

// Home.cityInBasePage = async (ctx: any) => {
//   const { req, res } = ctx 

//   if (res) {
//     let city = cookieWrap.getCookie('city', { req, res });
//     if (city) {
//       return { cityInBasePage: city }
//     }
//   } else {
//     let city = cookieWrap.getCookie('city');
//     if (city) {
//       return { cityInBasePage: city }
//     }
//   }

//   return { cityInBasePage: null }
// }
