import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react'
import { useAppContext } from '../../../contexts/App';
import { timeoutUntilSuccess } from '../../../libs/helpers/timeoutUntilSuccess';
import { useWindowResize } from '../../../hooks/useWindowResize';
import ArrowIcon from '../../icons/ArrowIcon';

interface Props {
  statuses: any;
  orderCreatedAt: string;
  className?: string;
}

export default function StatusBar({ statuses, orderCreatedAt, className='' }: Props) {
  const {company} = useAppContext()
  const statusBlock = useRef(null)
  const wrapper = useRef<any>(null)
  const [allStatuses, setAllStatuses] = useState<any>({})
  const [listenStatuses, setListenStatuses] = useState<any>([])

  useEffect(() => {
    return timeoutUntilSuccess(() => {
      if (wrapper.current && wrapper.current.id === `statuses-wrapper-${listenStatuses.length}`) {
        wrapper.current.scrollTo({left: 100000, behavior: 'smooth'})
        return true
      }
      return false
    })
  }, [listenStatuses.length])

  useEffect(() => {
    setAllStatuses(company.statuses.reduce((acc: any, s: any) => ({...acc, [s.code]: s}), {}))
  }, [company])

  useEffect(() => {
    if (statuses?.length) {
      setListenStatuses(statuses.filter((s: any) => allStatuses[s.status]?.listen))
    }
  }, [statuses, allStatuses])

  // это для возможности перетаскивать блок для скролла на компьютерных разрешениях 
  // (!!! при попытке таскать с включенным девтулом когда выбираем режим отзывч дизайна и вместо указателя мышки кружок -  работать будет только прри разр ниже 769...>
  //  >... так как это воспринимается как тап на телефоне плюс ниже прописано ограничение на мобильный скрин)
  let mouseDown = false;
  let startX: any, scrollLeft: any;

  const startDragging = (e: any) => {
    mouseDown = true;
    startX = e.pageX - wrapper.current.offsetLeft;
    scrollLeft = wrapper.current.scrollLeft;
  }

  const stopDragging = () => {
    mouseDown = false;
  }

  const move = (e: any) => {
    e.preventDefault();
    if(!mouseDown) return
    const x = e.pageX - wrapper.current.offsetLeft;
    const scroll = x - startX;
    wrapper.current.scrollLeft = scrollLeft - scroll;
  }

  const isMobileScreen = useWindowResize({cvt: (w) => w <= 768})
  
  if (listenStatuses.length && ['cancelled', 'completed'].includes(listenStatuses?.at(-1)?.status)) {
    return (
      <div className=''>
        {!isMobileScreen?
         <div 
          onMouseMove={(e) => {
            move(e)
          }}
          onMouseLeave={() => {
         stopDragging()
          }}
          onMouseUp={() => {
          stopDragging()
          }}
          onMouseDown={(e) => {
             startDragging(e)
          }}
          ref={wrapper}
          id={`statuses-wrapper-${listenStatuses.length}`}
          className={`relative w-full ${className} overflow-x-auto no-scroll-bar pl-[25px]`}
        >
         
         <div className='relative z-[5]'>
 
           <div ref={statusBlock} className={`mx-[-53px] flex justify-between text-sm font-bold ${listenStatuses.at(-1).status === 'cancelled' ? 'text-yellow' : 'text-main'}`}>
             <div style={{userSelect: 'none', touchAction: 'none'}} className={`min-w-[106px] px-[15px] flex flex-col items-center`}>
               <div >{allStatuses['accepted']?.title}</div>
               <div className={`mb-[10px] text-xs text-gray-30`}>{ moment(orderCreatedAt).format('HH:mm') }</div>
               <div className={`w-[15px] h-[15px] rounded-full bg-gray-10 border-[3px] ${listenStatuses.at(-1).status === 'cancelled' ? 'border-yellow' : 'border-main'}`}></div>
             </div>
 
             {
               listenStatuses?.map((status: {status: string, createdAt: string}) => (
                 <div key={status.createdAt} style={{userSelect: 'none', touchAction: 'none'}} className={`min-w-[106px] px-[15px] flex flex-col  items-center`}>
                   <div>{allStatuses[status.status]?.title}</div>
                   <div className={`mb-[10px] text-xs text-gray-30`}>{ moment(status.createdAt).format('HH:mm') }</div>
                   <div className={`w-[15px] h-[15px] rounded-full bg-gray-10 border-[3px] ${listenStatuses.at(-1).status === 'cancelled' ? 'border-yellow' : 'border-main'}`}></div>
                 </div>
               ))
             }
           </div>
 
         </div>
 
         <div className={`absolute bottom-[5px] w-full h-[5px] rounded-full ${listenStatuses.at(-1).status === 'cancelled' ? 'bg-yellow' : 'bg-main'}`}  style={{width: `max(97%, ${106 * listenStatuses.length}px)`}}></div>
       </div>
        :
        <div 
          ref={wrapper}
          id={`statuses-wrapper-${listenStatuses.length}`}
          // style={{scrollBehavior: 'smooth'}}  
          className={` relative w-full ${className} overflow-x-auto no-scroll-bar pl-[25px]`}
        >
        
          <div className='relative z-[5]'>

            <div ref={statusBlock} className={`mx-[-53px] flex justify-between text-sm font-bold ${listenStatuses.at(-1).status === 'cancelled' ? 'text-yellow' : 'text-main'}`}>
              <div  className={`min-w-[106px] px-[15px] flex flex-col items-center`}>
                <div >{allStatuses['accepted']?.title}</div>
                <div className={`mb-[10px] text-xs text-gray-30`}>{ moment(orderCreatedAt).format('HH:mm') }</div>
                <div className={`w-[15px] h-[15px] rounded-full bg-gray-10 border-[3px] ${listenStatuses.at(-1).status === 'cancelled' ? 'border-yellow' : 'border-main'}`}></div>
              </div>

              {
                listenStatuses?.map((status: {status: string, createdAt: string}) => (
                  <div key={status.createdAt}  className={`min-w-[106px] px-[15px] flex flex-col  items-center`}>
                    <div>{allStatuses[status.status]?.title}</div>
                    <div className={`mb-[10px] text-xs text-gray-30`}>{ moment(status.createdAt).format('HH:mm') }</div>
                    <div className={`w-[15px] h-[15px] rounded-full bg-gray-10 border-[3px] ${listenStatuses.at(-1).status === 'cancelled' ? 'border-yellow' : 'border-main'}`}></div>
                  </div>
                ))
              }
            </div>

          </div>

          <div className={`absolute bottom-[5px] w-full h-[5px] rounded-full ${listenStatuses.at(-1).status === 'cancelled' ? 'bg-yellow' : 'bg-main'}`}  style={{width: `max(100%, ${106 * listenStatuses.length}px)`}}></div>
        </div>
        }
      </div>
    )
  }

  return (
    <div ref={wrapper} id={`statuses-wrapper-${listenStatuses.length}`} className={`relative  ${className} overflow-y-hidden overflow-x-auto no-scroll-bar pb-1`}>
      <div className='relative z-[5] flex justify-between text-sm text-main font-bold'>
        <div className='min-w-[53px] w-[53px] block'/>

        <div ref={statusBlock} className={`min-w-[106px] px-[15px] flex flex-col items-center`}>
          <div>{allStatuses['accepted']?.title}</div>
          <div className={`mb-[10px] text-xs text-gray-30`}>{ moment(orderCreatedAt).format('HH:mm') }</div>
          <div className='w-[15px] h-[15px] rounded-full bg-gray-10 border-[3px] border-main'></div>
        </div>

        {
          listenStatuses?.map((status: {status: string, createdAt: string}) => (
            <div key={status.createdAt} className={`min-w-[106px] px-[15px] flex flex-col items-center`}>
              <div>{allStatuses[status.status]?.title}</div>
              <div className={`mb-[10px] text-xs text-gray-30`}>{ moment(status.createdAt).format('HH:mm') }</div>
              <div className='w-[15px] h-[15px] rounded-full bg-gray-10 border-[3px] border-main'></div>
            </div>
          ))
        }

        <div className='min-w-[53px] w-[53px] block'/>
      </div>
      <div className='relative' style={{width: `max(100%, ${106 * (listenStatuses.length + 2)}px)`}}>
        <div 
          className='absolute bottom-[5px] h-[5px] rounded-full bg-main block' 
          style={{width: (100 - 100 / (listenStatuses.length + 2)) + '%'}} 
        />
        <div className='absolute bottom-[5px] w-full h-[5px] rounded-full bg-main opacity-25' style={{width: `calc(100% - 7.5px)`}}></div>
        <ArrowIcon className="absolute bottom-[-4px] right-0 w-[15px] h-[23px] opacity-25" colorClassName="fill-main"/>
      </div>
    </div>
  )
}
