import productImgStub from '../../../images/productImgStub.jpg'
import { resizeImage } from '../../../libs/helpers/resizeImage'
import GiftIcon from '../../icons/GiftIcon'

export default function GiftProduct({ productData }: any) {
  return (
    <div key={productData.uid} className='py-9 md:p-[15px] border-b-[1px] border-gray-20 md:border-none md:bg-white dark:md:bg-gray-50 md:rounded-[29px] md:shadow-lg'>
      <div className="flex gap-[30px]">
        {
          productData?.image ? 
          <div className='rounded-[17px] overflow-hidden h-[125px] min-w-[125px]'>
            <picture className={'h-[125px]'}>
              <source className={'h-[125px]'} type="image/webp" srcSet={`${resizeImage(productData.image, '125', 'webp')} 1x, ${resizeImage(productData.image, '300', 'webp')} 2x, ${resizeImage(productData.image, '400', 'webp')}3x`}/>    
              <source className={'h-[125px]'} type="image/jpeg" srcSet={`${resizeImage(productData.image, '125', 'jpg')} 1x, ${resizeImage(productData.image, '300', 'jpg')} 2x, ${resizeImage(productData.image, '400', 'jpg')} 3x`}/>
              <img 
                className={'h-[125px]'}
                src={resizeImage(productData.image, '125', 'jpg')} 
                srcSet={`${resizeImage(productData.image, '125', 'jpg')} 1x, ${resizeImage(productData.image, '300', 'jpg')} 2x, ${resizeImage(productData.image, '400', 'jpg')} 3x`}
                alt=""
              />
            </picture>
          </div> :
          <img
            className='w-[125px] h-[125px] rounded-[17px]'
            src={productImgStub} 
            alt=""
          />
        }

        <div className='flex flex-col grow'>
          <div className='flex md:items-center justify-between'>
            <div className='flex items-center flex-wrap gap-x-4 md:text-[20px] font-bold -tracking-[.01em]'>
              <span>{ productData?.title }</span> 

              {/* { 
                productData?.skuGroupsValues.map((sky: any) => (
                  <span className='text-main' key={sky.valueId}>{ sky.title }</span>
                ))
              } */}

            </div>
            
          </div>

          {
            productData?.weight || productData?.size ? 
            <div className={`flex gap-2 -tracking-[.01em] font-bold text-gray-30`}>
              { productData?.weight && <div>{ productData?.weight } г.</div> }
              { productData?.size && <div>{ productData?.size } { productData?.measure_unit }</div> }
            </div> :
            <div className={`h-[24px]`}></div>
          }

          <div className={`text-sm grow mt-[11px] -tracking-[.01em]  leading-[17px] text-gray-50 dark:text-gray-10`}>{ productData?.shortDescription }</div>
          
          <div className='flex justify-between items-center'>
            <div className="flex items-center  mt-3 gap-x-2 text-main">
              <div className='flex items-center justify-center h-[35px] w-[35px] bg-main rounded-full'>
                <GiftIcon/>
              </div>
              <h3>{productData.comment ? productData.comment : 'Подарок'}</h3>
            </div>

            {
              (productData?.count && productData.count > 1) &&
              <div className='text-xl text-main font-bold'>x {productData.count}</div>
            }
          </div>
          
        </div>
      </div>
      
    </div>
  )
}