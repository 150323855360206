import React, {
  ClipboardEventHandler,
  KeyboardEventHandler,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState
} from "react";
import { Preloader } from "../../preloader";

export interface ITextInput {
  type?: string,
  value: any,
  disabled?: boolean,
  autoFocus?: boolean,
  getAuxElement?: (auxState: any, setAuxState: (s: any) => void, setDisabled: (s: boolean) => void) => ReactElement
  onEnterPressed?: (e: any) => void | boolean,
  onBlur?: (e: any) => any,
  onClick?: (e: any) => any,
  onFocus?: (e: any) => any,
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>,
  onPaste?: ClipboardEventHandler<HTMLInputElement>,
  onChange: (value: string) => void,
  onFormat?: (value: string) => string | null,
  placeholderInput?: string,
  children?: any,
  className?: string,
  classNameInput?: string,
  maxLength?: number,
  key?: any,
  inputRef?: MutableRefObject<HTMLInputElement | null>
  name?: string,
  required?: boolean
  errors?: any,
  max?:any,
  min?: any;
  errorLabel?: boolean,
  allowEdit?: boolean,
  inputmode?: string,
}

export function TextInput({
  type = 'text',
  disabled = false,
  autoFocus = false,
  className = '',
  classNameInput = '',
  placeholderInput = '',
  value,
  children,
  getAuxElement,
  onChange,
  onKeyDown,
  onEnterPressed,
  maxLength,
  onFormat,
  inputRef,
  name = '',
  errors = {},
  errorLabel = true,
  allowEdit = true,
  ...props
}: ITextInput) {
  const [auxState, setAuxState] = useState<any>(undefined)
  const [isDisabled, setDisabled] = useState<boolean>(disabled)
  
  useEffect(() => {
    setDisabled(disabled)
  }, [disabled])

  const error = errors[name]; 

  useEffect(() => {
    delete errors[name];
  }, [value]);

  return (
    <>
      <div className={`flex flex-row items-center justify-between text-sm px-[22px] py-2 rounded-[10px] bg-gray-20 dark:bg-gray-40 ${error && 'border-[1px] border-yellow'} ${className}`}>
        <input
          autoFocus={autoFocus}
          name={name}
          ref={inputRef}
          type={type}
          placeholder={placeholderInput}
          value={value}
          maxLength={maxLength}
          onKeyDown={e => {
            if (e.key === 'Enter' && onEnterPressed && onEnterPressed(e)) return;
            return onKeyDown && onKeyDown(e);
          }}
          onChange={e => {
            let val: string | null = e.target.value || '';
            if (onFormat) val = onFormat(val);
            onChange(val != null ? val : value);
          }}
          className={`w-full h-5 bg-transparent placeholder-gray-30 dark:placeholder-gray-20 outline-none disabled:text-gray-40 dark:disabled:text-gray-20 ${classNameInput}`} {...props}
          disabled={isDisabled}
          {...props}
        />
        {getAuxElement && allowEdit && <div
            className={`w-1/6 flex justify-end text-main cursor-pointer ml-4`}>{getAuxElement(auxState, setAuxState, setDisabled)}</div>}
            </div>
        {error && errorLabel && (<span className={'text-yellow text-xs font-medium'}>{error}</span>)}
    </>
  )
}

export function TextInputWithSave({type, onBlur, onClick, onEnterPressed, disabled, onSave, inputRef, value, allowEdit = true, classNameInput = '', ...props}: ITextInput & { onSave: () => boolean | void | Promise<boolean>, allowEdit?: boolean }) {
  const [disabledInternal, setDisabledInternal] = useState(true);
  const [edit, setEdit] = useState(false);

  const [showPreloader, setShowPreloader] = useState(false);
  const [lastValue, setLastValue] = useState(null)

  const saveAndExit = (e : any) => {
    if (lastValue === value) {
      ref.current?.blur();
      setDisabledInternal(true);
      setEdit(false);
      setShowPreloader(true);
      setTimeout(() => setShowPreloader(false), 200)
      return
    }
    const result = onSave();
    setShowPreloader(true);
    Promise.resolve(result).then(success => {
      setShowPreloader(false);
      if (success) {
        ref.current?.blur();
        setDisabledInternal(true);
        setEdit(false);
      }
      else {
        setDisabledInternal(false);
        setEdit(true);
      }
    })
  }

  const fallbackRef = useRef<any>(null);
  const ref = inputRef || fallbackRef;
  return <TextInput
    {...props}
    value={value}
    type={type}
    inputRef={ref}
    onClick={(e) => {
      if (onClick && onClick(e))
        return true;
      if (!edit && allowEdit) {
        setDisabledInternal(false);
        setEdit(true);
        setLastValue(value)
        setTimeout(() => ref.current?.focus(), 150);
      }
      if (type !== 'date') e.preventDefault();
    }}
    onBlur={e => {
      if (onBlur && onBlur(e))
        return true;
      return saveAndExit(e);
    }}
    onEnterPressed={e => {
      if (onEnterPressed && onEnterPressed(e))
        return true;
      return saveAndExit(e);
    }}
    getAuxElement={() => {
      if (!allowEdit)
        return <></>
      if (showPreloader)
        return <Preloader countOfDot={4} size={'10px'}/>;
      return <span onClick={e => {
        if (disabled) return;
        let success = true;
        if (edit) {
          saveAndExit(e);
        } else {
          setEdit(true);
          setLastValue(value);
          setDisabledInternal(false);
          setShowPreloader(false);
          setTimeout(() => ref.current?.focus(), 150);
        }
      }}>{edit ? 'Сохранить' : 'Изменить'}</span>
    }}
    disabled={!allowEdit}
    classNameInput={`${disabledInternal || disabled ? `text-gray-40 dark:text-gray-20 ${!allowEdit ? 'cursor-default' : 'cursor-pointer'}` : ''} ${classNameInput}`}
  />
}


interface ITextInputCode {
  codeSize?: number,
  charRegex?: RegExp,
  statusCode: string,
  failText?: string
}

export function TextInputCode({
  codeSize = 4,
  charRegex = /\d/,
  value,
  onChange,
  statusCode,
  autoFocus = true,
  failText = 'Неверный код'
}: (ITextInputCode & ITextInput)) {
  const refs = useRef<MutableRefObject<HTMLInputElement | null>[]>([]).current;
  while (refs.length < codeSize)
    refs.push({current: null})

  const valuesFromString = (val: string | undefined) => {
    const charArr = (val || '').split('').filter(c => !!c.match(charRegex));
    return Array.from(Array(codeSize).keys()).map(i => (charArr[i] || ''))
  }
  const stringFromValues = (vals: string[]) => vals.join('')
  const [values, setValues] = useState(valuesFromString(value));

  useEffect(() => {
    if (value !== stringFromValues(values)) {
      setValues(valuesFromString(value))
    }
    // not passing values is intended
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const setValueAt = (index: number, val: string) => {
    let newVals = values.map((v, i) => i === index ? val : v)
    newVals = valuesFromString(stringFromValues(newVals))
    setValues(newVals);
    onChange(stringFromValues(newVals))
  }

  const tmpDataRef = useRef<any>({}).current;
  return (
    <div className={"flex flex-col items-center justify-center gap-y-3"}>
      <div className={"w-full flex flex-row justify-center gap-x-2 xxs:gap-x-5 "}>
        {
          Array.from(Array(codeSize).keys()).map((index) => {
            return <TextInput
              inputRef={refs[index]}
              type={'number'}
              key={index}
              value={values[index]}
              autoFocus={autoFocus && index === 0}
              onChange={() => {
              }}
              onFocus={() => {
                if (value.length < index && !tmpDataRef.preventFocusReset) {
                  refs[value.length]?.current?.focus();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  if (statusCode !== 'wait') {
                    if (values[index] === '')
                      refs[index - 1]?.current?.focus();
                    setValueAt(index, '')
                  }
                  e.preventDefault();
                } else if (e.key === 'ArrowLeft') {
                  refs[index - 1]?.current?.focus();
                } else if (e.key === 'ArrowRight') {
                  refs[index + 1]?.current?.focus();
                } else if (e.key.length === 1) {
                  if (e.key.match(charRegex)) {
                    if (statusCode !== 'wait') {
                      setValueAt(index, e.key)
                      tmpDataRef.preventFocusReset = true
                      refs[Math.min(index + 1, codeSize - 1)]?.current?.focus();
                      tmpDataRef.preventFocusReset = false;
                    }
                  }
                  if (!e.ctrlKey && String.fromCharCode(e.keyCode) !== 'v')
                    e.preventDefault();
                }
              }}
              onPaste={(e) => {
                let clipboardData = e.clipboardData?.getData('Text')
                if (clipboardData && statusCode !== 'wait') {
                  const vals = valuesFromString(clipboardData);
                  onChange(stringFromValues(vals))
                  setValues(vals);
                }
                e.preventDefault();
              }}
              className={`w-[50px] xs:w-[64px] h-[65px] flex !justify-center px-2 bg-gray-20 rounded-xl  ${statusCode === 'wait' || statusCode === 'none' || statusCode === 'ban' ? 'border-[2px] border-gray-20' : statusCode == 'success' ? 'border-[2px] border-success bg-transparent' : 'border-[2px] border-main bg-transparent'}`}
              classNameInput={`!w-[10px] h-full text-center text-xl ${statusCode === 'wait' || statusCode === 'none' ? 'text-black' : statusCode == 'success' ? 'text-success' : 'text-main'}`}
            />
          })
        }
      </div>
      <p className={`${statusCode === 'fail' ? 'opacity-1' : 'opacity-0'} text-main text-center  max-w-[580px] break-words`}>{failText}</p>
    </div>
  )
}
