import React, {createContext, useContext, useEffect, useState} from "react";
import { getRGBAColor } from '../libs/getColor';
import getBranch from "../libs/ssrStartRequst/getBranch";
import getAddress from "../libs/ssrStartRequst/getAddress";
import getAllProducts from "../libs/ssrStartRequst/getAllProducts";
import getStories from "../libs/ssrStartRequst/getStories";
import getCompany from "../libs/ssrStartRequst/getCompany";
import getCity from "../libs/ssrStartRequst/getCity";
import {cookieWrap} from "../libs/helpers/cookieWrap";
import { Preloader } from "../components/common/preloader"; 
import {apiClient, getCurrentDomain, setVkMiniAppDomain} from "../libs/api/apiClient"
import Home from "../pages";
import bridge from "@vkontakte/vk-bridge";
import { localStorageWrap } from "../libs/helpers/localStorageWrap";
import { useNavigate } from "react-router-dom";
import NotFoundInVkGroup from "../components/common/VkPageNotFoundInVkGroup";
import { setVkInitAuthPromise } from "./AuthContext";

interface AppContextType {
  company: any;
  city: any;
  branch: any;
  autoSelectBranch: boolean;
  updateCurrentBranch: () => void;
  address: any;
  catalog: any;
  allProducts: any;
  stories: any,
  setAddress: (value: any) => void;
  setCatalog: (value: any) => void;
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  reloadWithoutReload: (showPreloader?: boolean) => Promise<void>;
  vkCommunityMessagesAllowed: boolean;
  setVkCommunityMessagesAllowed: (value: boolean) => void;
  vkLaunchParams: any;
  initialCatalog: any;
  notFound: boolean;
}

const AppContext = createContext<AppContextType>({
  company: null,
  city: null,
  branch: null,
  updateCurrentBranch: () => {},
  autoSelectBranch: false,
  address: null,
  catalog: null,
  allProducts: null,
  stories: null,
  setAddress: () => {},
  setCatalog: () => {},
  isOpen: true,
  setIsOpen: () => {},
  reloadWithoutReload: async (showPreloader?: boolean) => {},
  vkCommunityMessagesAllowed: false,
  setVkCommunityMessagesAllowed: () => {},
  vkLaunchParams: null as any,
  initialCatalog: null,
  notFound: false,
});

type Props = {
  children: React.ReactNode,
}

export async function getAllInitial(host: string, asPath: string, pageProps: any) {
  let logs: any = {host}
  const company = await getCompany(host)
  logs = {...logs, getCompany: company}

  if (company.requestStatus !== 200) return {company: null}
  const { city, redirect, notFound }: any = await getCity(pageProps, company, asPath)
  if (redirect && typeof window === 'undefined') {
    logs = {...logs, redirect}
    return { redirect, logs }
  } else if (notFound) {
    logs = {...logs, redirect, company, notFound}
    return { redirect, company, notFound, logs }
  } else if (city) {
    const {branch, autoSelectBranch}: any = await getBranch(host, city)

    const [{address}, allProducts, stories] = await Promise.all([
      getAddress(city, branch),
      getAllProducts(host, branch),
      getStories(host, branch),
    ])

    logs = {...logs, 
      redirect,
      company,
      city,
      notFound,
      branch,
      autoSelectBranch,
      address,
      allProducts,
      stories
    }

    return {
      redirect,
      company,
      city,
      notFound,
      branch,
      autoSelectBranch,
      address,
      allProducts,
      stories,
      logs
    };
  } else {
    logs = {...logs, redirect, company}
    return { redirect, company, logs }
  }
}

export function AppContextWrapper(props: Props) {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [company, setCompany] = useState<any>()
  const [city, setCity] = useState<any>()
  const [branch, setBranch] = useState<any>()
  const [autoSelectBranch, setAutoSelectBranch] = useState<any>()
  const [address, setAddress] = useState<any>()
  const [catalog, setCatalog] = useState([])
  const [allProducts, setAllProducts] = useState<any>()
  const [stories, setStories] = useState<any>()
  const [isOpen, setIsOpen] = useState(true)
  const [initialCatalog, setInitialCatalog] = useState<any>(null)
  const [vkCommunityMessagesAllowed, setVkCommunityMessagesAllowed] = useState<any>();
  const [vkLaunchParams, setVkLaunchParams] = useState<any>();
  const [getAllInitialLogs, setGetAllInitialLogs] = useState<any>()
  const [notFound, setNotFound] = useState(false)

  // Инициализация приложения
  useEffect(() => {
    (async () => {
      //Логика для загрузки вк мини апп
      let vkAuthPromise = null;
      let vkLaunchParams: any = null;
      
      let initPromise = bridge.send("VKWebAppInit")
      let launchParamsPromise = bridge.send("VKWebAppGetLaunchParams")
      let userInfo = await bridge.send('VKWebAppGetUserInfo')
      let result = await initPromise
      vkLaunchParams = await launchParamsPromise
      setVkMiniAppDomain(vkLaunchParams.vk_app_id, vkLaunchParams.vk_group_id)
      console.log("vk mini app init completed", result, vkLaunchParams)
      vkAuthPromise = apiClient.auth.loginVkMiniApp({...vkLaunchParams, name: `${userInfo.first_name} ${userInfo.last_name}`});
      

      // загружаем сохраненные данные
      await localStorageWrap.init();

      // логин пользователя в вк (асинхронный, не блокирует загрузку прилки)
      if (vkAuthPromise) {
        setVkInitAuthPromise((async () => {
          let res: any = {}
          try {
            res = await vkAuthPromise;
          } catch (e) { console.error('failed startup vk auth', e); }
          if (res.user && res.token) {
            localStorageWrap.setItem('user', JSON.stringify({...res.user, token: res.token}))
            localStorageWrap.setItem('vkUserLoaded', true);
            console.log("vk login successful")
            return true
          } else {
            localStorageWrap.setItem('vkUserLoaded', false);
            console.log("vk login failed", res)
            return false
          }
        })())
      } else {
        localStorageWrap.setItem('vkUserLoaded', false);
      }

      if (vkLaunchParams && vkLaunchParams.vk_group_id) {
        (async () => {
          let vkCommunityMessagesAllowedOnStart = false;
          try {
            let res = await bridge.send('VKWebAppAllowMessagesFromGroup', {group_id: vkLaunchParams.vk_group_id})
            vkCommunityMessagesAllowedOnStart = res.result;
          } catch(e) {}
          setVkCommunityMessagesAllowed(vkCommunityMessagesAllowedOnStart)
        })().then();
      }

      setVkLaunchParams(vkLaunchParams)


    })().then(() => {
      getAllInitial(getCurrentDomain(), '', {
        cityInBasePage: cookieWrap.getCookie('city'),
        alwaysLoadCity: true
      }).then(({
        redirect,
        company,
        city,
        notFound,
        branch,
        autoSelectBranch,
        address,
        allProducts,
        stories,
        logs
      }) => {
        setNotFound(notFound)
        setCompany(company)
        setCity(city)
        console.log('loaded city', city, {notFound})
        setBranch(branch)
        setAutoSelectBranch(autoSelectBranch)
        setAddress(address)
        setAllProducts(allProducts)
        setStories(stories)
        setGetAllInitialLogs(logs)
        if (redirect) {
          redirect = `/${redirect}`
          console.log('redirecting to', redirect, 'from', window.location)
          cookieWrap.setCookie('city', redirect)
          navigate(redirect)
          reloadWithoutReload(false, company).then(() => setLoaded(true))
          setLoaded(true)
        } else if (notFound) {
          console.log('redirecting to city selector')
          navigate('/')
          setLoaded(true)
        } else {
          console.log(`loaded to city ${city?.slug} and branch ${branch?.id}`, {city, branch})
          setLoaded(true)
        }
      })
    });
  }, [])

  useEffect(() => {
    if (!initialCatalog || initialCatalog?.length === 0) {
      setInitialCatalog(catalog)
    }
  }, [catalog])

  const updateCurrentBranch = async () => {
    if (branch) {
      const { data } = await apiClient.branch.get(branch?.id)
      if (data) setBranch(data)
    }
  }

  async function reloadWithoutReload(showPreloader: boolean = false, newCompany: any = company) {
    if (showPreloader) setLoaded(false);
    if (newCompany == null) return
    const {city}: any = await getCity({
      cityInBasePage: cookieWrap.getCookie('city'),
      alwaysLoadCity: true
    }, newCompany, '');
    const  {branch, autoSelectBranch}: any = await getBranch(getCurrentDomain(), city);
    const [{address}, allProducts, stories] = await Promise.all([
      getAddress(city, branch),
      getAllProducts(getCurrentDomain(), branch),
      getStories(getCurrentDomain(), branch),
    ])
    setCity(city)
    setBranch(branch)
    setAutoSelectBranch(autoSelectBranch)
    setAddress(address)
    setAllProducts(allProducts)
    setStories(stories)
    if (showPreloader) setLoaded(true);
  }

  useEffect(() => {
    console.log(getAllInitialLogs)
  }, [getAllInitialLogs])

  if (!loaded)
    return <div className={"flex h-screen justify-center items-center borer-2 border-red-500"}>
      <Preloader countOfDot={4} color={'gray-30'}/>
    </div>
  if (!company)
    return <NotFoundInVkGroup vkLaunchParams={vkLaunchParams}/>

  return (
    <>
      <AppContext.Provider
        value={{
          company,
          city,
          branch, autoSelectBranch, updateCurrentBranch,
          address, setAddress,
          initialCatalog, catalog, setCatalog,
          allProducts,
          stories,
          isOpen, setIsOpen,
          reloadWithoutReload,
          vkLaunchParams,
          vkCommunityMessagesAllowed, setVkCommunityMessagesAllowed,
          notFound
        }}
      >
        <style>:root {`{${getRGBAColor(company.template.options.mainColor, 'main')} ${getRGBAColor(company.template.options.additionalColor, 'additional')} ${getRGBAColor(company.template.options.orderButtonColor, 'orderButton')}}`}</style>
        {props.children}
      </AppContext.Provider>
    </>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}


